/* eslint-disable react/no-danger, jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */
import React, { memo, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { phoneConstant } from '_constants/otherConstants';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionHandleShowHasLicenseModal } from 'store/reducers/modal';
import { showHasLicenseModalSelector } from 'store/selectors/modal';
import { tenantSettingsSelector } from 'store/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import BaseModal from '../BaseModal';

import './style.scss';

const classNames = {
  modalFooter: 'hasLicense_modal_footer',
  modalContent: 'hasLicense_modal_content',
  modalTitle: 'hasLicense_modal_title',
  closeIcon: 'hasLicense_modal__closeIcon',
  acceptButton: 'hasLicense_modal__acceptButton',
  declineButton: 'hasLicense_modal__declineButton',
};

const HasLicenseModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(showHasLicenseModalSelector);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });
  const tenantSettings = useAppSelector(tenantSettingsSelector);

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowHasLicenseModal(null));
  }, [dispatch]);

  const openExternalResource = useCallback(() => {
    closeModal();
    window.open(
      modalState?.externalUrl || '#',
      '_blank',
      'noopener noreferrer',
    );
  }, [closeModal]);

  const { t } = useTenantTranslation();

  return (
    <BaseModal
      closeOnBackgroundClick
      withAcceptButton
      acceptButtonText={t('common.modal.has_license.button')}
      acceptButtonSize={isPhone ? 'small' : 'large'}
      onAcceptButtonClick={openExternalResource}
      withDeclineButton
      declineButtonText={t('common.cancel')}
      declineButtonSize={isPhone ? 'small' : 'large'}
      declineButtonLook="filled-gray"
      onDeclineButtonClick={closeModal}
      onModalClose={closeModal}
      modalTitle={t('common.modal.has_license.header')}
      classNames={classNames}
      isCloseIconVisible
    >
      <p>
        <Trans ns={tenantSettings?.tenantTranslationPrefix}>
          {t('common.modal.has_license.subtext')}
        </Trans>
      </p>
    </BaseModal>
  );
};

export default memo(HasLicenseModal);
