import React, { memo, useCallback, useMemo, useState } from 'react';
import { MdOutlineGroup } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { showProgramsAndResourcesModalSelector } from 'store/selectors/modal';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { GeographyServeEnum } from '_types/resources.interface';
import Input from 'app/components/InputComponents/Input';
import {
  actionHandleChangeProgramAndResourceModal,
  actionHandleShowProgramsAndResourcesModal,
  resetState,
} from 'store/reducers/modal';
import DropDownMultiSelectList from 'app/components/DropDownMultiSelectList/DropDownMultiSelectList';
import DropDownList from 'app/components/DropDownList/DropDownList';
import Textarea from 'app/components/Textarea/Textarea';
import {
  getAvailableIndustriesList,
  statesOnboardingListFn,
} from '_constants/onboardingConstants';
import { getAvailableGeographyServeList } from 'app/components/ProfileFields/advisory-org/AdvisoryOrgGeographyServeSelectComponent';
import AdvisoryOrgZipCodesImport from 'app/components/ProfileFields/advisory-org/AdvisoryOrgIsGlobalComponent/AdvisoryOrgZipCodesImport';
import Button from 'app/components/Button';
import { AxiosError } from 'axios';
import {
  createCohortProgram,
  updateCohortProgram,
} from 'store/actions/cohort-program';
import DatePickerCustom from 'app/components/DatePickerCustom/DatePickerCustom';
import NumericInput from 'app/components/InputComponents/NumericInput';
import { orderBy } from 'lodash';
import { fetchResources } from 'store/actions/resources';
import { profileAdvisoryOrgInfoSelector } from 'store/selectors/profile';
import ErrorsText from 'app/components/ErrorsText';
import FieldWrapper from '../utils/FieldWrapper';
import { availableProgramFormatList } from '../utils/helperFuctions';
import UploadLogo from '../utils/UploadLogo';
import AdditionalCriteriaInput from '../utils/AdditionalCriteriaInput';
import './style.scss';

const CohortModal: React.FC = () => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const modalData = useAppSelector(showProgramsAndResourcesModalSelector);
  const advisoryOrgInfo = useAppSelector(profileAdvisoryOrgInfoSelector);

  const [error, setError] = useState<any>(null);

  const availableIndustriesList = useMemo(
    () => orderBy(getAvailableIndustriesList(t), 'name', 'asc'),
    [t],
  );
  const availableGeographyServeList = useMemo(
    () => getAvailableGeographyServeList(t),
    [t],
  );
  const availableStatesList = useMemo(() => statesOnboardingListFn(t), [t]);

  const availableProgramFormat = useMemo(
    () => availableProgramFormatList(t),
    [t],
  );

  const handleChange = (field: string, value: any) => {
    if (modalData?.mode === 'edit' || modalData?.mode === 'create') {
      let updatedData = {
        ...modalData?.data,
        [field]: value,
      };

      if (error) {
        setError((prevState) => ({
          ...prevState,
          errors: { ...prevState?.erros, [field]: null },
        }));
      }

      if (field === 'geography') {
        if (value === GeographyServeEnum.NATIONAL) {
          updatedData = { ...updatedData, states: null, zipCodes: null };
        } else if (value === GeographyServeEnum.STATE) {
          updatedData = { ...updatedData, zipCodes: null };
        } else if (value === GeographyServeEnum.ZIPCODESPECIFIC) {
          updatedData = { ...updatedData, states: null };
        }
      }

      dispatch(
        actionHandleChangeProgramAndResourceModal({
          ...updatedData,
          advisoryOrgId: advisoryOrgInfo?.id,
        }),
      );
    }
  };
  const submitForm = useCallback(async () => {
    let action;

    if (modalData?.mode === 'create') {
      action = createCohortProgram;
    } else if (modalData?.mode === 'edit') {
      action = updateCohortProgram;
    }

    if (action) {
      const result = await dispatch(action(modalData?.data));

      if (action.fulfilled.match(result)) {
        if (advisoryOrgInfo?.id) {
          dispatch(fetchResources(advisoryOrgInfo.id));
        }
        // On success, reset state and close modal
        dispatch(actionHandleShowProgramsAndResourcesModal(null));
        dispatch(resetState());
      } else if (action.rejected.match(result)) {
        // Handle error response
        const error = result.payload as AxiosError;
        const responseData = error.response?.data as {
          message: string;
          errors: object;
        };
        setError(responseData);
        setTimeout(() => {
          const errorElement = document.querySelector(
            '.cohort_resource_modal__header',
          );
          errorElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 200);
      }
    }
  }, [dispatch, modalData, advisoryOrgInfo, setError]);

  if (modalData?.resourceType === 'cohort') {
    return (
      <div className="cohort_resource_modal">
        <div className="cohort_resource_modal__header">
          <div className="icon-wrapper">
            <MdOutlineGroup size={32} />
          </div>
          <p>
            {t(
              'common.modal.program_and_resources_modal.add_cohort_program.title',
            )}
          </p>
        </div>
        <div className="cohort_resource_modal__content">
          <UploadLogo
            logo={modalData?.data?.logo}
            firstName={modalData?.data?.name}
            onChange={(value) => handleChange('logo', value)}
          />
          {error && (
            <ErrorsText
              errorsListOrErrorText={t(
                'common.modal.program_and_resources_modal.error_text',
              )}
            />
          )}
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.program_name.title',
            )}
            addAsteriskToTitle
          >
            <Input
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.program_name.placeholder',
              )}
              value={modalData?.data?.name || ''}
              onChange={(value, _) => handleChange('name', value)}
              errors={error?.errors?.name}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.program_lead.title',
            )}
            addAsteriskToTitle
          >
            <Input
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.program_lead.placeholder',
              )}
              value={modalData?.data?.programLead || ''}
              onChange={(value, _) => handleChange('programLead', value)}
              errors={error?.errors?.programLead}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.program_description.title',
            )}
            subTitle={t(
              'common.modal.program_and_resources_modal.add_cohort_program.program_description.subtitle',
            )}
            addAsteriskToTitle
          >
            <Textarea
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.program_description.placeholder',
              )}
              value={modalData?.data?.description || ''}
              onChange={(value) => handleChange('description', value)}
              maxLength={500}
              errors={error?.errors?.description}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.start_date.title',
            )}
            addAsteriskToTitle
          >
            <DatePickerCustom
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.start_date.placeholder',
              )}
              format="dd-MM-yyyy"
              value={modalData?.data?.programStart || ''}
              onChange={(value) => handleChange('programStart', value)}
              showIcon
              errors={error?.errors?.programStart}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.duration.title',
            )}
            addAsteriskToTitle
          >
            <Input
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.duration.placeholder',
              )}
              value={modalData?.data?.programDuration || ''}
              onChange={(value, _) => handleChange('programDuration', value)}
              errors={error?.errors?.programDuration}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.format.title',
            )}
          >
            <DropDownList
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.format.placeholder',
              )}
              value={modalData?.data?.programFormat || ''}
              onChange={(value) => handleChange('programFormat', value)}
              availableValuesList={availableProgramFormat}
              isArrow
              errors={error?.errors?.programFormat}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.application_start_date.title',
            )}
            subTitle={t(
              'common.modal.program_and_resources_modal.add_cohort_program.application_start_date.subtitle',
            )}
          >
            <DatePickerCustom
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.application_start_date.placeholder',
              )}
              format="dd-MM-yyyy"
              value={modalData?.data?.applicationStart || ''}
              onChange={(value) => handleChange('applicationStart', value)}
              showIcon
              errors={error?.errors?.applicationStart}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.application_end_date.title',
            )}
          >
            <DatePickerCustom
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.application_end_date.placeholder',
              )}
              format="dd-MM-yyyy"
              value={modalData?.data?.applicationEnd || ''}
              onChange={(value) => handleChange('applicationEnd', value)}
              showIcon
              errors={error?.errors?.applicationEnd}
            />
          </FieldWrapper>

          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.eligibility_criteria.title',
            )}
            subTitle={t(
              'common.modal.program_and_resources_modal.add_cohort_program.eligibility_criteria.subtitle',
            )}
          >
            <FieldWrapper
              subTitle={t(
                'common.modal.program_and_resources_modal.add_cohort_program.minimum_years_eligibility.title',
              )}
              subTitleClassName="custom_sub_title"
              fieldClassName="custom_field_class"
            >
              <NumericInput
                placeholder={t(
                  'common.modal.program_and_resources_modal.add_cohort_program.minimum_years_eligibility.placeholder',
                )}
                value={modalData?.data?.minYearsInBusiness}
                thousandSeparator=","
                allowNegative={false}
                onChange={(value) => handleChange('minYearsInBusiness', value)}
                errors={error?.errors?.minYearsInBusiness}
              />
            </FieldWrapper>
            <FieldWrapper
              subTitle={t(
                'common.modal.program_and_resources_modal.add_cohort_program.location_eligibility.title',
              )}
              subTitleClassName="custom_sub_title"
              fieldClassName="custom_field_class"
            >
              <DropDownList
                placeholder={t(
                  'common.modal.program_and_resources_modal.add_cohort_program.location_eligibility.placeholder',
                )}
                value={modalData?.data?.geography || ''}
                onChange={(value) => handleChange('geography', value)}
                availableValuesList={availableGeographyServeList}
                isArrow
                errors={error?.errors?.geography}
              />
            </FieldWrapper>
            {modalData?.data?.geography === GeographyServeEnum.STATE && (
              <FieldWrapper
                subTitle={t(
                  'common.modal.program_and_resources_modal.add_cohort_program.state_eligibility.title',
                )}
                fieldClassName="custom_field_class"
                addAsteriskToTitle
              >
                <DropDownMultiSelectList
                  placeholder={`${t('common.modal.program_and_resources_modal.add_cohort_program.state_eligibility.placeholder')}`}
                  value={modalData?.data?.state || []}
                  onChange={(values) => handleChange('state', values)}
                  availableValuesList={availableStatesList}
                  isArrow
                  errors={error?.errors?.state}
                />
              </FieldWrapper>
            )}
            {modalData?.data?.geography ===
              GeographyServeEnum.ZIPCODESPECIFIC && (
              <AdvisoryOrgZipCodesImport
                onChange={(values) => handleChange('zipCodes', values)}
                errors={error?.errors?.zipCodes}
              />
            )}

            <FieldWrapper
              subTitle={t(
                'common.modal.program_and_resources_modal.add_cohort_program.annual_revenue_eligibility.title',
              )}
              subTitleClassName="custom_sub_title"
              fieldClassName="custom_field_class"
            >
              <NumericInput
                placeholder={t(
                  'common.modal.program_and_resources_modal.add_cohort_program.annual_revenue_eligibility.placeholder',
                )}
                value={modalData?.data?.annualRevenue}
                thousandSeparator=","
                prefix="$"
                allowNegative={false}
                onChange={(value) => handleChange('annualRevenue', value)}
                errors={error?.errors?.annualRevenue}
              />
            </FieldWrapper>
            <FieldWrapper
              subTitle={t(
                'common.modal.program_and_resources_modal.add_cohort_program.sector_eligibility.title',
              )}
              subTitleClassName="custom_sub_title"
            >
              <DropDownMultiSelectList
                placeholder={`${t('common.modal.program_and_resources_modal.add_cohort_program.sector_eligibility.placeholder')}`}
                value={modalData?.data?.industries || []}
                onChange={(values) => handleChange('industries', values)}
                availableValuesList={availableIndustriesList}
                isArrow
                errors={error?.errors?.industries}
              />
            </FieldWrapper>
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.additional_criteria.title',
            )}
            subTitle={t(
              'common.modal.program_and_resources_modal.add_cohort_program.additional_criteria.subtitle',
            )}
          >
            {error?.errors?.additionalCriteria && (
              <ErrorsText
                errorsListOrErrorText={error?.errors?.additionalCriteria}
              />
            )}
            <AdditionalCriteriaInput
              value={modalData?.data?.additionalCriteria}
              onChange={(value) => handleChange('additionalCriteria', value)}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.website_link.title',
            )}
            subTitle={t(
              'common.modal.program_and_resources_modal.add_cohort_program.website_link.subtitle',
            )}
            addAsteriskToTitle
          >
            <Input
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.website_link.placeholder',
              )}
              value={modalData?.data?.programLeadUrl || ''}
              onChange={(value, _) => handleChange('programLeadUrl', value)}
              errors={error?.errors?.programLeadUrl}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.online_application.title',
            )}
            subTitle={t(
              'common.modal.program_and_resources_modal.add_cohort_program.online_application.subtitle',
            )}
            addAsteriskToTitle
          >
            <Input
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.online_application.placeholder',
              )}
              value={modalData?.data?.applicationUrl || ''}
              onChange={(value, _) => handleChange('applicationUrl', value)}
              errors={error?.errors?.applicationUrl}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_cohort_program.fee.title',
            )}
          >
            <NumericInput
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.fee.placeholder',
              )}
              value={modalData?.data?.fee}
              thousandSeparator=","
              prefix="$"
              allowNegative={false}
              onChange={(value) => handleChange('fee', value)}
              errors={error?.errors?.fee}
            />
          </FieldWrapper>
        </div>
        <div className="cohort_resource_modal__footer">
          <Button
            type="btn"
            btnLook="filled"
            onClick={submitForm}
            size="large"
            value={t('common.modal.program_and_resources_modal.done_btn')}
          />
        </div>
      </div>
    );
  }

  return null;
};
export default memo(CohortModal);
