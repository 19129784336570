import React, { memo, useCallback, useMemo, useState } from 'react';
import { MdOutlineSource } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { showProgramsAndResourcesModalSelector } from 'store/selectors/modal';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { GeographyServeEnum } from '_types/resources.interface';
import Input from 'app/components/InputComponents/Input';
import {
  actionHandleChangeProgramAndResourceModal,
  actionHandleShowProgramsAndResourcesModal,
  resetState,
} from 'store/reducers/modal';
import DropDownMultiSelectList from 'app/components/DropDownMultiSelectList/DropDownMultiSelectList';
import DropDownList from 'app/components/DropDownList/DropDownList';
import Textarea from 'app/components/Textarea/Textarea';
import {
  getAvailableIndustriesList,
  sboCommunitiesListFn,
  statesOnboardingListFn,
} from '_constants/onboardingConstants';
import { getAvailableGeographyServeList } from 'app/components/ProfileFields/advisory-org/AdvisoryOrgGeographyServeSelectComponent';
import AdvisoryOrgZipCodesImport from 'app/components/ProfileFields/advisory-org/AdvisoryOrgIsGlobalComponent/AdvisoryOrgZipCodesImport';
import orderBy from 'lodash/orderBy';
import { tenantSettingsSboBusinessNeedsAvailableSelector } from 'store/selectors/tenant-settings';
import { formatBusinessTopicsData } from 'utils/business-topics';
import Button from 'app/components/Button';
import {
  createPartnerResource,
  fetchResources,
  updatePartnerResource,
} from 'store/actions/resources';
import { AxiosError } from 'axios';
import { profileAdvisoryOrgInfoSelector } from 'store/selectors/profile';
import NumericInput from 'app/components/InputComponents/NumericInput';
import ErrorsText from 'app/components/ErrorsText';
import FieldWrapper from '../utils/FieldWrapper';
import {
  getAvailableSboTypesList,
  getPartnerResourceTypeList,
} from '../utils/helperFuctions';
import UploadLogo from '../utils/UploadLogo';
import './style.scss';

const OtherResourceModal: React.FC = () => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();

  const [error, setError] = useState<any>(null);

  const modalData = useAppSelector(showProgramsAndResourcesModalSelector);
  const availableBusinessNeedsData = useAppSelector(
    tenantSettingsSboBusinessNeedsAvailableSelector,
  );
  const advisoryOrgInfo = useAppSelector(profileAdvisoryOrgInfoSelector);

  const availableBusinessNeedsFormatted = useMemo(
    () =>
      formatBusinessTopicsData(availableBusinessNeedsData, t).map(
        ({ hint, ...rest }) => rest,
      ),
    [availableBusinessNeedsData, t],
  );
  const availableSboTypesList = useMemo(() => getAvailableSboTypesList(t), [t]);
  const availableIndustriesList = useMemo(
    () => orderBy(getAvailableIndustriesList(t), 'name', 'asc'),
    [t],
  );
  const availableSboCommunities = useMemo(() => sboCommunitiesListFn(t), [t]);
  const availableGeographyServeList = useMemo(
    () => getAvailableGeographyServeList(t),
    [t],
  );
  const availableStatesList = useMemo(() => statesOnboardingListFn(t), [t]);
  const availablePartnerResourceTypeList = useMemo(
    () => getPartnerResourceTypeList(t),
    [t],
  );

  const handleChange = useCallback(
    (field: string, value: any) => {
      if (error) {
        setError((prevState) => ({
          ...prevState,
          errors: { ...prevState?.errors, [field]: null },
        }));
      }

      if (modalData?.mode === 'edit' || modalData?.mode === 'create') {
        let updatedData = {
          ...modalData.data,
          [field]: value,
        };

        // Handle geography-specific changes
        if (field === 'geography') {
          if (value === GeographyServeEnum.NATIONAL) {
            updatedData = { ...updatedData, states: null, zipCodes: null };
          } else if (value === GeographyServeEnum.STATE) {
            updatedData = { ...updatedData, zipCodes: null };
          } else if (value === GeographyServeEnum.ZIPCODESPECIFIC) {
            updatedData = { ...updatedData, states: null };
          }
        }

        dispatch(
          actionHandleChangeProgramAndResourceModal({
            ...updatedData,
            advisoryOrgId: advisoryOrgInfo?.id,
          }),
        );
      }
    },
    [dispatch, modalData, advisoryOrgInfo, setError, error],
  );

  const submitForm = useCallback(async () => {
    let action;

    if (modalData?.mode === 'create') {
      action = createPartnerResource;
    } else if (modalData?.mode === 'edit') {
      action = updatePartnerResource;
    }

    if (action) {
      const result = await dispatch(action(modalData?.data));

      if (action.fulfilled.match(result)) {
        if (advisoryOrgInfo?.id) {
          dispatch(fetchResources(advisoryOrgInfo.id));
        }
        // On success, reset state and close modal
        dispatch(actionHandleShowProgramsAndResourcesModal(null));
        dispatch(resetState());
      } else if (action.rejected.match(result)) {
        // Handle error response
        const error = result.payload as AxiosError;
        const responseData = error.response?.data as {
          message: string;
          errors: object;
        };
        setError(responseData);
        setTimeout(() => {
          const errorElement = document.querySelector(
            '.other_resource_modal__header',
          );
          errorElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 200);
      }
    }
  }, [dispatch, modalData, advisoryOrgInfo, setError]);

  if (modalData?.resourceType === 'resource') {
    return (
      <div className="other_resource_modal">
        <div className="other_resource_modal__header">
          <div className="icon-wrapper">
            <MdOutlineSource size={32} />
          </div>
          <p>
            {t(
              'common.modal.program_and_resources_modal.add_other_resources.title',
            )}
          </p>
        </div>
        <div className="other_resource_modal__content">
          <UploadLogo
            logo={modalData?.data?.imgUrl}
            firstName={modalData?.data?.partnerName}
            onChange={(value) => handleChange('imgUrl', value)}
          />
          {error && (
            <ErrorsText
              errorsListOrErrorText={t(
                'common.modal.program_and_resources_modal.error_text',
              )}
            />
          )}
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_other_resources.resource_name.title',
            )}
            addAsteriskToTitle
          >
            <Input
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_other_resources.resource_name.placeholder',
              )}
              value={modalData?.data?.partnerName || ''}
              onChange={(value, _) => handleChange('partnerName', value)}
              errors={error?.errors?.partnerName}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_other_resources.sponser_name.title',
            )}
            addAsteriskToTitle
          >
            <Input
              type="text"
              placeholder={t('Sponsor Name')}
              value={modalData?.data?.partnerAffiliation || ''}
              onChange={(value, _) => handleChange('partnerAffiliation', value)}
              errors={error?.errors?.partnerAffiliation}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_other_resources.resource_type.title',
            )}
          >
            <DropDownList
              placeholder={t(
                'common.modal.program_and_resources_modal.add_other_resources.resource_type.placeholder',
              )}
              value={modalData?.data?.type || ''}
              onChange={(value) => handleChange('type', value)}
              availableValuesList={availablePartnerResourceTypeList}
              isArrow
              errors={error?.errors?.type}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_other_resources.resource_description.title',
            )}
            subTitle={t(
              'common.modal.program_and_resources_modal.add_other_resources.resource_description.subtitle',
            )}
            addAsteriskToTitle
          >
            <Textarea
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_other_resources.resource_description.placeholder',
              )}
              value={modalData?.data?.about || ''}
              onChange={(value) => handleChange('about', value)}
              maxLength={200}
              errors={error?.errors?.about}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_other_resources.fee.title',
            )}
          >
            <NumericInput
              placeholder={t(
                'common.modal.program_and_resources_modal.add_other_resources.fee.placeholder',
              )}
              value={modalData?.data?.fee}
              thousandSeparator=","
              prefix="$"
              allowNegative={false}
              onChange={(value) => handleChange('fee', value)}
              errors={error?.errors?.fee}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_other_resources.website_link.title',
            )}
            subTitle={t(
              'common.modal.program_and_resources_modal.add_other_resources.website_link.subtitle',
            )}
            addAsteriskToTitle
          >
            <Input
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_other_resources.website_link.placeholder',
              )}
              value={modalData?.data?.outboundLink || ''}
              onChange={(value, _) => handleChange('outboundLink', value)}
              errors={error?.errors?.outboundLink}
            />
          </FieldWrapper>

          <FieldWrapper
            title={t(
              'common.modal.program_and_resources_modal.add_other_resources.tags.title',
            )}
            subTitle={t(
              'common.modal.program_and_resources_modal.add_other_resources.tags.subtitle',
            )}
            fieldClassName="custom_field_class"
          >
            <FieldWrapper
              subTitle={t(
                'common.modal.program_and_resources_modal.add_other_resources.business_need_tag.title',
              )}
              subTitleClassName="custom_sub_title"
              fieldClassName="custom_field_class"
            >
              <DropDownMultiSelectList
                placeholder={`${t('common.modal.program_and_resources_modal.add_other_resources.business_need_tag.placeholder')}`}
                value={modalData?.data?.topics || []}
                onChange={(values) => handleChange('topics', values)}
                availableValuesList={availableBusinessNeedsFormatted}
                isArrow
                errors={error?.errors?.topics}
              />
            </FieldWrapper>
            <FieldWrapper
              subTitle={t(
                'common.modal.program_and_resources_modal.add_other_resources.business_stage_tag.title',
              )}
              subTitleClassName="custom_sub_title"
              fieldClassName="custom_field_class"
            >
              <DropDownMultiSelectList
                placeholder={`${t('common.modal.program_and_resources_modal.add_other_resources.business_stage_tag.placeholder')}`}
                value={modalData?.data?.businessStage || []}
                onChange={(values) => handleChange('businessStage', values)}
                availableValuesList={availableSboTypesList}
                isArrow
                errors={error?.errors?.businessStage}
              />
            </FieldWrapper>
            <FieldWrapper
              subTitle={t(
                'common.modal.program_and_resources_modal.add_other_resources.industry_tag.title',
              )}
              subTitleClassName="custom_sub_title"
              fieldClassName="custom_field_class"
            >
              <DropDownMultiSelectList
                placeholder={`${t('common.modal.program_and_resources_modal.add_other_resources.industry_tag.placeholder')}`}
                value={modalData?.data?.industries || []}
                onChange={(values) => handleChange('industries', values)}
                availableValuesList={availableIndustriesList}
                isArrow
                errors={error?.errors?.industries}
              />
            </FieldWrapper>
            <FieldWrapper
              subTitle={t(
                'common.modal.program_and_resources_modal.add_other_resources.community_tag.title',
              )}
              subTitleClassName="custom_sub_title"
              fieldClassName="custom_field_class"
            >
              <DropDownMultiSelectList
                placeholder={`${t('common.modal.program_and_resources_modal.add_other_resources.community_tag.placeholder')}`}
                value={modalData?.data?.sboCommunities || []}
                onChange={(values) => handleChange('sboCommunities', values)}
                availableValuesList={availableSboCommunities}
                isArrow
                errors={error?.errors?.sboCommunities}
              />
            </FieldWrapper>
            <FieldWrapper
              subTitle={t(
                'common.modal.program_and_resources_modal.add_other_resources.geography_tag.title',
              )}
              subTitleClassName="custom_sub_title"
              fieldClassName="custom_field_class"
              addAsteriskToTitle
            >
              <DropDownList
                placeholder={t(
                  'common.modal.program_and_resources_modal.add_other_resources.geography_tag.placeholder',
                )}
                value={modalData?.data?.geography || ''}
                onChange={(value) => handleChange('geography', value)}
                availableValuesList={availableGeographyServeList}
                isArrow
                errors={error?.errors?.geography}
              />
            </FieldWrapper>
            {modalData?.data?.geography === GeographyServeEnum.STATE && (
              <FieldWrapper
                subTitle={t(
                  'common.modal.program_and_resources_modal.add_other_resources.state_tag.title',
                )}
                subTitleClassName="custom_sub_title"
                fieldClassName="custom_field_class"
                addAsteriskToTitle
              >
                <DropDownMultiSelectList
                  placeholder={`${t('common.modal.program_and_resources_modal.add_other_resources.state_tag.placeholder')}`}
                  value={modalData?.data?.states || []}
                  onChange={(values) => handleChange('states', values)}
                  availableValuesList={availableStatesList}
                  isArrow
                  errors={error?.errors?.states}
                />
              </FieldWrapper>
            )}
            {modalData?.data?.geography ===
              GeographyServeEnum.ZIPCODESPECIFIC && (
              <AdvisoryOrgZipCodesImport
                onChange={(values) => handleChange('zipCodes', values)}
                errors={error?.errors?.zipCodes}
              />
            )}
          </FieldWrapper>
        </div>
        <div className="other_resource_modal__footer">
          <Button
            type="btn"
            btnLook="filled"
            onClick={submitForm}
            size="large"
            value={t('common.modal.program_and_resources_modal.done_btn')}
          />
        </div>
      </div>
    );
  }

  return null;
};
export default memo(OtherResourceModal);
