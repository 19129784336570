import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { MdOutlineCancel, MdOutlineClose } from 'react-icons/md';

import {
  buttonClickEventEscapeButtonNumber,
  phoneConstant,
} from '_constants/otherConstants';

import Button, {
  ButtonProps,
  ButtonSizeChoices,
  ButtonLookChoices,
} from 'app/components/Button';
import ErrorsText from 'app/components/ErrorsText';
import Ellipsis from 'app/components/Ellipsis';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import useTenantColors from 'utils/hooks/useTenantColors';

import './style.scss';

export type ModalAcceptButtonProps =
  | {
      withAcceptButton: true;
      onAcceptButtonClick: Function;
      acceptButtonText?: string;
      acceptButtonSize?: ButtonSizeChoices;
      acceptButtonLook?: ButtonLookChoices;
      acceptButtonStyle?: React.CSSProperties;
      acceptButtonRightIcon?: JSX.Element;
      disableAcceptButton?: boolean;
    }
  | {
      withAcceptButton?: false;
    };

export type ModalDeclineButtonProps =
  | {
      withDeclineButton: true;
      onDeclineButtonClick: Function;
      leftDeclineButtonIcon?: JSX.Element;
      declineButtonText?: string;
      declineButtonLook?: ButtonProps['btnLook'];
      declineButtonSize?: ButtonSizeChoices;
      declineButtonRightIcon?: JSX.Element;
    }
  | {
      withDeclineButton?: false;
    };

export type ModalCloseButtonProps =
  | {
      withCloseButton: true;
      closeButtonIcon?: JSX.Element;
      closeButtonText?: string;
      closeButtonLook?: ButtonProps['btnLook'];
      closeButtonSize?: ButtonSizeChoices;
      closeButtonError?: string | null;
    }
  | {
      withCloseButton?: false;
    };

export type BaseModalProps = {
  children?: ReactNode;
  onModalClose?: Function;
  closeOnBackgroundClick?: boolean;
  backgroundScrollDisabled?: boolean;
  isCloseIconVisible?: boolean;
  classNames?: {
    modalContainer?: string;
    modal?: string;
    modalInnerContainer?: string;
    modalHeader?: string;
    modalTitle?: string;
    modalSubtitle?: string;
    modalContent?: string;
    modalFooter?: string;
    modalPhoneUpperLine?: string;
    acceptButton?: string;
    declineButton?: string;
    closeIcon?: string;
    closeButton?: string;
  };
  headerIcon?: ReactNode;
  modalTitle?: string;
  modalDescription?: string;
  footerChildren?: ReactNode;
} & (ModalAcceptButtonProps & ModalDeclineButtonProps & ModalCloseButtonProps);

const BaseModal: React.FC<BaseModalProps> = (props) => {
  const {
    children,
    closeOnBackgroundClick,
    classNames,
    headerIcon,
    withAcceptButton,
    withDeclineButton,
    withCloseButton,
    modalTitle,
    modalDescription,
    isCloseIconVisible = false,
    footerChildren,
  } = props;

  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });

  const [isCloseButtonLoading, setIsCloseButtonLoading] =
    useState<boolean>(false);
  const [isAcceptButtonLoading, setIsAcceptButtonLoading] =
    useState<boolean>(false);
  const [isDeclineButtonLoading, setIsDeclineButtonLoading] =
    useState<boolean>(false);
  const { colorVariables } = useTenantColors();
  const isAnythingLoading =
    isCloseButtonLoading || isAcceptButtonLoading || isDeclineButtonLoading;

  const onModalClose = useCallback(async () => {
    setIsCloseButtonLoading(true);

    try {
      if (props.onModalClose) {
        await props.onModalClose();
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(
        'An error has occurred during modal close action. Please contact administrator.',
      );
      // eslint-disable-next-line no-console
      console.error(e);
    }

    setIsCloseButtonLoading(false);
  }, [setIsCloseButtonLoading, props.onModalClose]);

  const onAcceptButtonClick = useCallback(async () => {
    if (!withAcceptButton) {
      return;
    }

    setIsAcceptButtonLoading(true);

    try {
      if (props.onAcceptButtonClick) {
        await props.onAcceptButtonClick();
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(
        'An error has occurred during modal accept action. Please contact administrator.',
      );
      // eslint-disable-next-line no-console
      console.error(e);
    }

    setIsAcceptButtonLoading(false);
  }, [
    withAcceptButton,
    withAcceptButton && props.onAcceptButtonClick,
    setIsAcceptButtonLoading,
  ]);

  const onDeclineButtonClick = useCallback(async () => {
    if (!withDeclineButton) {
      return;
    }

    setIsDeclineButtonLoading(true);

    try {
      if (props.onDeclineButtonClick) {
        await props.onDeclineButtonClick();
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(
        'An error has occurred during modal decline action. Please contact administrator.',
      );
      // eslint-disable-next-line no-console
      console.error(e);
    }

    setIsDeclineButtonLoading(false);
  }, [
    withDeclineButton,
    withDeclineButton && props.onDeclineButtonClick,
    setIsDeclineButtonLoading,
  ]);

  const { ref } = useOnClickOutside(
    closeOnBackgroundClick || false,
    onModalClose,
  );

  const onModalContainerClick = useCallback(
    (event: React.TouchEvent<HTMLElement> | React.MouseEvent<HTMLElement>) => {
      const isBackgroundClick = ref.current === event.target;
      if (isBackgroundClick && closeOnBackgroundClick && !isAnythingLoading) {
        onModalClose();
      }
    },
    [closeOnBackgroundClick, onModalClose],
  );

  useEffect(() => {
    if (!closeOnBackgroundClick) {
      return () => {};
    }

    function escapeButtonClick(event) {
      if (event.which === buttonClickEventEscapeButtonNumber) {
        onModalClose();
      }
    }

    document.addEventListener('keydown', escapeButtonClick);
    return () => {
      document.removeEventListener('keydown', escapeButtonClick);
    };
  }, [closeOnBackgroundClick]);

  useEffect(() => {
    if (!props.backgroundScrollDisabled) {
      return () => {};
    }

    document.getElementsByTagName('body')[0].style.overflow = 'hidden';

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = '';
    };
  }, [props.backgroundScrollDisabled]);

  return (
    <div
      className={clsx('modal-container', classNames?.modalContainer)}
      onClick={onModalContainerClick}
      ref={ref}
    >
      <div className={clsx('modal', classNames?.modal)}>
        {isCloseIconVisible && (
          <div
            className={clsx('modal-close-icon', classNames?.closeIcon)}
            onClick={onModalClose}
          >
            {isPhone ? (
              <MdOutlineCancel
                size={32}
                color={colorVariables?.icons.icon_secondary}
              />
            ) : (
              <MdOutlineClose
                size={36}
                color={colorVariables?.icons.icon_secondary}
              />
            )}
          </div>
        )}

        <div
          className={clsx(
            'modal-inner-container',
            classNames?.modalInnerContainer,
          )}
        >
          <div className={clsx('modal-inner-container-header')}>
            {isPhone && (
              <hr
                className={clsx(
                  'modal-phone-upper-line',
                  classNames?.modalPhoneUpperLine,
                )}
              />
            )}

            {headerIcon && (
              <div className={clsx('modal-header', classNames?.modalHeader)}>
                {headerIcon}
              </div>
            )}

            {modalTitle && (
              <p className={clsx('modal-title', classNames?.modalTitle)}>
                {modalTitle}
              </p>
            )}

            {modalDescription && (
              <div
                className={clsx('modal-subtitle', classNames?.modalSubtitle)}
              >
                <Ellipsis text={modalDescription} maxLine={3} />
              </div>
            )}
          </div>

          {children && (
            <div className={clsx('modal-content', classNames?.modalContent)}>
              {children}
            </div>
          )}
        </div>

        {(withAcceptButton || withDeclineButton || withCloseButton) && (
          <div className={clsx('modal-footer', classNames?.modalFooter)}>
            {withCloseButton && (
              <div
                className={clsx('modal-footer-error', {
                  enabled: !!props.closeButtonError,
                })}
              >
                {withCloseButton && props.closeButtonError && (
                  <ErrorsText errorsListOrErrorText={props.closeButtonError} />
                )}
              </div>
            )}

            <div
              className={clsx('modal-footer-buttons', {
                withError: withCloseButton && !!props.closeButtonError,
              })}
            >
              {withDeclineButton && (
                <Button
                  type="btn"
                  btnLook={props.declineButtonLook || 'filled'}
                  onClick={onDeclineButtonClick}
                  size={props.declineButtonSize || 'small'}
                  isLoading={isDeclineButtonLoading}
                  disabled={isAnythingLoading}
                  leftIcon={props.leftDeclineButtonIcon}
                  className={clsx(
                    'modal-decline-button',
                    classNames?.declineButton,
                  )}
                  value={props.declineButtonText || 'Decline'}
                  rightIcon={props.declineButtonRightIcon}
                />
              )}

              {withAcceptButton && (
                <Button
                  type="btn"
                  style={props.acceptButtonStyle}
                  btnLook={props.acceptButtonLook || 'filled'}
                  onClick={onAcceptButtonClick}
                  size={props.acceptButtonSize || 'small'}
                  isLoading={isAcceptButtonLoading}
                  disabled={isAnythingLoading || props.disableAcceptButton}
                  className={clsx(
                    'modal-accept-button',
                    classNames?.acceptButton,
                  )}
                  value={props.acceptButtonText || 'Accept'}
                  rightIcon={props.acceptButtonRightIcon}
                />
              )}

              {footerChildren && footerChildren}

              {withCloseButton && (
                <div className="modal-close-button-wrapper">
                  <Button
                    type="btn"
                    btnLook={props.closeButtonLook || 'filled'}
                    onClick={onModalClose}
                    size={props.closeButtonSize || 'medium'}
                    isLoading={isCloseButtonLoading}
                    disabled={isAnythingLoading}
                    className={clsx(
                      'modal-close-button',
                      classNames?.closeButton,
                      {
                        'right-0': !!props.closeButtonIcon,
                      },
                    )}
                    rightIcon={props.closeButtonIcon}
                    value={props.closeButtonText || 'Close'}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(BaseModal);
