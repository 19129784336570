/* eslint-disable react/no-danger, jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */
import React, { memo, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { phoneConstant } from '_constants/otherConstants';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionHandleShowExternalUrlConsentModal } from 'store/reducers/modal';
import { showExternalUrlConsentModalSelector } from 'store/selectors/modal';
import { tenantSettingsSelector } from 'store/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import { FundingReferralType } from '_types/funding.interface';
import BaseModal from '../BaseModal';

import './style.scss';

const classNames = {
  modalFooter: 'info_modal_footer',
  modalContent: 'info_modal_content',
  modalTitle: 'info_modal_title',
  closeIcon: 'info_modal__closeIcon',
  acceptButton: 'info_modal__acceptButton',
  declineButton: 'info_modal__declineButton',
};

const ExternalUrlConsentModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(showExternalUrlConsentModalSelector);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });
  const tenantSettings = useAppSelector(tenantSettingsSelector);

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowExternalUrlConsentModal(null));
  }, [dispatch]);

  const openExternalResource = useCallback(() => {
    document.getElementById('external-link-href')!.click();
    closeModal();
  }, [closeModal]);

  const { t } = useTenantTranslation();

  return (
    <BaseModal
      closeOnBackgroundClick
      withAcceptButton
      acceptButtonText={
        modalState?.acceptButtonText ||
        modalState?.fundingReferralType === FundingReferralType.WEBSITE
          ? t('common.continue')
          : t('common.yes_continue')
      }
      acceptButtonSize={isPhone ? 'small' : 'large'}
      onAcceptButtonClick={openExternalResource}
      withDeclineButton
      declineButtonText={t('common.cancel')}
      declineButtonSize={isPhone ? 'small' : 'large'}
      declineButtonLook="filled-gray"
      onDeclineButtonClick={closeModal}
      onModalClose={closeModal}
      modalTitle={
        modalState?.title ||
        modalState?.fundingReferralType === FundingReferralType.WEBSITE
          ? t('common.visit_website')
          : t(
              'funding_module.apply_online_section.external_resource_confirmation_title',
            )
      }
      classNames={classNames}
      isCloseIconVisible
    >
      <p>
        <Trans
          ns={tenantSettings?.tenantTranslationPrefix}
          i18nKey={
            modalState?.fundingReferralType === FundingReferralType.WEBSITE
              ? 'funding_module.apply_online_section.external_resource_confirmation_text2'
              : 'funding_module.apply_online_section.external_resource_confirmation_text1'
          }
          components={[
            <a
              key="0"
              href="https://digitalready.verizonwireless.com/?utm_source=google&utm_medium=cpc&utm_content=verizon-CSR&utm_campaign=digital-ready-launch&utm_term=verizon%20small%20business%20digital%20ready&gad_source=1&gclid=CjwKCAjwqMO0BhA8EiwAFTLgIJeAJ7a4MCOC-uZiSls4zLurlG5WpzV4NSKFrLDoSSJOdF9zrWIqiBoCgfsQAvD_BwE&gclsrc=aw.ds"
              target="_blank"
              rel="noopener noreferrer"
              className="text-link"
            />,
          ]}
        />
      </p>

      <a
        id="external-link-href"
        href={modalState?.externalUrl || ''}
        target={modalState?.target || ''}
        rel={modalState?.rel || ''}
      />
    </BaseModal>
  );
};

export default memo(ExternalUrlConsentModal);
