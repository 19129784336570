import React, { memo, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { phoneConstant } from '_constants/otherConstants';

import ModulesHamburgerMenuForMobile from 'app/components/ModulesHamburgerMenuForMobile';
import { headerTabsConstantsFn } from 'app/components/HomePageTabs/utils';
import HomePageTabs from 'app/components/HomePageTabs/HomePageTabs';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantModulesSelector } from 'store/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useTenantType from 'utils/hooks/useTenantType';

const GeneralHeaderUnauthProfilePart: React.FC = () => {
  const { t } = useTenantTranslation();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });

  const tenantModules = useAppSelector(tenantSettingsTenantModulesSelector);
  const tenantType = useTenantType();

  const areHeaderButtonsMany = useMemo(
    () =>
      headerTabsConstantsFn(undefined, tenantModules, t, tenantType).length > 2,
    [tenantModules, t, tenantType],
  );

  return isPhone && areHeaderButtonsMany ? (
    <div className="profile__right-menu">
      <ModulesHamburgerMenuForMobile />
    </div>
  ) : (
    <HomePageTabs withDividers />
  );
};

export default memo(GeneralHeaderUnauthProfilePart);
