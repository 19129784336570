import React, { useEffect, memo, useCallback } from 'react';

import { InfoModalType } from '_types/modals.interface';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { showInfoModalSelector } from 'store/selectors/modal';
import { actionHandleShowInfoModal } from 'store/reducers/modal';

import BaseModal from '../BaseModal';

import './style.scss';

const InfoModalGlobal: React.FC = () => {
  const dispatch = useAppDispatch();

  const info: InfoModalType | null = useAppSelector(showInfoModalSelector);

  const onModalClose = useCallback(async () => {
    if (info?.modalCloseDisabled) {
      return;
    }

    if (info?.onModalClose) {
      await info?.onModalClose();
    }
    dispatch(actionHandleShowInfoModal(null));
  }, [dispatch, info?.onModalClose]);

  useEffect(() => {
    document.body.style.overflow = info ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [info]);

  return (
    info && (
      <BaseModal
        closeOnBackgroundClick={
          !info.modalCloseDisabled && info.closeOnBackgroundClick
        }
        onModalClose={onModalClose}
        withCloseButton={!info.modalCloseDisabled as true}
        closeButtonText={
          !info.modalCloseDisabled ? info.closeButtonText : undefined
        }
        closeButtonSize={
          !info.modalCloseDisabled ? info.closeButtonSize : undefined
        }
        headerIcon={info.headerIcon}
        modalTitle={info.modalTitle}
        classNames={info.classNames}
        isCloseIconVisible={info.isCloseIconVisible}
      >
        {info.content}
      </BaseModal>
    )
  );
};

export default memo(InfoModalGlobal);
