import React, { memo } from 'react';

interface ErrorIconProps {
  className?: string;
}
const ErrorIcon: React.FC<ErrorIconProps> = ({ className }) => (
  <svg
    className={className}
    width="1em"
    height="0.9em"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00018 6.5V8.16666V6.5ZM9.00018 11.5H9.00851H9.00018ZM3.22685 14.8333H14.7735C16.0568 14.8333 16.8585 13.4442 16.2168 12.3333L10.4435 2.33333C9.80185 1.2225 8.19851 1.2225 7.55685 2.33333L1.78351 12.3333C1.14185 13.4442 1.94351 14.8333 3.22685 14.8333Z"
      stroke="#BA2C2B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(ErrorIcon);
