import React, { memo, useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import { phoneConstant } from '_constants/otherConstants';

import { UserRolesEnum } from '_types/user.interface';

import { MyAdvisorProfileErrorsType } from 'app/pages/MyAccount/components/MyProfile/MyAdvisorProfile/validationSchemas';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import {
  profileInfoSelector,
  profileMissingDataErrorsSelector,
} from 'store/selectors/profile';
import { fetchPatchProfileInfo } from 'store/actions/profile';
import {
  actionHandleShowProfileMissingDataModal,
  actionHandleShowProfileMissingDataSuccessModal,
} from 'store/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import BaseModal from '../BaseModal';

import {
  advisorValidationSchema,
  advisoryOrgAndAdvisorValidationSchema,
  advisoryOrgOnlyValidationSchema,
  sboValidationSchema,
} from './validationSchemas';
import ProfileMissingDataForm from './ProfileMissingDataForm';
import { validateProfileMissingData } from './utils';

import './style.scss';

const classNames = {
  modal: 'profile_missing_data_modal',
  modalInnerContainer: 'profile_missing_data_modal_container',
  modalHeader: 'profile_missing_data_modal_header',
  modalContent: 'profile_missing_data_modal_content',
  modalFooter: 'profile_missing_data_modal_footer',
  modalTitle: 'profile_missing_data_modal_title',
  modalPhoneUpperLine: 'profile_missing_data_modal_phone-upper-line',
};

const ProfileMissingDataModal: React.FC = () => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });

  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const errors = useAppSelector(
    profileMissingDataErrorsSelector<MyAdvisorProfileErrorsType>,
  );

  const profileInfo = useAppSelector(profileInfoSelector);

  const dataValidationSchema = useMemo(() => {
    if (!profileInfo) {
      return null;
    }

    switch (profileInfo?.role) {
      case UserRolesEnum.ADVISORY_ORG_ADMIN:
        if (profileInfo.isAdvisor) {
          return advisoryOrgAndAdvisorValidationSchema;
        }
        return advisoryOrgOnlyValidationSchema;
      case UserRolesEnum.ADVISOR:
        return advisorValidationSchema;
      case UserRolesEnum.SBO:
        return sboValidationSchema;
      default:
        return null;
    }
  }, [profileInfo?.role, profileInfo?.isAdvisor]);

  const closeModal = useCallback(async () => {
    if (!profileInfo) {
      return;
    }

    const dataToSend = await validateProfileMissingData(
      dispatch,
      t,
      dataValidationSchema,
      profileInfo,
    );

    if (dataToSend) {
      await dispatch(fetchPatchProfileInfo(dataToSend)).unwrap();
      dispatch(actionHandleShowProfileMissingDataModal(null));
      dispatch(actionHandleShowProfileMissingDataSuccessModal({}));
    }
  }, [dispatch, profileInfo, pathname, dataValidationSchema]);

  const isError = useMemo(() => Object.keys(errors).length !== 0, [errors]);

  return (
    <BaseModal
      closeOnBackgroundClick={false}
      onModalClose={closeModal}
      withCloseButton
      closeButtonSize={isPhone ? 'medium' : 'large'}
      closeButtonText={t('common.save')}
      modalTitle={t('common.modal.missing_data.title', {
        name: `${profileInfo?.firstName} ${profileInfo?.lastName}`,
      })}
      classNames={classNames}
      closeButtonError={isError ? t('validation.form_invalid') : null}
    >
      <p className="profile_missing_data_modal-description">
        {t('common.modal.missing_data.description')}
      </p>
      <div>
        <ProfileMissingDataForm role={profileInfo!.role} />
      </div>
    </BaseModal>
  );
};

export default memo(ProfileMissingDataModal);
