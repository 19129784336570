import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CohortItemType } from '_types/cohort.interface ';

import {
  FetchPartnerResourcesFilterSettings,
  PartnerResourcesItemType,
} from '_types/resources.interface';

import {
  fetchPartnerResourcesCards,
  fetchPartnerResourcesFilterSettings,
  fetchResources,
} from 'store/actions/resources';

interface ResourcesState {
  resources: PartnerResourcesItemType[];
  cohortPrograms: CohortItemType[];
  isLoading: boolean;
  partnerResources: {
    partnerResourcesCount: number;
    partnerResourcesList: PartnerResourcesItemType[];
    isLoading: boolean;
    isPartnerResourcesFilterDetailsLoading: boolean;
    partnerResourcesFilterDetails: FetchPartnerResourcesFilterSettings;
  };
}

const resourcesInitialState: ResourcesState = {
  resources: [],
  cohortPrograms: [],
  isLoading: false,
  partnerResources: {
    partnerResourcesCount: 0,
    partnerResourcesList: [],
    isLoading: false,
    isPartnerResourcesFilterDetailsLoading: false,
    partnerResourcesFilterDetails: {} as FetchPartnerResourcesFilterSettings,
  },
};

const ResourcesSlice = createSlice({
  name: 'resources',
  initialState: resourcesInitialState,
  reducers: {
    resetState: () => resourcesInitialState,
    resetPartnerResourcesState: (state) => {
      state.partnerResources.partnerResourcesCount = 0;
      state.partnerResources.partnerResourcesList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResources.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchResources.fulfilled, (state, action) => {
      state.resources = action.payload.resources;
      state.cohortPrograms = action.payload.cohorts;
      state.isLoading = false;
    });
    builder.addCase(fetchResources.rejected, (state) => {
      state.isLoading = false;
    });

    // ****************************** Partner Resources ************************* /
    builder.addCase(fetchPartnerResourcesCards.pending, (state) => {
      state.partnerResources.isLoading = true;
    });
    builder.addCase(fetchPartnerResourcesCards.fulfilled, (state, action) => {
      state.partnerResources.partnerResourcesCount = action.payload.count;
      state.partnerResources.partnerResourcesList =
        action.payload.partnerResources;
      state.partnerResources.isLoading = false;
    });
    builder.addCase(fetchPartnerResourcesCards.rejected, (state) => {
      state.partnerResources.isLoading = false;
    });

    builder.addCase(fetchPartnerResourcesFilterSettings.pending, (state) => {
      state.partnerResources.isPartnerResourcesFilterDetailsLoading = true;
    });
    builder.addCase(
      fetchPartnerResourcesFilterSettings.fulfilled,
      (state, action: PayloadAction<FetchPartnerResourcesFilterSettings>) => {
        state.partnerResources.isPartnerResourcesFilterDetailsLoading = false;
        state.partnerResources.partnerResourcesFilterDetails = action.payload;
      },
    );
    builder.addCase(fetchPartnerResourcesFilterSettings.rejected, (state) => {
      state.partnerResources.isPartnerResourcesFilterDetailsLoading = false;
    });

    // ****************************** Cohort Program ************************* /
  },
});

export const { resetState } = ResourcesSlice.actions;

export default ResourcesSlice;
