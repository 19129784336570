import React from 'react';
import { TFunction } from 'i18next';

import { PlatformLanguages } from '_types';
import { TenantModules, TenantTypeEnum } from '_types/tenant-settings';

import AdvisorsIcon from 'app/components/Icons/TabIcons/AdvisorsIcon';
// import CommunityIcon from 'app/components/Icons/TabIcons/CommunityIcon';
import CoursesIcon from 'app/components/Icons/TabIcons/CoursesIcon';
import FundingIcon from 'app/components/Icons/TabIcons/FundingIcon';
import HomeIcon from 'app/components/Icons/TabIcons/HomeIcon';
import ClientsIcon from 'app/components/Icons/TabIcons/ClientsIcon';
import CommunityIcon from 'app/components/Icons/TabIcons/CommunityIcon';
import PeopleIcon from 'app/components/Icons/PeopleIcon';
import { UnauthHomePageConfigrationDataJSON } from '_types/tenant-settings/tenant-page-configuration/unauth-home-page';

export interface HeaderTabs {
  name: string;
  getIcon: Function;
  namePhone: string;
  moduleField?: keyof Omit<TenantModules, 'id'>;
  path: string;
  isForUnAuthHomePage?: boolean;
}

export enum HeaderTypes {
  auth,
  onboarding,
  notFound,
  general,
  shutdown,
}

export const platformLanguageChoices: {
  value: PlatformLanguages;
  translate: string;
  initName: string;
}[] = [
  { value: PlatformLanguages.ENGLISH, translate: 'English', initName: 'US' },
  { value: PlatformLanguages.ARABIC, translate: 'Arabic', initName: 'عرب' },
  {
    value: PlatformLanguages.CHINESE,
    translate: 'Chinese',
    initName: '中國人',
  },
  {
    value: PlatformLanguages.SPANISH,
    translate: 'Spanish',
    initName: 'Español',
  },
];

export const advisorHeaderTabs = (t: TFunction): HeaderTabs[] => [
  {
    name: t('common.header_section.clients'),
    namePhone: t('common.header_section.clients'),
    moduleField: 'sessions',
    getIcon: (color) => <ClientsIcon color={color} />,
    path: '/clients',
  },
  {
    name: t('common.header_section.funding'),
    namePhone: t('common.header_section.funding'),
    moduleField: 'fundings',
    getIcon: (color) => <FundingIcon color={color} />,
    path: '/fundings/list',
  },
  // {
  //   name: t('common.courses'),
  //   namePhone: t('common.courses'),
  //   moduleField: 'courses',
  //   getIcon: color => <CoursesIcon color={color} />,
  //   path: '/courses',
  // },
  {
    name: t('common.header_section.advisors'),
    namePhone: t('common.header_section.advisors'),
    moduleField: 'users',
    getIcon: (color) => <AdvisorsIcon color={color} />,
    path: '/advisors/list',
  },
  // {
  //   name: t('common.courses'),
  //   namePhone: t('common.courses'),
  //   moduleField: 'courses',
  //   getIcon: color => <CoursesIcon color={color} />,
  //   path: '/courses',
  // },
  {
    name: t('common.header_section.community'),
    namePhone: t('common.header_section.community'),
    moduleField: 'community',
    getIcon: (color) => <CommunityIcon color={color} />,
    path: '/community',
  },
  {
    name: t('common.header_section.about_us'),
    namePhone: t('common.header_section.about_us'),
    moduleField: 'aboutUs',
    getIcon: (color) => <PeopleIcon color={color} />,
    path: '/about-us',
  },
];

export const sboHeaderTabs = (t: TFunction): HeaderTabs[] => [
  {
    name: t('common.header_section.home'),
    namePhone: t('common.header_section.home'),
    getIcon: (color) => <HomeIcon color={color} />,
    path: '/',
  },
  {
    name: t('common.header_section.funding'),
    namePhone: t('common.header_section.funding'),
    moduleField: 'fundings',
    getIcon: (color) => <FundingIcon color={color} />,
    path: '/fundings/list',
  },
  {
    name: t('common.header_section.advisors'),
    namePhone: t('common.header_section.advisors'),
    moduleField: 'users',
    getIcon: (color) => <AdvisorsIcon color={color} />,
    path: '/advisors/list',
  },
  {
    name: t('common.header_section.courses'),
    namePhone: t('common.header_section.courses'),
    moduleField: 'courses',
    getIcon: (color) => <CoursesIcon color={color} />,
    path: '/courses',
  },
  {
    name: t('common.header_section.community'),
    namePhone: t('common.header_section.community'),
    moduleField: 'community',
    getIcon: (color) => <CommunityIcon color={color} />,
    path: '/community',
  },
  {
    name: t('common.header_section.partner_resources'),
    namePhone: t('common.header_section.partner_resources'),
    moduleField: 'partnerResource',
    getIcon: () => null,
    path: '/partner-resources',
  },
  {
    name: t('common.header_section.glossary'),
    namePhone: t('common.header_section.glossary'),
    moduleField: 'glossary',
    getIcon: () => null,
    path: '/glossary',
  },
  {
    name: t('common.header_section.about_us'),
    namePhone: t('common.header_section.about_us'),
    moduleField: 'aboutUs',
    getIcon: (color) => <PeopleIcon color={color} />,
    path: '/about-us',
  },
];

export const unauthenticatedHeaderTabs = (
  t: TFunction,
  tenantType?: TenantTypeEnum,
  pageConfiguration?: UnauthHomePageConfigrationDataJSON | null,
): HeaderTabs[] => {
  const headerTabs: HeaderTabs[] = [];
  if (tenantType === TenantTypeEnum.VERIZON) {
    headerTabs.push({
      name: t('common.header_section.funding'),
      namePhone: t('common.header_section.funding'),
      moduleField: 'fundings',
      getIcon: (color) => <></>,
      path: '/',
    });
  }

  if (tenantType === TenantTypeEnum.VERIZON) {
    headerTabs.push({
      name: t('common.header_section.glossary'),
      namePhone: t('common.header_section.glossary'),
      moduleField: 'glossary',
      getIcon: (color) => <></>,
      path: '/glossary',
    });
  }

  headerTabs.push({
    name: t('common.header_section.about_us'),
    namePhone: t('common.header_section.about_us'),
    moduleField: 'aboutUs',
    getIcon: (color) => <></>,
    path: '/about-us',
  });

  headerTabs.push({
    name: t('common.header_section.log_in'),
    namePhone: t('common.header_section.log_in'),
    moduleField: 'users',
    getIcon: (color) => <></>,
    path: '#',
  });

  if (tenantType !== TenantTypeEnum.VERIZON) {
    if (pageConfiguration?.greeting) {
      headerTabs.push({
        name: t('common.header_section.overview'),
        namePhone: t('common.header_section.overview'),
        getIcon: (color) => <></>,
        path: '#overview',
        isForUnAuthHomePage: true,
      });
    }

    if (pageConfiguration?.resources) {
      headerTabs.push({
        name: t('common.header_section.support_and_resources'),
        namePhone: t('common.header_section.support_and_resources'),
        getIcon: (color) => <></>,
        path: '#resources',
        isForUnAuthHomePage: true,
      });
    }

    if (pageConfiguration?.whoWeServe) {
      headerTabs.push({
        name: t('common.header_section.who_we_serve'),
        namePhone: t('common.header_section.who_we_serve'),
        getIcon: (color) => <></>,
        path: '#who-we-serve',
        isForUnAuthHomePage: true,
      });
    }

    if (pageConfiguration?.partners) {
      headerTabs.push({
        name: t('common.header_section.made_possible_by'),
        namePhone: t('common.header_section.made_possible_by'),
        getIcon: (color) => <></>,
        path: '#made-possible-by',
        isForUnAuthHomePage: true,
      });
    }
  }
  return headerTabs;
};
