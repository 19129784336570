import React, { memo } from 'react';
import useTenantColors from 'utils/hooks/useTenantColors';

interface Props {
  className?: string;
}

const ImportExportIcon: React.FC<Props> = ({ className }) => {
  const { colorVariables } = useTenantColors();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 3L5 6.99H8V14H10V6.99H13L9 3ZM16 17.01V10H14V17.01H11L15 21L19 17.01H16Z"
        fill={colorVariables?.icons.icon_primary2_button || '#053B66'}
      />
    </svg>
  );
};

export default memo(ImportExportIcon);
