import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { showAdvisorReferralModalSelector } from 'store/selectors/modal';
import AvatarCircle from 'app/components/AvatarCircle/AvatarCircle';
import {
  actionHandleShowAdvisorReferralModal,
  actionHandleShowInfoModal,
} from 'store/reducers/modal';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import ProfileFieldWrapper from 'app/components/ProfileFields/common/ProfileFieldWrapper';
import DropDownList from 'app/components/DropDownList/DropDownList';
import Textarea from 'app/components/Textarea/Textarea';
import { useParams } from 'react-router-dom';
import advisorService from '_services/advisorService';
import {
  AdvisorReferral,
  AdvisorReferralStatus,
} from '_types/advisor.interface';
import { profileAdvisorSelector } from 'store/selectors/profile';
import { creatAdvisorReferral } from 'store/actions/advisor';
import CongratulationsIcon from 'app/components/Icons/CongratulationsIcon';
import useTenantColors from 'utils/hooks/useTenantColors';
import { AxiosError } from 'axios';
import ErrorsText from 'app/components/ErrorsText';
import BaseModal from '../BaseModal';
import './style.scss';

const AdvisorRefferalModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTenantTranslation();
  const [organization, setOrganization] = useState('All');
  const [advisor, setAdvisor] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [availableAdvisors, setAvailableAdvisors] = useState([]);
  const [availableAdvisoryOrgs, setavailableAdvisoryOrgs] = useState([
    { name: 'All', value: 'All' },
  ]);
  const params = useParams();
  const advisorData = useAppSelector(profileAdvisorSelector);
  const [error, setError] = useState<string | null>(null);
  const { colorVariables } = useTenantColors();

  const sboAsClient = useAppSelector(showAdvisorReferralModalSelector);

  const closeModal = useCallback(async () => {
    dispatch(actionHandleShowAdvisorReferralModal(null));
  }, []);

  const refferAdvisor = useCallback(async () => {
    const wildcardPath = params['*'];
    const id = wildcardPath?.split('/')[1];

    const advisorReferralData = {
      refereeAdvisorId: advisorData.id,
      referredAdvisorId: advisor,
      sboId: Number(id),
      note,
      status: AdvisorReferralStatus.PENDING,
    } as unknown as AdvisorReferral;

    const result = await dispatch(creatAdvisorReferral(advisorReferralData));

    if (creatAdvisorReferral.fulfilled.match(result)) {
      closeModal();

      dispatch(
        actionHandleShowInfoModal({
          closeOnBackgroundClick: true,
          headerIcon: (
            <CongratulationsIcon
              color={colorVariables?.text.text_accent_1}
              className="icon"
            />
          ),
          modalTitle: t('common.modal.advisor_referral.success.title'),
          content: t('common.modal.advisor_referral.success.content'),
          closeButtonText: t(
            'common.modal.advisor_referral.success.close_button',
          ),
        }),
      );
    } else if (creatAdvisorReferral.rejected.match(result)) {
      const error = result.payload as AxiosError;
      const responseData = error.response?.data as { message: string };
      setError(responseData?.message);
      setTimeout(() => {
        const errorElement = document.querySelector('.errors_text');
        errorElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 200);
    }
  }, [note, advisorData, advisor]);

  const isDisableAcceptButton = useMemo(
    () => !(note && advisor && advisorData.id),
    [note, advisor, advisorData],
  );

  const headerIcon = useMemo(
    () => (
      <AvatarCircle
        firstName={
          sboAsClient?.businessOwnerProfile?.Profile?.Name as unknown as string
        }
        lastName={
          sboAsClient?.businessOwnerProfile?.Profile
            ?.Surname as unknown as string
        }
        src={
          sboAsClient?.businessOwnerProfile?.Profile?.photo as unknown as string
        }
        size={{ small: '0', large: '86' }}
      />
    ),
    [sboAsClient],
  );

  const getAdvisorsList = useCallback(async (value) => {
    const advisors = await advisorService.getAdvisorsListByAdvisoryOrgId(
      value || 0,
    );
    if (advisors) {
      setAvailableAdvisors(
        advisors.map((a) => ({ name: a.firstName, value: a.id })),
      );
    }
  }, []);

  useEffect(() => {
    (async () => {
      const advisoryOrg = await advisorService.getAdvisoryOrgsList();
      if (advisoryOrg) {
        setavailableAdvisoryOrgs([
          { name: 'All', value: 'All' },
          ...advisoryOrg.map((a) => ({ name: a.name, value: a.id })),
        ]);
      }
      getAdvisorsList(undefined);
    })();
  }, []);

  const onSelectAdvisoryOrg = useCallback((value) => {
    setOrganization(value);
    getAdvisorsList(value === 'All' ? 0 : value);
  }, []);

  return (
    <BaseModal
      headerIcon={headerIcon}
      modalTitle={t('common.modal.advisor_referral.title')}
      isCloseIconVisible
      closeOnBackgroundClick
      onModalClose={closeModal}
      withDeclineButton
      onDeclineButtonClick={closeModal}
      declineButtonText={t('common.cancel')}
      withAcceptButton
      onAcceptButtonClick={refferAdvisor}
      acceptButtonText={t('common.modal.advisor_referral.accept_button')}
      disableAcceptButton={isDisableAcceptButton}
      classNames={{
        modal: 'advisor_referral_modal',
        modalTitle: 'advisor_referral_modal__title',
        modalHeader: 'advisor_referral_modal__header',
        modalInnerContainer: 'advisor_referral_modal__inner_container',
        acceptButton: 'modal-footer-buttons__acceptButton',
        declineButton: 'modal-footer-buttons__declineButton',
        closeIcon: 'advisor_referral_modal__closeIcon',
        modalFooter: 'advisor_referral_modal__footer',
      }}
    >
      {error && <ErrorsText errorsListOrErrorText={error} />}
      <ProfileFieldWrapper
        title={t('common.modal.advisor_referral.questions.organizations.title')}
        subTitle={t(
          'common.modal.advisor_referral.questions.organizations.description',
        )}
      >
        <DropDownList
          placeholder=""
          value={organization || ''}
          onChange={(value) => onSelectAdvisoryOrg(value)}
          availableValuesList={availableAdvisoryOrgs}
          isArrow
        />
      </ProfileFieldWrapper>
      <ProfileFieldWrapper
        title={t('common.modal.advisor_referral.questions.advisor.title')}
        subTitle={t(
          'common.modal.advisor_referral.questions.advisor.description',
        )}
        addAsteriskToTitle
      >
        <DropDownList
          placeholder={t(
            'common.modal.advisor_referral.questions.advisor.placeholder',
          )}
          value={advisor || ''}
          onChange={(value) => setAdvisor(value)}
          availableValuesList={availableAdvisors}
          isArrow
        />
      </ProfileFieldWrapper>
      <ProfileFieldWrapper
        title={t('common.modal.advisor_referral.questions.note.title')}
        subTitle={t('common.modal.advisor_referral.questions.note.description')}
        addAsteriskToTitle
      >
        <Textarea
          type="text"
          placeholder={t(
            'common.modal.advisor_referral.questions.note.placeholder',
          )}
          value={note || ''}
          onChange={(value) => setNote(value)}
          maxLength={200}
        />
      </ProfileFieldWrapper>
    </BaseModal>
  );
};

export default memo(AdvisorRefferalModal);
