import { TFunction } from 'i18next';

import {
  HeaderTabs,
  advisorHeaderTabs,
  unauthenticatedHeaderTabs,
  sboHeaderTabs,
} from '_constants/headerConstants';

import { UserRolesEnum } from '_types/user.interface';
import { TenantModules, TenantTypeEnum } from '_types/tenant-settings';
import { UnauthHomePageConfigrationDataJSON } from '_types/tenant-settings/tenant-page-configuration/unauth-home-page';

const filterTabsWithTenantModules = (
  tabs: HeaderTabs[],
  tenantModules: TenantModules,
) => tabs.filter((tab) => !tab.moduleField || tenantModules[tab.moduleField]);

export const headerTabsConstantsFn = (
  role: UserRolesEnum | undefined,
  tenantModules: TenantModules | null | undefined,
  t: TFunction,
  tenantType?: TenantTypeEnum,
  pageConfiguration?: UnauthHomePageConfigrationDataJSON | null,
): HeaderTabs[] => {
  let headerTabsList: HeaderTabs[];

  switch (role) {
    case UserRolesEnum.ADVISORY_ORG_ADMIN:
    case UserRolesEnum.ADVISOR:
      headerTabsList = advisorHeaderTabs(t);
      break;
    case UserRolesEnum.SBO:
      headerTabsList = sboHeaderTabs(t);
      break;
    default:
      headerTabsList = unauthenticatedHeaderTabs(
        t,
        tenantType,
        pageConfiguration,
      );
      break;
  }

  if (!tenantModules) {
    return headerTabsList;
  }

  return filterTabsWithTenantModules(headerTabsList, tenantModules);
};
