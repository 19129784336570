import React, { memo, useCallback, useState } from 'react';
import { MdAddCircle } from 'react-icons/md';

import { ProfileFieldErrorType } from '_types/profile.interface';

import Button from 'app/components/Button';
import ProfileFieldWrapper from 'app/components/ProfileFields/common/ProfileFieldWrapper';
import ErrorsText from 'app/components/ErrorsText';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import useTenantColors from 'utils/hooks/useTenantColors';
import ZipCodesFileImportModal from './ZipCodesFileImportModal';

import './style.scss';

interface AdvisoryOrgZipCodesComponentProps {
  onChange: (value: string[]) => void;
  errors?: ProfileFieldErrorType;
}

const AdvisoryOrgZipCodesImport: React.FC<
  AdvisoryOrgZipCodesComponentProps
> = ({ onChange, errors }) => {
  const { t } = useTenantTranslation();
  const { colorVariables } = useTenantColors();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSuccessfullyUploaded, setIsSuccessfullyUploaded] =
    useState<boolean>(false);

  const onModalOpen = useCallback(() => {
    setIsSuccessfullyUploaded(false);
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const onModalClose = useCallback(
    () => setIsModalOpen(false),
    [setIsModalOpen],
  );

  return (
    <ProfileFieldWrapper
      addAsteriskToTitle
      fieldClassName="advisory-org-zip-codes"
      title={t('onboarding.advisory_org_zip_codes.title')}
      subTitle={t('onboarding.advisory_org_zip_codes.subtitle')}
    >
      {!!errors?.length && <ErrorsText errorsListOrErrorText={errors} />}

      {isSuccessfullyUploaded && (
        <p className="advisory-org-zip-codes-success-msg">
          {t('onboarding.advisory_org_zip_codes.successfully_uploaded')}
        </p>
      )}

      <Button
        className="advisory-org-zip-codes__button"
        btnLook="filled"
        btnType="button"
        type="btn"
        size="medium"
        onClick={onModalOpen}
        rightIcon={
          <MdAddCircle
            size={20}
            color={colorVariables?.icons.icon_secondary_tetriary_button}
          />
        }
      >
        {t('onboarding.advisory_org_zip_codes.upload_zip_codes_btn')}
      </Button>

      {isModalOpen && (
        <ZipCodesFileImportModal
          setIsSuccessfullyUploaded={setIsSuccessfullyUploaded}
          onModalClose={onModalClose}
          onChange={onChange}
        />
      )}
    </ProfileFieldWrapper>
  );
};

export default memo(AdvisoryOrgZipCodesImport);
