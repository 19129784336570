import React, { memo, useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MdOutlineInfo } from 'react-icons/md';
import { phoneConstant } from '_constants/otherConstants';
import AvatarCircle from 'app/components/AvatarCircle/AvatarCircle';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { showCohortExpandedViewModalSelector } from 'store/selectors/modal';
import {
  actionHandleShowCohortExpandedViewModal,
  actionHandleShowHasLicenseModal,
} from 'store/reducers/modal';
import { tenantSettingsTenantStylingSelector } from 'store/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useTenantColors from 'utils/hooks/useTenantColors';
import { useCurrentPlatformLanguage } from 'utils/translations';

import BaseModal from '../BaseModal';

import './style.scss';
import CohortCardExpandedCenterView from './CohortCardExpandedCenterView';

const CohortCardExpandedViewModal: React.FC = () => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });
  const { t } = useTenantTranslation();
  const { colorVariables } = useTenantColors();
  const dispatch = useAppDispatch();
  const tenantStyling = useAppSelector(tenantSettingsTenantStylingSelector);
  const currentLanguage = useCurrentPlatformLanguage();
  const cohortData = useAppSelector(showCohortExpandedViewModalSelector);

  const classNames = {
    modalContainer: 'custom_cohort_modal_container',
    modal: 'custom_modal',
    modalInnerContainer: 'custom_modal_inner_container',
    modalHeader: 'custom_modal_header',
    modalTitle: 'custom_modal_title',
    modalContent: 'custom_modal_content',
    modalFooter: cohortData?.applicationUrl
      ? 'custom_modal_footer'
      : 'custom_modal_footer_center',
    acceptButton: 'custom_modal_acceptButton',
    declineButton: 'custom_modal_declinedButton ',
  };

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowCohortExpandedViewModal(null));
  }, [dispatch]);

  const headerIcon = useMemo(
    () => (
      <AvatarCircle
        src={cohortData?.logo}
        firstName={cohortData?.name || ''}
        lastName=""
        size={{ small: '80', large: '80' }}
      />
    ),
    [cohortData?.logo],
  );

  const openExternalWebsite = useCallback(
    (url: string) => {
      closeModal();
      if (!cohortData?.hasLicense) {
        dispatch(
          actionHandleShowHasLicenseModal({
            externalUrl: url || '#',
          }),
        );
      } else {
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    },
    [cohortData],
  );

  return (
    <BaseModal
      onModalClose={closeModal}
      isCloseIconVisible
      closeOnBackgroundClick
      backgroundScrollDisabled
      headerIcon={headerIcon}
      modalTitle={cohortData?.name}
      classNames={classNames}
      withAcceptButton
      acceptButtonSize={isPhone ? 'medium' : 'large'}
      acceptButtonText={t('common.apply_online')}
      onAcceptButtonClick={() =>
        openExternalWebsite(cohortData?.applicationUrl || '#')
      }
      withDeclineButton
      declineButtonText={t('common.visit_website')}
      onDeclineButtonClick={() =>
        openExternalWebsite(cohortData?.programLeadUrl || '#')
      }
      declineButtonSize={isPhone ? 'medium' : 'large'}
      declineButtonLook="filled"
      declineButtonRightIcon={
        <MdOutlineInfo
          size={24}
          color={colorVariables?.icons.icon_secondary_tetriary_button}
        />
      }
    >
      {cohortData && <CohortCardExpandedCenterView cohortData={cohortData} />}
    </BaseModal>
  );
};

export default memo(CohortCardExpandedViewModal);
