import React, { memo, useEffect, useState } from 'react';

import { ProfileFieldErrorType } from '_types/profile.interface';

import ErrorCloud from '../ErrorCloud';

import './style.scss';

interface TextareaProps {
  type: string;
  autocomplete?: boolean;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  placeholder: string;
  readonly?: boolean;
  value: string;
  onChange: Function;
  width?: string;
  errors?: ProfileFieldErrorType;
}
const Textarea: React.FC<TextareaProps> = ({
  type,
  autocomplete,
  disabled,
  maxLength,
  minLength,
  placeholder,
  readonly,
  value,
  onChange,
  width,
  errors,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  useEffect(() => {
    if (!focus && value !== value?.trim()) {
      onChange(value?.trim());
    }
  }, [focus]);

  return (
    <div
      className={`text_area__field  ${focus ? 'focus' : ''}`}
      style={{ width: width || '100% ' }}
    >
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        maxLength={maxLength || undefined}
      />
      {!!errors?.length && !focus && (
        <ErrorCloud errorsListOrErrorText={errors} />
      )}
    </div>
  );
};

export default memo(Textarea);
