import React, { memo } from 'react';
import clsx from 'clsx';

import './style.scss';

interface ProgressBarInfiniteProps {
  className?: string;
}

const ProgressBarInfinite: React.FC<ProgressBarInfiniteProps> = memo(
  ({ className }) => (
    <div className={clsx('progress_bar_infinite', className)}>
      <div className="progress_bar_infinite__line" />
    </div>
  ),
);

export default ProgressBarInfinite;
