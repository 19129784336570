import React, { memo } from 'react';
import useTenantColors from 'utils/hooks/useTenantColors';

interface Props {
  className?: string;
}

const SecurityIcon: React.FC<Props> = ({ className }) => {
  const { colorVariables } = useTenantColors();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM12 11.99H19C18.47 16.11 15.72 19.78 12 20.93V12H5V6.3L12 3.19V11.99Z"
        fill={colorVariables?.icons.icon_primary2_button || '#053B66'}
      />
    </svg>
  );
};

export default memo(SecurityIcon);
