import { useEffect } from 'react';

import { fetchGetOnboardingQuestionConfiguration } from 'store/actions/onboarding';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import {
  onboardingQuestionConfigurationSelector,
  onboardingQuestionRequestStateSelector,
} from 'store/selectors/onboarding';

const useOnboardingFieldConfiguration = (componentName: string) => {
  const dispatch = useAppDispatch();

  const fieldConfiguration = useAppSelector((state) =>
    onboardingQuestionConfigurationSelector(state, componentName),
  );

  const fieldConfigurationRequestState = useAppSelector((state) =>
    onboardingQuestionRequestStateSelector(state, componentName),
  );

  useEffect(() => {
    if (!fieldConfiguration && !fieldConfigurationRequestState?.isFetched) {
      dispatch(fetchGetOnboardingQuestionConfiguration(componentName));
    }
  }, []);

  return fieldConfiguration;
};

export default useOnboardingFieldConfiguration;
