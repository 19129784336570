import { TenantStyling } from '_types/tenant-settings/tenant-styling.interface';

export const changeColorSettings = (tenantStyling: TenantStyling) => {
  document.documentElement.style.setProperty(
    '--main-blue',
    tenantStyling.mainColor,
  );
  document.documentElement.style.setProperty(
    '--main-yellow',
    tenantStyling.mainYellow,
  );
  document.documentElement.style.setProperty(
    '--secondary-dark-blue',
    tenantStyling.secondaryColor,
  );
  document.documentElement.style.setProperty(
    '--secondary-teal',
    tenantStyling.secondaryTeal,
  );
  document.documentElement.style.setProperty(
    '--secondary-4',
    tenantStyling.secondary04,
  );
  document.documentElement.style.setProperty(
    '--secondary-5',
    tenantStyling.secondary05,
  );
  document.documentElement.style.setProperty(
    '--secondary-6',
    tenantStyling.secondary06,
  );
  document.documentElement.style.setProperty(
    '--secondary-8',
    tenantStyling.secondary08,
  );
  document.documentElement.style.setProperty(
    '--shades-8',
    tenantStyling.shades08,
  );
  document.documentElement.style.setProperty(
    '--shades-9',
    tenantStyling.shades09,
  );
  document.documentElement.style.setProperty(
    '--shades-10',
    tenantStyling.shades10,
  );
  document.documentElement.style.setProperty(
    '--shades-12',
    tenantStyling.shades12,
  );

  /* Re-design Colors
   ******************** */

  // Background
  document.documentElement.style.setProperty(
    '--background-primary-light',
    tenantStyling.colorVariables.background.background_primary_light,
  );
  document.documentElement.style.setProperty(
    '--background-primary-dark',
    tenantStyling.colorVariables.background.background_primary_dark,
  );
  document.documentElement.style.setProperty(
    '--background-secondary',
    tenantStyling.colorVariables.background.background_secondary,
  );
  document.documentElement.style.setProperty(
    '--background-section',
    tenantStyling.colorVariables.background.background_section,
  );
  document.documentElement.style.setProperty(
    '--background-avatar-with-letters',
    tenantStyling.colorVariables.background.background_avatar_with_letters,
  );
  document.documentElement.style.setProperty(
    '--background-avatar-organization',
    tenantStyling.colorVariables.background.background_avatar_organization,
  );
  document.documentElement.style.setProperty(
    '--background-glossary',
    tenantStyling.colorVariables.background.background_glossary,
  );
  document.documentElement.style.setProperty(
    '--background-icon',
    tenantStyling.colorVariables.background.background_icon,
  );
  document.documentElement.style.setProperty(
    '--background-card',
    tenantStyling.colorVariables.background.background_card,
  );

  // Border
  document.documentElement.style.setProperty(
    '--border-cards',
    tenantStyling.colorVariables.borders.border_cards,
  );
  document.documentElement.style.setProperty(
    '--border-dropdown-filters',
    tenantStyling.colorVariables.borders.border_dropdown_filters,
  );
  document.documentElement.style.setProperty(
    '--border-tetriary-button',
    tenantStyling.colorVariables.borders.border_tetriary_button,
  );
  document.documentElement.style.setProperty(
    '--border-search-field',
    tenantStyling.colorVariables.borders.border_search_field,
  );
  document.documentElement.style.setProperty(
    '--border-avatar',
    tenantStyling.colorVariables.borders.border_avatar,
  );
  document.documentElement.style.setProperty(
    '--border-funding',
    tenantStyling.colorVariables.borders.border_funding,
  );
  document.documentElement.style.setProperty(
    '--border-field-hover',
    tenantStyling.colorVariables.borders.border_field_hover,
  );

  // Buttons
  document.documentElement.style.setProperty(
    '--button-primary1-active',
    tenantStyling.colorVariables.buttons.button_primary1_active,
  );
  document.documentElement.style.setProperty(
    '--button-primary1-hover',
    tenantStyling.colorVariables.buttons.button_primary1_hover,
  );
  document.documentElement.style.setProperty(
    '--button-primary2-active',
    tenantStyling.colorVariables.buttons.button_primary2_active,
  );
  document.documentElement.style.setProperty(
    '--button-secondary',
    tenantStyling.colorVariables.buttons.button_secondary,
  );
  document.documentElement.style.setProperty(
    '--button-tetriary-active',
    tenantStyling.colorVariables.buttons.button_tetriary_active,
  );
  document.documentElement.style.setProperty(
    '--button-pagination-selected',
    tenantStyling.colorVariables.buttons.button_pagination_selected,
  );
  document.documentElement.style.setProperty(
    '--button-pagination-unselected',
    tenantStyling.colorVariables.buttons.button_pagination_unselected,
  );
  document.documentElement.style.setProperty(
    '--button-notification-unselected',
    tenantStyling.colorVariables.buttons.button_notification_unselected,
  );
  document.documentElement.style.setProperty(
    '--button-notification-selected',
    tenantStyling.colorVariables.buttons.button_notification_selected,
  );
  document.documentElement.style.setProperty(
    '--button-radio-button-check-box',
    tenantStyling.colorVariables.buttons.button_radio_button_check_box,
  );

  // Error Alert
  document.documentElement.style.setProperty(
    '--error-alert',
    tenantStyling.colorVariables.error_alert.error_alert,
  );

  // Icons
  document.documentElement.style.setProperty(
    '--icon-primary1-button',
    tenantStyling.colorVariables.icons.icon_primary1_button,
  );
  document.documentElement.style.setProperty(
    '--icon-primary2-button',
    tenantStyling.colorVariables.icons.icon_primary2_button,
  );
  document.documentElement.style.setProperty(
    '--icon-secondary-tetriary-button',
    tenantStyling.colorVariables.icons.icon_secondary_tetriary_button,
  );
  document.documentElement.style.setProperty(
    '--icon-dropdown',
    tenantStyling.colorVariables.icons.icon_dropdown,
  );
  document.documentElement.style.setProperty(
    '--icon-pagination',
    tenantStyling.colorVariables.icons.icon_pagination,
  );
  document.documentElement.style.setProperty(
    '--icon-notification-unselected',
    tenantStyling.colorVariables.icons.icon_notification_unselected,
  );
  document.documentElement.style.setProperty(
    '--icon-notification-selected',
    tenantStyling.colorVariables.icons.icon_notification_selected,
  );
  document.documentElement.style.setProperty(
    '--icon-notification-hover',
    tenantStyling.colorVariables.icons.icon_notification_hover,
  );
  document.documentElement.style.setProperty(
    '--icon-secondary',
    tenantStyling.colorVariables.icons.icon_secondary,
  );

  // Lines
  document.documentElement.style.setProperty(
    '--line-section',
    tenantStyling.colorVariables.lines.line_section,
  );
  document.documentElement.style.setProperty(
    '--line-footer',
    tenantStyling.colorVariables.lines.line_footer,
  );
  document.documentElement.style.setProperty(
    '--line-section-title-scroll',
    tenantStyling.colorVariables.lines.line_section_title_scroll,
  );
  document.documentElement.style.setProperty(
    '--line-header',
    tenantStyling.colorVariables.lines.line_header,
  );

  // Switcher
  document.documentElement.style.setProperty(
    '--switcher-selected',
    tenantStyling.colorVariables.switcher.switcher_selected,
  );
  document.documentElement.style.setProperty(
    '--switcher-unselected',
    tenantStyling.colorVariables.switcher.switcher_unselected,
  );

  // Tags
  document.documentElement.style.setProperty(
    '--tag-primary-background',
    tenantStyling.colorVariables.tags.tag_primary_background,
  );
  document.documentElement.style.setProperty(
    '--tag-secondary-background',
    tenantStyling.colorVariables.tags.tag_secondary_background,
  );

  // Texts
  document.documentElement.style.setProperty(
    '--text-primary1',
    tenantStyling.colorVariables.text.text_primary1,
  );
  document.documentElement.style.setProperty(
    '--text-primary2',
    tenantStyling.colorVariables.text.text_primary2,
  );
  document.documentElement.style.setProperty(
    '--text-secondary',
    tenantStyling.colorVariables.text.text_secondary,
  );
  document.documentElement.style.setProperty(
    '--text-accent-1',
    tenantStyling.colorVariables.text.text_accent_1,
  );
  document.documentElement.style.setProperty(
    '--text-accent-card',
    tenantStyling.colorVariables.text.text_accent_card,
  );
  document.documentElement.style.setProperty(
    '--text-accent-2',
    tenantStyling.colorVariables.text.text_accent_2,
  );
  document.documentElement.style.setProperty(
    '--text-buttons-primary',
    tenantStyling.colorVariables.text.text_buttons_primary,
  );
  document.documentElement.style.setProperty(
    '--text-buttons-secondary',
    tenantStyling.colorVariables.text.text_buttons_secondary,
  );
  document.documentElement.style.setProperty(
    '--text-on-the-dark-background',
    tenantStyling.colorVariables.text.text_on_the_dark_background,
  );
  document.documentElement.style.setProperty(
    '--text-search-field-default',
    tenantStyling.colorVariables.text.text_search_field_default,
  );
  document.documentElement.style.setProperty(
    '--text-primary1-lighten',
    tenantStyling.colorVariables.text.text_primary1_lighten,
  );
};
