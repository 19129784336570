import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SessionInfoType } from '_types/sessions.interface';
import type {
  InfoModalType,
  ShowResetPasswordModalType,
  GeneralModalType,
  NonDigitalFundingInfoModalType,
  LoginModalType,
  ProfileMissingDataModalType,
  ProfileMissingDataSuccessModal,
  ExternalUrlConsentModalType,
  FundingCardExpandedViewModalType,
  ProgramAndResourcesModalType,
  ShowHasLicenseModalType,
} from '_types/modals.interface';
import { CohortItemType } from '_types/cohort.interface ';
import { SboAsClient } from '_types/sbo.interface';
import { ReferredAdvisorCardData } from '_types/advisor.interface';
import { PartnerResourcesItemType } from '_types/resources.interface';

interface ModalState {
  showLoginModal: LoginModalType | null;
  showResetPasswordModal: ShowResetPasswordModalType | null;
  showResetEmailModal: boolean;
  showAccountDeletionModal: boolean;
  showRateSessionModal: SessionInfoType | null;
  showInfoModal: InfoModalType | null;
  showGeneralModal: GeneralModalType | null;
  showNonDigitalFundingInfoModal: NonDigitalFundingInfoModalType | null;
  showProfileMissingDataModal: ProfileMissingDataModalType | null;
  showProfileMissingDataSuccessModal: ProfileMissingDataSuccessModal | null;
  showExternalUrlConsentModal: ExternalUrlConsentModalType | null;
  showHasLicenseModal: ShowHasLicenseModalType | null;
  showFundingCardExpandedViewModal: FundingCardExpandedViewModalType | null;
  showCohortCardExpandedViewModal: CohortItemType | null;
  showMenuModal: boolean;
  showCalculatorModal: boolean;
  showCalendlyLinksModal: boolean;
  hasCalendlyLinksModalBeenShown: boolean;
  showAdvisorReferralModal: SboAsClient | null;
  showAdvisorReferralDeclineModal: ReferredAdvisorCardData | null;
  showProgramsAndResourcesModal: ProgramAndResourcesModalType | null;
}

export const modalSliceInitialState: ModalState = {
  showLoginModal: null,
  showResetPasswordModal: null,
  showResetEmailModal: false,
  showAccountDeletionModal: false,
  showRateSessionModal: null,
  showInfoModal: null,
  showGeneralModal: null,
  showNonDigitalFundingInfoModal: null,
  showProfileMissingDataModal: null,
  showProfileMissingDataSuccessModal: null,
  showExternalUrlConsentModal: null,
  showHasLicenseModal: null,
  showFundingCardExpandedViewModal: null,
  showCohortCardExpandedViewModal: null,
  showMenuModal: false,
  showCalculatorModal: false,
  showCalendlyLinksModal: false,
  hasCalendlyLinksModalBeenShown: false,
  showAdvisorReferralModal: null,
  showAdvisorReferralDeclineModal: null,
  showProgramsAndResourcesModal: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: modalSliceInitialState,
  reducers: {
    resetState: () => modalSliceInitialState,
    actionHandleShowLoginModal(
      state,
      action: PayloadAction<LoginModalType | null>,
    ) {
      state.showLoginModal = action.payload;
    },
    actionHandleShowResetPasswordModal(
      state,
      action: PayloadAction<ShowResetPasswordModalType | null>,
    ) {
      state.showResetPasswordModal = action.payload;
    },
    actionHandleShowResetEmailModal(state, action: PayloadAction<boolean>) {
      state.showResetEmailModal = action.payload;
    },
    actionHandleShowAccountDeletionModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.showAccountDeletionModal = action.payload;
    },
    actionHandleShowRateSessionModal(
      state,
      action: PayloadAction<SessionInfoType | null>,
    ) {
      state.showRateSessionModal = action.payload;
    },
    actionHandleShowInfoModal(
      state,
      action: PayloadAction<InfoModalType | null>,
    ) {
      state.showInfoModal = action.payload;
    },
    actionHandleShowGeneralModal(
      state,
      action: PayloadAction<GeneralModalType | null>,
    ) {
      state.showGeneralModal = action.payload;
    },
    actionHandleShowNonDigitalFundingInfoModal(
      state,
      action: PayloadAction<NonDigitalFundingInfoModalType | null>,
    ) {
      state.showNonDigitalFundingInfoModal = action.payload;
    },
    actionHandleShowProfileMissingDataModal(
      state,
      action: PayloadAction<ProfileMissingDataModalType | null>,
    ) {
      state.showProfileMissingDataModal = action.payload;
    },
    actionHandleShowProfileMissingDataSuccessModal(
      state,
      action: PayloadAction<ProfileMissingDataSuccessModal | null>,
    ) {
      state.showProfileMissingDataSuccessModal = action.payload;
    },
    actionHandleShowExternalUrlConsentModal(
      state,
      action: PayloadAction<ExternalUrlConsentModalType | null>,
    ) {
      state.showExternalUrlConsentModal = action.payload;
    },
    actionHandleShowHasLicenseModal(
      state,
      action: PayloadAction<ShowHasLicenseModalType | null>,
    ) {
      state.showHasLicenseModal = action.payload;
    },
    actionHandleShowFundingExpandedViewModal(
      state,
      action: PayloadAction<FundingCardExpandedViewModalType | null>,
    ) {
      state.showFundingCardExpandedViewModal = action.payload;
    },
    actionHandleShowCohortExpandedViewModal(
      state,
      action: PayloadAction<CohortItemType | null>,
    ) {
      state.showCohortCardExpandedViewModal = action.payload;
    },
    actionHandleShowMenuModal(state, action: PayloadAction<boolean>) {
      state.showMenuModal = action.payload;
    },
    actionHandleShowCalculatorModal(state, action: PayloadAction<boolean>) {
      state.showCalculatorModal = action.payload;
    },
    actionHandleShowCalendlyLinksModal(state, action: PayloadAction<boolean>) {
      state.showCalendlyLinksModal = action.payload;
    },
    actionHandleHasCalendlyLinksModalBeenShown(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.hasCalendlyLinksModalBeenShown = action.payload;
    },
    actionHandleShowAdvisorReferralModal(
      state,
      action: PayloadAction<SboAsClient | null>,
    ) {
      state.showAdvisorReferralModal = action.payload;
    },
    actionHandleShowAdvisorReferralDeclineModal(
      state,
      action: PayloadAction<ReferredAdvisorCardData | null>,
    ) {
      state.showAdvisorReferralDeclineModal = action.payload;
    },
    actionHandleShowProgramsAndResourcesModal(
      state,
      action: PayloadAction<ProgramAndResourcesModalType | null>,
    ) {
      state.showProgramsAndResourcesModal = action.payload;
    },
    actionHandleChangeProgramAndResourceModal(
      state,
      action: PayloadAction<Partial<PartnerResourcesItemType>>,
    ) {
      state.showProgramsAndResourcesModal!.data = action.payload;
    },
  },
});

export const {
  actionHandleShowLoginModal,
  actionHandleShowResetPasswordModal,
  actionHandleShowResetEmailModal,
  actionHandleShowAccountDeletionModal,
  actionHandleShowRateSessionModal,
  actionHandleShowInfoModal,
  actionHandleShowGeneralModal,
  actionHandleShowNonDigitalFundingInfoModal,
  actionHandleShowProfileMissingDataModal,
  actionHandleShowProfileMissingDataSuccessModal,
  actionHandleShowExternalUrlConsentModal,
  actionHandleShowHasLicenseModal,
  actionHandleShowFundingExpandedViewModal,
  actionHandleShowCohortExpandedViewModal,
  actionHandleShowMenuModal,
  actionHandleShowCalculatorModal,
  actionHandleShowCalendlyLinksModal,
  actionHandleHasCalendlyLinksModalBeenShown,
  actionHandleShowAdvisorReferralModal,
  actionHandleShowAdvisorReferralDeclineModal,
  actionHandleShowProgramsAndResourcesModal,
  actionHandleChangeProgramAndResourceModal,
  resetState,
} = modalSlice.actions;

export default modalSlice;
