import React, { memo } from 'react';
import useTenantColors from 'utils/hooks/useTenantColors';

interface Props {
  className?: string;
}

const GrowthIcon: React.FC<Props> = ({ className }) => {
  const { colorVariables } = useTenantColors();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 4H19.5V20H16.5V4ZM4.5 14H7.5V20H4.5V14ZM10.5 9H13.5V20H10.5V9Z"
        fill={colorVariables?.icons.icon_primary2_button || '#053B66'}
      />
    </svg>
  );
};

export default memo(GrowthIcon);
