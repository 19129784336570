import React, { ReactElement } from 'react';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

import { UserRolesEnum } from '_types/user.interface';

import ScheduleIcon from 'app/components/Icons/ScheduleIcon';

import { NotificationUserMentioned } from '../../types';
import { LinkToObjectType } from '../NotificationBlock/types';

import {
  MentionNotificationObjectMentionedInInterface,
  MentionNotificationObjectMentionedInTypeEnum,
} from './types';

export const getTitleBlock = (
  type: MentionNotificationObjectMentionedInTypeEnum,
  userMentioned: NotificationUserMentioned,
  t: TFunction,
): ReactElement | null => {
  switch (type) {
    case MentionNotificationObjectMentionedInTypeEnum.SESSION_COMMENT:
      return (
        <Trans components={[<span key="0" />]}>
          {t('notifications.title.mention_session_comment_title', {
            name: `${userMentioned.firstName} ${userMentioned.lastName}`,
          })}
        </Trans>
      );
    default:
      return null;
  }
};

export const getContentBlock = (
  type: MentionNotificationObjectMentionedInTypeEnum,
  objectMentionedIn: MentionNotificationObjectMentionedInInterface,
): ReactElement | null => {
  switch (type) {
    case MentionNotificationObjectMentionedInTypeEnum.SESSION_COMMENT:
      return <p className="comment">{objectMentionedIn.text}</p>;
    default:
      return null;
  }
};

export const getLinkToObject = (
  type: MentionNotificationObjectMentionedInTypeEnum,
  objectMentionedIn: MentionNotificationObjectMentionedInInterface,
  currentUserRole: UserRolesEnum,
  t: TFunction,
): LinkToObjectType | null => {
  switch (type) {
    case MentionNotificationObjectMentionedInTypeEnum.SESSION_COMMENT:
      return {
        startIcon: <ScheduleIcon />,
        parts: [
          {
            content: <>{t('common.session_upper')}</>,
            link:
              currentUserRole === UserRolesEnum.SBO
                ? '/advisors/list'
                : '/clients',
          },
          {
            content: objectMentionedIn.session.name,
            link: `/advisors/session/${objectMentionedIn.session.id}`,
          },
        ],
      };
    default:
      return null;
  }
};
