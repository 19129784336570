export const formatMoney = (value: number): string => {
  if (value === Number.POSITIVE_INFINITY) {
    return `$${value}`;
  }

  if (value >= 10 ** 3 && value < 10 ** 6) {
    return `$${value / 10 ** 3}K`;
  }

  if (value >= 10 ** 6 && value < 10 ** 9) {
    return `$${value / 10 ** 6}M`;
  }

  if (value >= 10 ** 9) {
    return `$${value / 10 ** 9}B`;
  }

  return `$${value}`;
};

export const addSubAddressToEmail = (
  email: string,
  subAddress: string,
): string => {
  // If the email already has a subaddress, add _subaddress to the subaddress
  // Otherwise, add +postfix to the first email part
  const [emailPart, domain] = email.split('@');
  const [firstEmailPart, ...restEmailParts] = emailPart.split('+');
  const oldSubaddress = restEmailParts.join('+');

  if (oldSubaddress === subAddress) {
    return email;
  }

  if (oldSubaddress) {
    return `${firstEmailPart}+${oldSubaddress}_${subAddress}@${domain}`;
  }

  return `${firstEmailPart}+${subAddress}@${domain}`;
};
