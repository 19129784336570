import { ReactNode } from 'react';

import { TenantFilterTypeEnum } from '_types';

import type { ProfileCalendlyLinks } from './calendly.interface';
import type { UserRolesEnum } from './user.interface';
import { Tenant } from './tenant.interface';
import { ProfileSBOInfo } from './profile.interface';

export interface AdvisorCardData {
  id: number;
  advisoryOrgName: string;
  bio: string;
  firstName: string;
  lastName: string;
  photo: string | null;
  weeklyHours: string;
  nextAvailable: string;
  isCFO: boolean;
}

export interface FullAdvisorData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  advisoryOrgName: string;
  languages: string[];
  topics: string[];
  bio: string;
  photo: string;
  video: string;
  affiliations: string[];
  website: string;
  phoneNumber: string;
  linkedIn: string;
  twitter: string;
  instagram: string;
  facebook: string;
  advisorCalendlyLinks: ProfileCalendlyLinks;
  nextAvailable: string;
  logo: string;
  overview: string;
  advisoryOrgId: number;
  isCFO: boolean;
}

export type PaginatedAdvisors = {
  count: number;
  limit: number;
  offset: number;
  advisorCards: AdvisorCardData[];
};

export interface AdvisorChosenFilterListsType {
  organizations?: string[];
  topics?: string[];
  sboCommunities?: string[];
  industry?: string[];
  state?: string[];
  businessStage?: string[];
  location?: string[];
}

export interface RateStepsType {
  title: ReactNode;
  subtitle?: string;
}

export interface AdvisorSmallData {
  id: number;
  userId: number;
  userRole: UserRolesEnum;
  firstName: string;
  lastName: string;
  email: string;
  photo: string;
}

export type AdvisorSortingOrder = 'ASC' | 'DESC' | 'RANDOM';

export interface AdvisorFilterRequestBody {
  organizations?: string[];
  organizationId?: number;
  topics?: string[];
  sboCommunities?: string[];
  sortingOrder?: AdvisorSortingOrder;
  search?: string; // = ''
  isGlobal?: boolean | null; // = true
}

export type AvailableAdvisorFilterOptionType =
  | 'organizations'
  | 'topics'
  | 'industry'
  | 'sboCommunities'
  | 'state';

export type AdvisorFilterOptionListType = {
  availableValues: Array<string | { value: any; name: string }>;
  anyText: string;
  description?: string;
  title: string;
  phoneText?: string;
  buttonText: string;
  isMultiple: boolean;
};

export type AdvisorFilterOptions = Record<
  AvailableAdvisorFilterOptionType,
  AdvisorFilterOptionListType
>;
export interface AdvisorFilterSettings {
  id: number;
  tenantId: number;
  filterType: TenantFilterTypeEnum;
  filterSetting: AdvisorFilterOptions;
  isEnabled: boolean;
  filterText: string;
}

export enum AdvisorReferralStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export interface AdvisorReferral {
  refereeAdvisorId: number;
  referredAdvisorId: number;
  sboId: number;
  note: string;
  status: AdvisorReferralStatus;
}

export interface FetchReferredAdvisor {
  id: number;
  note: string;
  reasonToDecline: string;
  refereeAdvisor: AdvisorCardData;
  referredAdvisor: AdvisorCardData;
  sboInfo: ProfileSBOInfo;
  status: AdvisorReferralStatus;
  tenant: Tenant;
}

export interface PatchReferredAdvisor {
  id: number;
  status: AdvisorReferralStatus;
  reasonToDecline: string;
}

export interface ReferredAdvisorCardData {
  id: number;
  advisoryOrgName: string;
  bio: string;
  firstName: string;
  lastName: string;
  photo: string | null;
  weeklyHours: string;
  nextAvailable: string;
  referralId: number;
  status: AdvisorReferralStatus;
  note: string;
  reasonToDecline: string;
  isCFO: boolean;
}
