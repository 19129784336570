import type {
  OnboardingData,
  OnboardingProgress,
  UpdateOnboardingData,
  UpdateOnboardingProgress,
} from '_types/onboarding.interface';

import axiosApiInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const OnboardingService = {
  getOnboardingPages: () =>
    axiosApiInstance
      .get(`${baseUrl}/api/v1/onboarding/tenant-onboarding-pages/user-specific`)
      .then(({ data }) => data),
  getOnboardingProgress: () =>
    axiosApiInstance
      .get<OnboardingProgress>(
        `${baseUrl}/api/v1/onboarding/onboarding-progress`,
      )
      .then(({ data }) => data),
  putOnboardingProgress: (body: UpdateOnboardingProgress) =>
    axiosApiInstance
      .put<OnboardingProgress>(
        `${baseUrl}/api/v1/onboarding/onboarding-progress`,
        body,
      )
      .then(({ data }) => data),
  getOnboardingData: () =>
    axiosApiInstance
      .get<OnboardingData>(`${baseUrl}/api/v1/onboarding/onboarding-data`)
      .then(({ data }) => data),
  putOnboardingData: (body: UpdateOnboardingData) =>
    axiosApiInstance
      .put<OnboardingData>(`${baseUrl}/api/v1/onboarding/onboarding-data`, body)
      .then(({ data }) => data),
  getListAdvisoryOrgsBefore: () =>
    axiosApiInstance
      .get(`${baseUrl}/api/v1/onboarding-static-info`)
      .then(({ data }) => data),
  getOnboardingQuestionConfiguration: (componentName: string) =>
    axiosApiInstance
      .get(`${baseUrl}/api/v1/onboarding-questions/${componentName}`)
      .then(({ data }) => data),
};
export default OnboardingService;
