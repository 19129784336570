import React, { memo, useCallback, useMemo, useRef } from 'react';
import { TFunction } from 'i18next';

import { ProfileFieldErrorType } from '_types/profile.interface';
import { GeographyServeEnum } from '_types/onboarding.interface';

import DropDownList from 'app/components/DropDownList/DropDownList';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import useGoToFirstErrorId from 'utils/contexts/ProfileFieldIdsContext/useGoToFirstErrorId';
import ProfileFieldWrapper from '../../common/ProfileFieldWrapper';

import AdvisoryOrgStateSelectComponent from './AdvisoryOrgStateSelectComponent';
// import AdvisoryOrgZipCodes from './AdvisoryOrgZipCode';
import AdvisoryOrgZipCodesImport from '../AdvisoryOrgIsGlobalComponent/AdvisoryOrgZipCodesImport';

import './style.scss';

export const getAvailableGeographyServeList = (t: TFunction) => [
  {
    value: GeographyServeEnum.NATIONAL,
    name: t('myAccount.advisory_orggeography_serve.national'),
  },
  {
    value: GeographyServeEnum.STATE,
    name: t('myAccount.advisory_orggeography_serve.state'),
  },
  {
    value: GeographyServeEnum.ZIPCODESPECIFIC,
    name: t('myAccount.advisory_orggeography_serve.zipcode'),
  },
];

type GeographyServeObject = {
  geography: GeographyServeEnum;
  state: string[];
  zipCodes: string[];
};

interface AdvisoryOrgGeographyServeSelectComponentProps {
  onChange: (obj: Partial<GeographyServeObject>) => void;
  errors?: ProfileFieldErrorType;
  value: GeographyServeObject;
  // type: ProfileFieldLocationType;
}

const AdvisoryOrgGeographyServeSelectComponent: React.FC<
  AdvisoryOrgGeographyServeSelectComponentProps
> = ({
  onChange,
  errors,
  value,
  // type,
}) => {
  const fieldRef = useRef<HTMLDivElement>(null);
  const { t } = useTenantTranslation();

  const availableGeographyServeList = useMemo(
    () => getAvailableGeographyServeList(t),
    [t],
  );

  const getOnChangeGeographyServe = useCallback(
    (fieldName: keyof GeographyServeObject) =>
      (newValue: string | string[]) => {
        onChange({ [fieldName]: newValue });
      },
    [onChange],
  );

  const onChangeGeography = useCallback(
    getOnChangeGeographyServe('geography'),
    [getOnChangeGeographyServe],
  );

  const onChangeState = useCallback(getOnChangeGeographyServe('state'), [
    getOnChangeGeographyServe,
  ]);

  const onChangeZipCode = useCallback(getOnChangeGeographyServe('zipCodes'), [
    getOnChangeGeographyServe,
  ]);

  // const onZipCodesChange = useCallback(
  //   (zipCodes: string[]) => onChange({ zipCodes }),
  //   [onChange],
  // );

  const fieldId = useGoToFirstErrorId(['advisoryOrgData.geography']);

  return (
    <div className="advisoryOrg-Geography">
      <ProfileFieldWrapper
        title={t('onboarding.geography_serve.title')}
        id={fieldId}
        addAsteriskToTitle
      >
        <DropDownList
          placeholder={`${t('onboarding.geography_serve.placeholder')}`}
          value={value.geography || ''}
          onChange={onChangeGeography}
          availableValuesList={availableGeographyServeList}
          errors={errors}
          isArrow
        />
      </ProfileFieldWrapper>

      {value.geography === GeographyServeEnum.ZIPCODESPECIFIC && (
        // <AdvisoryOrgZipCodes
        //   onChange={onChangeZipCode}
        //   value=value{.zipCodes}
        // />
        <AdvisoryOrgZipCodesImport
          onChange={onChangeZipCode}
          // errors={errors?.zipCodes}
        />
      )}

      <div className="advisoryOrg-Geography__state">
        {value.geography === GeographyServeEnum.STATE && (
          <AdvisoryOrgStateSelectComponent
            onChange={onChangeState}
            value={value.state}
          />
        )}
      </div>
    </div>
  );
};

export default memo(AdvisoryOrgGeographyServeSelectComponent);
