import React, { memo, useCallback, useState } from 'react';
import { TFunction } from 'i18next';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
} from '@firebase/auth';
import { CgArrowRightO } from 'react-icons/cg';

import Button from 'app/components/Button';
import Input from 'app/components/InputComponents/Input';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantStylingSelector } from 'store/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { auth } from 'utils/firebase/FirebaseRemoteConfigInitialization';
import { getMultiFactorResolver } from 'utils/firebase/signin';

import '../style.scss';

import type { LoginGoToNextStepFnArg } from '../types';

const processReauthenticateError = (t: TFunction, error: any): string => {
  switch (error.code) {
    case 'auth/wrong-password':
    case 'auth/invalid-email':
    case 'auth/invalid-login-credentials':
      return t('validation.invalid_password');
    default:
      // eslint-disable-next-line no-console
      console.error(error);
      return t('common.error_occurred');
  }
};

export interface PasswordOnlyFormProps {
  onPasswordVerificationDone: (arg: LoginGoToNextStepFnArg) => Promise<void>;
}

const PasswordOnlyForm: React.FC<PasswordOnlyFormProps> = ({
  onPasswordVerificationDone,
}) => {
  const { t } = useTenantTranslation();

  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tenantStyle = useAppSelector(tenantSettingsTenantStylingSelector);

  const entryPassword = useCallback(
    (value: string | '') => {
      setPassword(value);
      setPasswordError('');
    },
    [setPassword, setPasswordError],
  );

  const handleReauthenticateByPassword = useCallback(
    async (e) => {
      e.preventDefault();

      setIsLoading(true);

      try {
        const creds = EmailAuthProvider.credential(
          auth.currentUser!.email!,
          password,
        );
        await reauthenticateWithCredential(auth.currentUser!, creds);

        await onPasswordVerificationDone({ isMfaRequired: false });
      } catch (error: any) {
        if (error.code === 'auth/multi-factor-auth-required') {
          // The user is a multi-factor user. Second factor challenge is required.
          const resolver = getMultiFactorResolver(auth, error);
          await onPasswordVerificationDone({
            isMfaRequired: true,
            multifactorResolver: resolver,
          });
        } else {
          const errorMessage = processReauthenticateError(t, error);
          setPasswordError(errorMessage);
        }
      }

      setIsLoading(false);
    },
    [t, password, setPasswordError],
  );

  return (
    <form
      className="login_page reauthenticate"
      onSubmit={handleReauthenticateByPassword}
    >
      <div className="title">
        <p>{t('auth_module.reauthenticate_title')}</p>
        <p>{t('auth_module.reauthenticate_text')}</p>
      </div>

      <div className="entry">
        <div className="entry_item">
          <p className="title">{t('common.password')}</p>
          <Input
            type="password"
            placeholder={`${t('common.password')}...`}
            value={password || ''}
            onChange={(value: string) => entryPassword(value)}
            errors={t(passwordError || '')}
          />
        </div>
      </div>
      <div className="login_page__btn">
        <Button
          type="btn"
          btnType="submit"
          rightIcon={
            <CgArrowRightO
              size={20}
              color={tenantStyle?.colorVariables.icons.icon_primary1_button}
            />
          }
          btnLook="filled"
          to="/auth"
          value={t('auth_module.login')}
          disabled={!password}
          size="large"
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default memo(PasswordOnlyForm);
