import { PageName, TenantLegalDocumentTypeEnum } from '_types/tenant-settings';
import { UserRolesEnum } from '_types/user.interface';

import type { TFunction } from 'i18next';
import type { ReactNode } from 'react';

export const getTenantLegalDocumentNameByType = (
  type: TenantLegalDocumentTypeEnum,
  t: TFunction,
): string => {
  switch (type) {
    case TenantLegalDocumentTypeEnum.SBO_AGREEMENT:
    case TenantLegalDocumentTypeEnum.ADVISOR_AGREEMENT:
      return t('common.footer_section.terms_use');
    case TenantLegalDocumentTypeEnum.PRIVACY_POLICY:
      return t('common.footer_section.privacy_policy');
    default:
      return 'null';
  }
};

export const getTenantLegalDocumentUrlByType = (
  type: TenantLegalDocumentTypeEnum,
): string => {
  if (Object.values(TenantLegalDocumentTypeEnum).includes(type)) {
    return `/legal-documents/${type}`;
  }

  return '#';
};

export const getTermsOfUseDocumentTypeByRole = (
  role: UserRolesEnum | undefined | null,
):
  | TenantLegalDocumentTypeEnum.SBO_AGREEMENT
  | TenantLegalDocumentTypeEnum.ADVISOR_AGREEMENT => {
  switch (role) {
    case UserRolesEnum.ADVISOR:
    case UserRolesEnum.ADVISORY_ORG_ADMIN:
      return TenantLegalDocumentTypeEnum.ADVISOR_AGREEMENT;
    default:
      return TenantLegalDocumentTypeEnum.SBO_AGREEMENT;
  }
};

export const getTenantLegalDocumentPageTitleByType = (
  type: TenantLegalDocumentTypeEnum,
  t: TFunction,
): ReactNode => {
  switch (type) {
    case TenantLegalDocumentTypeEnum.SBO_AGREEMENT:
      return t('agreement_module.user_header');
    case TenantLegalDocumentTypeEnum.ADVISOR_AGREEMENT:
      return t('agreement_module.advisor_header');
    case TenantLegalDocumentTypeEnum.PRIVACY_POLICY:
      return t('privacy_policy_module.header');
    default:
      return null;
  }
};

export const getTenantLegalDocumentPageMetadataKeyByType = (
  type: TenantLegalDocumentTypeEnum,
): PageName => {
  switch (type) {
    case TenantLegalDocumentTypeEnum.SBO_AGREEMENT:
      return PageName.SBO_USER_AGREEMENT;
    case TenantLegalDocumentTypeEnum.ADVISOR_AGREEMENT:
      return PageName.ADVISOR_USER_AGREEMENT;
    case TenantLegalDocumentTypeEnum.PRIVACY_POLICY:
      return PageName.PRIVACY_POLICY;
    default:
      return '' as PageName;
  }
};
