import React, { useState, useMemo, memo, useCallback } from 'react';
import clsx from 'clsx';

import { ProfileFieldErrorType } from '_types/profile.interface';
import { TranslateValueType } from '_types';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import EmptyArrowIcon from '../Icons/EmptyArrowIcon';
import ErrorCloud from '../ErrorCloud';

import './style.scss';
import ErrorsText from '../ErrorsText';

interface DropDownListProps {
  placeholder: string;
  value: string;
  onChange: (newValue: string) => void;
  errors?: ProfileFieldErrorType;
  availableValuesList: TranslateValueType[];
  isArrow?: boolean;
  listUpward?: boolean;
}

const DropDownList: React.FC<DropDownListProps> = ({
  placeholder,
  value,
  onChange,
  errors,
  availableValuesList,
  isArrow,
  listUpward,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const changeValue = useCallback(
    (newValue: string) => {
      onChange(newValue);
      setOpen(false);
    },
    [onChange, setOpen],
  );

  const handleLangModal = useCallback(() => setOpen(false), []);

  const { ref } = useOnClickOutside(open, handleLangModal);

  const selectedValue = useMemo(
    () => availableValuesList.find((elem) => elem.value === value)?.name,
    [value, availableValuesList],
  );

  const handleDropDownClick = () => {
    setOpen((value) => !value);
  };

  return (
    <div className="drop_down_container" ref={ref}>
      <div
        className={clsx('drop_down_container__text_field', {
          error: errors?.length,
          active: open,
        })}
        onClick={handleDropDownClick}
      >
        <p className={`${value ? 'value' : ''}`}>
          {selectedValue || placeholder}
        </p>

        {isArrow && (
          <EmptyArrowIcon className={`arrow ${open ? 'active' : ''}`} />
        )}
      </div>

      {!!errors?.length && <ErrorsText errorsListOrErrorText={errors} />}

      {open && (
        <div
          className={clsx('drop_down_container__list', { upward: listUpward })}
        >
          {availableValuesList?.map((elem) => (
            <div
              className="drop_down_container__list__item"
              key={elem.value}
              onClick={() => changeValue(elem.value)}
            >
              {elem.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(DropDownList);
