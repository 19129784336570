import { createSlice } from '@reduxjs/toolkit';

import {
  GrantItemType,
  LoanItemType,
  LoanFilterSettings,
  GrantFilterSettings,
} from '_types/funding.interface';
import { PartialPlatformLanguagesMap } from '_types/utils.interface';

import {
  fetchGetLoansCards,
  fetchGetGrantCards,
  fetchGetProductTypes,
  fetchLoanFilterSettings,
  fetchGrantFilterSettings,
} from 'store/actions/funding';

interface FundingState {
  loansCount: number;
  loansList: LoanItemType[];
  grantsCount: number;
  grantsList: GrantItemType[];
  isLoading: boolean;
  availableProductTypes: PartialPlatformLanguagesMap<string[]> | null;
  loanFilterDetails: LoanFilterSettings;
  isLoanFilterDetailsLoading: boolean;
  grantFilterDetails: GrantFilterSettings;
  isGrantFilterDetailsLoading: boolean;
  topMatch: {
    loansList: LoanItemType[];
    grantsList: GrantItemType[];
    isLoading: boolean;
  };
}

export const fundingInitialState: FundingState = {
  loansCount: 0,
  loansList: [],
  grantsCount: 0,
  grantsList: [],
  isLoading: true,
  availableProductTypes: null,
  loanFilterDetails: {} as LoanFilterSettings,
  isLoanFilterDetailsLoading: false,
  grantFilterDetails: {} as GrantFilterSettings,
  isGrantFilterDetailsLoading: false,
  topMatch: {
    loansList: [],
    grantsList: [],
    isLoading: false,
  },
};

const fundingSlice = createSlice({
  name: 'funding',
  initialState: fundingInitialState,
  reducers: {
    resetState: () => fundingInitialState,
    resetLoanState: (state) => {
      state.loansCount = 0;
      state.loansList = [];
    },
    resetGrantState: (state) => {
      state.grantsCount = 0;
      state.grantsList = [];
    },
    resetTopMatchLoanState: (state) => {
      state.topMatch.loansList = [];
    },
    resetTopMatchGrantState: (state) => {
      state.topMatch.grantsList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetLoansCards.pending, (state, action) => {
      if (action.meta.arg.fetchType === 'topMatch') {
        state.topMatch.isLoading = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchGetLoansCards.fulfilled, (state, action) => {
      if (action.meta.arg.fetchType === 'topMatch') {
        state.topMatch.isLoading = false;
        state.topMatch.loansList = action.payload.list;
      } else {
        state.isLoading = false;
        state.loansCount = action.payload.count;
        state.loansList = action.payload.list;
      }
    });
    builder.addCase(fetchGetLoansCards.rejected, (state, action) => {
      if (action.meta.arg.fetchType === 'topMatch') {
        state.topMatch.isLoading = true;
      } else {
        state.isLoading = false;
      }
    });
    builder.addCase(fetchGetGrantCards.pending, (state, action) => {
      if (action.meta.arg.fetchType === 'topMatch') {
        state.topMatch.isLoading = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchGetGrantCards.fulfilled, (state, action) => {
      if (action.meta.arg.fetchType === 'topMatch') {
        state.topMatch.isLoading = false;
        state.topMatch.grantsList = action.payload.list;
      } else {
        state.isLoading = false;
        state.grantsCount = action.payload.count;
        state.grantsList = action.payload.list;
      }
    });
    builder.addCase(fetchGetGrantCards.rejected, (state, action) => {
      if (action.meta.arg.fetchType === 'topMatch') {
        state.topMatch.isLoading = false;
      } else {
        state.isLoading = false;
      }
    });
    builder.addCase(fetchGetProductTypes.fulfilled, (state, action) => {
      state.availableProductTypes = action.payload.productTypes;
    });
    builder.addCase(fetchLoanFilterSettings.pending, (state) => {
      state.isLoanFilterDetailsLoading = true;
    });
    builder.addCase(fetchLoanFilterSettings.fulfilled, (state, action) => {
      state.isLoanFilterDetailsLoading = false;
      state.loanFilterDetails = action.payload;
    });
    builder.addCase(fetchLoanFilterSettings.rejected, (state, _action) => {
      state.isLoanFilterDetailsLoading = false;
    });

    builder.addCase(fetchGrantFilterSettings.pending, (state) => {
      state.isGrantFilterDetailsLoading = true;
    });
    builder.addCase(fetchGrantFilterSettings.fulfilled, (state, action) => {
      state.isGrantFilterDetailsLoading = false;
      state.grantFilterDetails = action.payload;
    });
    builder.addCase(fetchGrantFilterSettings.rejected, (state, _action) => {
      state.isGrantFilterDetailsLoading = false;
    });
  },
});

export const {
  resetState,
  resetLoanState,
  resetGrantState,
  resetTopMatchLoanState,
  resetTopMatchGrantState,
} = fundingSlice.actions;

export default fundingSlice;
