import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { actionHandleShowProgramsAndResourcesModal } from 'store/reducers/modal';
import { showProgramsAndResourcesModalSelector } from 'store/selectors/modal';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import SelectableBlock from 'app/components/SelectableBlock';
import Button from 'app/components/Button';
import { deletePartnerResource, fetchResources } from 'store/actions/resources';
import { PartnerResourcesItemType } from '_types/resources.interface';
import { profileAdvisoryOrgInfoSelector } from 'store/selectors/profile';
import { deleteCohortProgram } from 'store/actions/cohort-program';
import { CohortItemType } from '_types/cohort.interface ';
import BaseModal from '../BaseModal';
import OtherResourceModal from './OtherResourceModal';
import CohortModal from './CohortModal';

import { getAvailableResourceModalTypeList } from './utils/helperFuctions';
import DeleteResourceModal from './DeleteResourceModal';
import './style.scss';

const customClassNames = {
  closeIcon: 'program_and_resources_modal__close_icon',
  modalInnerContainer: 'program_and_resources_modal__inner_container',
  modalContent: 'program_and_resources_modal__modal_content',
};

const ProgramAndResourcesModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTenantTranslation();
  const modalData = useAppSelector(showProgramsAndResourcesModalSelector);
  const advisoryOrgInfo = useAppSelector(profileAdvisoryOrgInfoSelector);
  const [step, setStep] = useState<number>(1);
  const [resourceType, setResourceType] = useState<
    'resource' | 'cohort' | undefined
  >(undefined);

  useEffect(() => {
    if (modalData!.mode === 'edit') {
      setStep(2);
    }
  }, [modalData?.mode, setStep]);

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowProgramsAndResourcesModal(null));
  }, [dispatch]);

  const deleteResource = useCallback(async () => {
    let action;
    let response;

    if (modalData?.resourceType === 'resource') {
      action = deletePartnerResource;
      response = await dispatch(
        action(modalData?.data as Partial<PartnerResourcesItemType>),
      );
    } else if (modalData?.resourceType === 'cohort') {
      action = deleteCohortProgram;
      response = await dispatch(
        action(modalData?.data as Partial<CohortItemType>),
      );
    }

    if (action.fulfilled.match(response)) {
      dispatch(fetchResources(advisoryOrgInfo?.id));
      closeModal();
    }
  }, [modalData, dispatch]);

  const availableResourceModalType = useMemo(
    () => getAvailableResourceModalTypeList(t),
    [t],
  );

  const nextStep = useCallback(() => {
    if (resourceType) {
      setStep(2);
      dispatch(
        actionHandleShowProgramsAndResourcesModal({
          mode: 'create',
          resourceType,
        }),
      );
    }
  }, [resourceType]);

  if (modalData?.mode === 'delete') {
    return (
      <DeleteResourceModal
        closeModal={closeModal}
        deleteResource={deleteResource}
      />
    );
  }

  return (
    <BaseModal
      onModalClose={closeModal}
      isCloseIconVisible
      closeOnBackgroundClick
      backgroundScrollDisabled
      classNames={customClassNames}
    >
      <div className="program_and_resources_modal">
        {modalData?.mode === 'create' && step === 1 && (
          <div className="add_resource">
            <div className="add_resource__header">
              <p className="title">
                {t('common.modal.program_and_resources_modal.title')}
              </p>
              <p className="sub_title">
                {t('common.modal.program_and_resources_modal.subtitle')}
              </p>
            </div>
            <div className="add_resource__content">
              <SelectableBlock
                selected={resourceType === 'cohort'}
                onChange={() => setResourceType('cohort')}
                subtitle={availableResourceModalType[0].subtitle}
                value={availableResourceModalType[0].value}
                type="checkbox"
                className="select"
              />
              <SelectableBlock
                selected={resourceType === 'resource'}
                onChange={() => setResourceType('resource')}
                subtitle={availableResourceModalType[1].subtitle}
                value={availableResourceModalType[1].value}
                type="checkbox"
                className="select"
              />
            </div>
            <div className="add_resource__footer">
              <Button
                type="btn"
                btnLook="filled"
                onClick={nextStep}
                size="large"
                disabled={!resourceType}
                value={t(
                  'common.modal.program_and_resources_modal.continue_btn',
                )}
              />
            </div>
          </div>
        )}

        {step !== 1 &&
          modalData?.resourceType &&
          (modalData.resourceType === 'resource' ? (
            <OtherResourceModal />
          ) : modalData.resourceType === 'cohort' ? (
            <CohortModal />
          ) : null)}
      </div>
    </BaseModal>
  );
};

export default memo(ProgramAndResourcesModal);
