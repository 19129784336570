/* eslint-disable no-alert */
/* eslint-disable no-console */
import { phoneConstant } from '_constants/otherConstants';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { actionHandleShowAdvisorReferralDeclineModal } from 'store/reducers/modal';
import { CiCircleQuestion } from 'react-icons/ci';
import { TFunction } from 'i18next';
import ListWithSelectables from 'app/components/ListWithSelectables';
import { CgArrowRightO } from 'react-icons/cg';

import advisorService from '_services/advisorService';
import { AdvisorReferralStatus } from '_types/advisor.interface';
import { showAdvisorReferralDeclineModalSelector } from 'store/selectors/modal';
import { fetchReferredAdvisor } from 'store/actions/advisor';
import { profileInfoSelector } from 'store/selectors/profile';
import BaseModal from '../BaseModal';
import './style.scss';

const reasonToDeclineOption = (t: TFunction) => [
  {
    value: 'Advisor does not have the area of expertise I need',
    name: t('Advisor does not have the area of expertise I need'),
  },
  {
    value: 'I do not have time to meet with this advisor',
    name: t('I do not have time to meet with this advisor'),
  },
  {
    value: 'I do not require their services at this time',
    name: t('I do not require their services at this time'),
  },
  {
    value: 'It is unclear how this advisor will add value to my business',
    name: t('It is unclear how this advisor will add value to my business'),
  },
  {
    value: 'I do not want to pay this advisors fees',
    name: t('I do not want to pay this advisors fees'),
  },

  { value: 'Other, please specify...', name: t('Other') },
];

const AdvisorReferralDeclineModal: React.FC = () => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const referredAdvisorCardData = useAppSelector(
    showAdvisorReferralDeclineModalSelector,
  );
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });
  const profileInfo = useAppSelector(profileInfoSelector);
  const [step, setStep] = useState<number>(1);
  const [value, setValue] = useState<string | null>(null);

  const closeModal = useCallback(
    () => dispatch(actionHandleShowAdvisorReferralDeclineModal(null)),
    [dispatch],
  );
  const availableValue = useMemo(() => reasonToDeclineOption(t), [t]);

  const stepIdicator = useMemo(
    () => (
      <span className="step_counter">
        {step} {t('common.of')} {2}
      </span>
    ),
    [step],
  );

  const declineReferredAdvisor = useCallback(() => {
    const submitDeclineRequest = async () => {
      setStep(2);
      try {
        if (value) {
          await advisorService.declineRefferedAdvisor({
            status: AdvisorReferralStatus.DECLINED,
            reasonToDecline: value,
            id: referredAdvisorCardData?.referralId as number,
          });
          dispatch(actionHandleShowAdvisorReferralDeclineModal(null));
          dispatch(fetchReferredAdvisor(profileInfo?.sboData?.id as number));
        }
      } catch (e) {
        alert('Failed to decline referred advisor');
        console.log('e');
      }
    };
    submitDeclineRequest();
  }, [step, setStep]);

  return (
    <BaseModal
      headerIcon={<CiCircleQuestion size={40} />}
      onModalClose={closeModal}
      isCloseIconVisible
      closeOnBackgroundClick
      withCloseButton
      closeButtonSize={isPhone ? 'medium' : 'large'}
      closeButtonText={t('common.cancel')}
      withAcceptButton
      acceptButtonSize={isPhone ? 'medium' : 'large'}
      acceptButtonText={step === 1 ? t('common.continue') : t('common.decline')}
      acceptButtonRightIcon={<CgArrowRightO size={24} color="white" />}
      onAcceptButtonClick={declineReferredAdvisor}
      disableAcceptButton={!value}
      modalTitle={
        step === 1
          ? t('common.refferedAdvisorDeclineModal.step_1_title')
          : t('common.refferedAdvisorDeclineModal.step_2_title')
      }
      footerChildren={stepIdicator}
      classNames={{
        modal: 'advisor_referral_decline_modal',
        modalTitle: 'advisor_referral_decline_modal__title',
        modalHeader: 'advisor_referral_decline_modal__header',
        modalInnerContainer: 'advisor_referral_decline_modal__inner_container',
        modalContent: 'advisor_referral_decline_modal__modalContent',
        acceptButton: 'advisor_referral_decline_modal__footer__acceptButton',
        closeButton: 'advisor_referral_decline_modal__footer__closeButton',
        closeIcon: 'advisor_referral_decline_modal__closeIcon',
        modalFooter: 'advisor_referral_decline_modal__footer',
      }}
    >
      {step === 1 ? (
        <ListWithSelectables
          type="select"
          availableValuesList={availableValue}
          value={value || ''}
          onChange={(value) => setValue(value)}
        />
      ) : (
        <p className="step2_description">
          {t('common.refferedAdvisorDeclineModal.step_2_description')}
        </p>
      )}
    </BaseModal>
  );
};

export default memo(AdvisorReferralDeclineModal);
