import React, { memo } from 'react';
import moment from 'moment';
import { MdCalendarMonth } from 'react-icons/md';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { formatAs } from 'utils/funding';
import useTenantColors from 'utils/hooks/useTenantColors';
import { useCurrentPlatformLanguage } from 'utils/translations';
import './style.scss';
import { CohortItemType } from '_types/cohort.interface ';
import {
  formatMoneyWithOutSymbol,
  getApplicationStatus,
} from 'utils/cohortProgram';
import DollarIcon from '../../../../../assets/img/partnerResources/dollar.svg';
import DollarCrossIcon from '../../../../../assets/img/partnerResources/dollar_off.svg';

interface CohortCardExpandedViewProps {
  cohortData: CohortItemType;
}

const CohortCardExpandedView: React.FC<CohortCardExpandedViewProps> = ({
  cohortData,
}) => {
  const { t } = useTenantTranslation();
  const { colorVariables } = useTenantColors();
  const currentLanguage = useCurrentPlatformLanguage();

  return (
    <div className="cohort_modal_container">
      <div className="card">
        <div className="deadline">
          <MdCalendarMonth
            size={24}
            color={colorVariables?.icons.icon_secondary_tetriary_button}
          />
          <span>
            {
              getApplicationStatus(
                cohortData.applicationStart,
                cohortData.applicationEnd,
              ).value
            }
          </span>
        </div>

        <span className="fee">
          {cohortData?.fee > 0 ? (
            <>
              {t('common.modal.cohort_program.fee')}:{' '}
              <img src={DollarIcon} className="dollar_icon" alt="dollar icon" />{' '}
              {formatMoneyWithOutSymbol(cohortData?.fee, false)}
            </>
          ) : (
            <>
              <img
                src={DollarCrossIcon}
                className="no_dollar_icon"
                alt="no dollar icon"
              />
              {t('common.modal.cohort_program.no_fee')}
            </>
          )}
        </span>

        <div className="card_body">
          <div className="name">
            <p>
              <span>{t('cohort_card.program_lead')}: </span>
              {cohortData?.programLead || t('common.none')}
            </p>
          </div>
          <div className="desription">
            <p>
              <span>{t('cohort_card.program_description')}: </span>
              {cohortData?.description || t('common.none')}
            </p>
          </div>
          <div className="card_item">
            <p>{t('common.modal.cohort_program.application_deadline')}</p>
            <p>
              {cohortData?.applicationEnd
                ? moment(cohortData?.applicationEnd).format('MMM D, YYYY')
                : t('common.rolling')}
            </p>
          </div>
          <div className="card_item">
            <p>{t('common.modal.cohort_program.program_duration')}</p>
            <p>
              {cohortData.programDuration
                ? cohortData.programDuration
                : t('common.none')}
            </p>
          </div>
          <div className="card_item">
            <p>{t('common.modal.cohort_program.program_start_date')}</p>
            <p>
              {cohortData?.programStart
                ? moment(cohortData?.programStart).format('MMM D, YYYY')
                : t('common.rolling')}
            </p>
          </div>
          <div className="card_item">
            <p>{t('common.modal.cohort_program.program_format')}</p>
            <p>
              {cohortData?.programFormat
                ? cohortData?.programFormat
                : t('common.none')}
            </p>
          </div>
          <div className="card_item">
            <p>{t('common.modal.cohort_program.eligibility_criteria')}</p>
          </div>
          <div className="card_item">
            <p>{t('common.modal.cohort_program.minimum_years_in_business')}</p>
            <p>
              {cohortData?.minYearsInBusiness &&
              cohortData?.minYearsInBusiness !== 0
                ? t('common.modal.cohort_program.n_years', {
                    count: cohortData?.minYearsInBusiness,
                  })
                : t('common.none')}
            </p>
          </div>
          <div className="card_item">
            <p>{t('common.modal.cohort_program.located_in')}</p>
            <p> {cohortData.geography || t('common.none')}</p>
          </div>
          <div className="card_item">
            <p>{t('common.modal.cohort_program.annual_revenue')}</p>
            <p>
              {cohortData?.annualRevenue
                ? formatAs(cohortData?.annualRevenue, 'money')
                : t('common.none')}
            </p>
          </div>
          <div className="card_item">
            <p>{t('common.modal.cohort_program.industries')}</p>
            <p>
              {cohortData?.industries !== null &&
              cohortData?.industries?.length !== 0
                ? cohortData?.industries?.join(', ')
                : t('common.none')}
            </p>
          </div>
          <div className="card_item">
            <p>{t('common.modal.cohort_program.additional_criteria')}</p>
            {cohortData?.additionalCriteria &&
            Object.keys(cohortData?.additionalCriteria).length !== 0 ? (
              <ul>
                {Object.entries(cohortData?.additionalCriteria).map(
                  ([key, value]) => (
                    <li>
                      <strong>{key}: </strong>
                      {value}
                    </li>
                  ),
                )}
              </ul>
            ) : (
              <span>
                <p>{t('common.none')}</p>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CohortCardExpandedView);
