import { useEffect } from 'react';

import { useAppSelector } from 'store/configureStore';

import { profileIdSelector } from 'store/selectors/profile';
import { tenantSettingsTenantModulesSelector } from 'store/selectors/tenant-settings';

export const useGATagAddUserId = (
  currentAccessToken: string | null,
  isProfileInfoPresent: boolean,
) => {
  const profileId = useAppSelector(profileIdSelector);
  const tenantModules = useAppSelector(tenantSettingsTenantModulesSelector);

  useEffect(() => {
    if (!tenantModules?.users || !isProfileInfoPresent || !currentAccessToken) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: profileId,
      event: 'userIdSet',
    });
  }, [
    isProfileInfoPresent,
    currentAccessToken,
    tenantModules?.users,
    profileId,
  ]);
};
