import React, { memo, useCallback, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import isEmpty from 'lodash/isEmpty';
import { CgArrowRightO } from 'react-icons/cg';

import Button from 'app/components/Button';
import CodeInput from 'app/components/InputComponents/CodeInput';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantStylingSelector } from 'store/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import {
  MFACodeUsedInFormEnum,
  processCodeVerificationRequestError,
} from './utils';

import './style.scss';

const verificationCodeValidationSchema = object({
  verificationCode: string()
    .matches(/^\d{6}$/)
    .required(),
});

interface MFACodeEnterFormProps {
  mfaCodeUsedInFormType: MFACodeUsedInFormEnum;
  phoneNumber: string;
  goToPreviousPageStage: () => void;
  onFormSubmit: (verificationCode: string) => Promise<void>;
  onCodeResend: () => Promise<void>;
}

const MFACodeEnterForm: React.FC<MFACodeEnterFormProps> = ({
  mfaCodeUsedInFormType,
  phoneNumber,
  goToPreviousPageStage,
  onFormSubmit,
  onCodeResend,
}) => {
  const { t } = useTenantTranslation();
  const tenantStyle = useAppSelector(tenantSettingsTenantStylingSelector);

  const [isVerifyingCode, setIsVerifyingCode] = useState<boolean>(false);
  const [isResendingCode, setIsResendingCode] = useState<boolean>(false);
  const [verificationCodeError, setVerificationCodeError] = useState<
    string | null
  >(null);
  const [seconds, setSeconds] = useState(45);
  const [isResendButtonDisabled, setIsResendButtonDisabled] =
    useState<boolean>(false);

  useEffect(() => {
    setIsResendButtonDisabled(true);
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
    setIsResendButtonDisabled(false);
    return () => seconds;
  }, [seconds, setIsResendButtonDisabled]);

  const onResendCode = useCallback(async () => {
    setIsResendingCode(true);
    setIsResendButtonDisabled(true);
    setSeconds(45);

    setVerificationCodeError(null);

    await onCodeResend();

    setIsResendingCode(false);
  }, [
    setIsResendingCode,
    onCodeResend,
    setVerificationCodeError,
    setIsResendButtonDisabled,
  ]);

  const onVerificationCodeSubmit = useCallback(
    async ({ verificationCode }: { verificationCode: string }) => {
      setIsVerifyingCode(true);
      setVerificationCodeError(null);

      try {
        await onFormSubmit(verificationCode);
      } catch (e: any) {
        const errorMessage = processCodeVerificationRequestError(
          e,
          t,
          mfaCodeUsedInFormType,
        );
        setVerificationCodeError(errorMessage);
        // eslint-disable-next-line no-console
        console.error(e);
      }

      setIsVerifyingCode(false);
    },
    [
      t,
      mfaCodeUsedInFormType,
      onFormSubmit,
      setIsVerifyingCode,
      setVerificationCodeError,
    ],
  );

  const isLoadingAnything = isVerifyingCode || isResendingCode;

  return (
    <Formik
      initialValues={{ verificationCode: '' }}
      validationSchema={verificationCodeValidationSchema}
      onSubmit={onVerificationCodeSubmit}
      validateOnMount
    >
      {({ errors, setFieldValue }) => (
        <Form className="code_verification_form">
          <div className="code_verification_form__text">
            <p className="code_verification_form__text__title">
              {t('auth_module.mfa_verification_code_title')}
            </p>
            <p className="code_verification_form__text__subtitle">
              {t('auth_module.mfa_verification_code_text', { phoneNumber })}
            </p>
          </div>

          <div className="code_verification_form__input">
            <CodeInput
              name="verificationCode"
              cellsNumber={6}
              onChange={(value: string) =>
                setFieldValue('verificationCode', value)
              }
              errorText={verificationCodeError}
              hasError={!!verificationCodeError}
            />
          </div>

          <Button
            type="btn"
            btnType="submit"
            rightIcon={
              <CgArrowRightO
                size={20}
                color={tenantStyle?.colorVariables.icons.icon_primary1_button}
              />
            }
            btnLook="filled"
            value={t('common.continue')}
            size="large"
            isLoading={isVerifyingCode}
            disabled={isLoadingAnything || !isEmpty(errors)}
          />
          {isResendButtonDisabled ? (
            <p className="code_verification_form__resend_code_btn">
              {t('auth_module.resend_mfa_in_n_second', {
                seconds,
              })}
            </p>
          ) : (
            <Button
              type="link"
              className="code_verification_form__resend_code_btn"
              btnType="button"
              onClick={onResendCode}
              btnLook="text-underline"
              value={t('auth_module.mfa_code_resend_btn_text')}
              size="medium"
              isLoading={isResendingCode}
              disabled={isLoadingAnything}
            />
          )}

          <Button
            type="link"
            btnLook="text-underline"
            btnType="button"
            className="code_verification_form__go_back_btn"
            size="medium"
            onClick={goToPreviousPageStage}
            value={t('common.go_back')}
            disabled={isLoadingAnything}
          />
        </Form>
      )}
    </Formik>
  );
};

export default memo(MFACodeEnterForm);
