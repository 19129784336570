/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */

import React, { ReactNode } from 'react';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TooltipPredefinedDescriptionKeys } from './types';

export const getWordDefinitionTooltipDescriptionByPredefinedKey = (
  key: TooltipPredefinedDescriptionKeys,
  t: TFunction,
): ReactNode => {
  switch (key) {
    case TooltipPredefinedDescriptionKeys.APR:
      return (
        <Trans
          components={[
            <Link
              key="0"
              className="word_definition_tooltip__word_link"
              to="/glossary#apr"
              target="_blank"
              rel="noreferrer"
            />,
          ]}
        >
          {t('funding_module.loan_card_tooltips.apr')}
        </Trans>
      );
    case TooltipPredefinedDescriptionKeys.DEFERMENT_PERIOD:
      return (
        <Trans
          components={[
            <Link
              key="0"
              className="word_definition_tooltip__word_link"
              to="/glossary#deferment-period"
              target="_blank"
              rel="noreferrer"
            />,
          ]}
        >
          {t('funding_module.loan_card_tooltips.deferment_period')}
        </Trans>
      );
    case TooltipPredefinedDescriptionKeys.BASIC_REQUIREMENTS:
      return (
        <ul className="word_definition_tooltip__list">
          <li className="word_definition_tooltip__list__item">
            <Trans
              components={[
                <Link
                  key="0"
                  className="word_definition_tooltip__word_link"
                  to="/glossary#collateral"
                  target="_blank"
                  rel="noreferrer"
                />,
              ]}
            >
              {t(
                'funding_module.loan_card_tooltips.basic_requirements.collateral',
              )}
            </Trans>
          </li>

          <li className="word_definition_tooltip__list__item">
            <Trans
              components={[
                <Link
                  key="0"
                  className="word_definition_tooltip__word_link"
                  to="/glossary#guarantor"
                  target="_blank"
                  rel="noreferrer"
                />,
              ]}
            >
              {t(
                'funding_module.loan_card_tooltips.basic_requirements.guarantor',
              )}
            </Trans>
          </li>

          <li className="word_definition_tooltip__list__item">
            <Trans
              components={[
                <Link
                  key="0"
                  className="word_definition_tooltip__word_link"
                  to="/glossary#capital-injection"
                  target="_blank"
                  rel="noreferrer"
                />,
              ]}
            >
              {t(
                'funding_module.loan_card_tooltips.basic_requirements.capital_injection',
              )}
            </Trans>
          </li>
        </ul>
      );
    default:
      return null;
  }
};
