import type { IndustryFieldConfiguration } from './types';

export const doesIndustryHaveSubIndustries = (
  industryFieldConfiguration?: IndustryFieldConfiguration | null,
  industry?: string | null,
): boolean => {
  const industryOption = industryFieldConfiguration?.options?.find(
    (option) => option.value === industry,
  );

  return !!industryOption?.subIndustryOptions?.length;
};
