import type { ProfileFieldData } from '_constants/profile-fields/fields';

import type { TranslateValueType } from '_types';

import type { OnboardingFooterProps } from 'app/components/Footers/OnboardingFooter/types';

import type { ReactNode } from 'react';
import type { TFunction } from 'i18next';
import type {
  CalendlyIntegrationPolicyEnum,
  ProfileCalendlyLinks,
} from './calendly.interface';
import type { SboType } from './sbo.interface';
import type {
  AdvisorInvitation,
  ProfileInfoType,
  SocialLinksType,
} from './profile.interface';

export interface OnboardingProgress {
  onboardingStep: number;
  isOnboardingCompleted: boolean;
}

export interface UpdateOnboardingProgress extends Partial<OnboardingProgress> {
  onboardingStep: number;
}

export interface OnboardingAdvisorInvitation
  extends Pick<AdvisorInvitation, 'email' | 'firstName' | 'lastName'> {}

export interface OnboardingAdvisoryOrgInfo extends SocialLinksType {
  adminOfExistingAdvisoryOrgId: number | null;
  name: string | null;
  overview: string | null;
  advisorInvitations: OnboardingAdvisorInvitation[];
  website: string | null;
  sboCommunities: string[];
  geography: string;
  zipCodes: string[];
  calendlyToken: string | null;
  calendlyIntegrationPolicy: CalendlyIntegrationPolicyEnum;
  calendlyOrgId: string | null;
  logo: string | null;
  state: string[] | null;
}

export interface OnboardingAdvisorInfo extends SocialLinksType {
  advisoryOrgId: number | null;
  photo: string | null;
  video: string | null;
  languages: string[];
  bio: string | null;
  topics: string[];
  affiliations: string[];
  website: string | null;
  advisorCalendlyLinks: ProfileCalendlyLinks;
  industries: string[];
  subIndustries: string[];
  servingContractingNeeds: string[];
  servingContractorTypes: string[];
  phoneNumber: string | null;
  sboType: string[] | null;
}

export interface OnboardingSBOInfo {
  photo: string | null;
  phoneNumber: string | null;
  mailingAddress: string | null;
  sboType: SboType | null;
  topics: string[];
  contractingNeeds: string[];
  fundingType: string[];
  fundingPurpose: string[];
  borrowingSize: number | null;
  needFinancingBy: string | null;
  advisoryOrganizations: string[];
  platformAwarenessSource: string[];
  gender: string | null;
  ethnicity: string[];
  languages: string[];
  businessName: string | null;
  businessStructure: string | null;
  industry: string | null;
  subIndustry: string | null;
  zipCode: string | null;
  openingDate: Date | null;
  previousYearRevenue: number | null;
  ein: string | null;
  allowEmptyEin?: boolean;
  businessStreet: string | null;
  businessCity: string | null;
  businessState: string | null;
  fullTimeEmployees: number | null;
  partTimeEmployees: number | null;
  tempFullTimeEmployees: number | null;
  tempPartTimeEmployees: number | null;
  roleInBusiness: string | null;
  isMinorityOwned: boolean;
  isWomenOwned: boolean;
  isVeteranOwned: boolean;
  isDisabledVeteranOwned: boolean;
  isImmigrantOwned: boolean;
  isWorkerOwned: boolean;
  isOverHalfOwnedByWomen: string | null;
  isOverHalfOwnedByMinority: string | null;
  diversityCertifications: string[];
  jobTitle: string | null;
  NYCBorough?: string | null;
  targetIndustries: string[];
  customerTypes: string[];
  businessSupplierContractingStatus: string[];
  ownedCollateral: string[];
  personalCreditScore: string | null;
  hadLoanBefore: boolean | null;
  hadPriorBankruptcy: string | null;
  didEarnProfitLastYear: boolean | null;
  annualNetProfitLastYear: number | null;
  expectedRevenueThisYear: string | null;
  hasBusinessPlan: boolean | null;
  servedGeographies: string[] | null;
  numberOfBusinessContracts: number | null;
  totalValueOfBusinessContracts: number | null;
  newIndustriesUnderConsideration: string[] | null;
  newCustomerTypesUnderConsideration: string[] | null;
  newMarketsUnderConsideration: string[] | null;
  participatedInTrainingCohortProgram: boolean | null;
  trainingCohortProgramsTaken: string[] | null;
}

export interface OnboardingDataAdditionalInfo {
  orgCalendlyIntegrationPolicy?: CalendlyIntegrationPolicyEnum;
}

export interface OnboardingData {
  userId: number;
  tenantId: number;
  advisoryOrgData: OnboardingAdvisoryOrgInfo | null;
  advisorData: OnboardingAdvisorInfo | null;
  sboData: OnboardingSBOInfo | null;
  additionalInfo: OnboardingDataAdditionalInfo | null;
}

export type UpdateOnboardingData = {
  advisoryOrgData?: Partial<OnboardingAdvisoryOrgInfo> | null;
  advisorData?: Partial<OnboardingAdvisorInfo> | null;
  sboData?: Partial<OnboardingSBOInfo> | null;
};

export type CheckShouldShowOnboardingPageFunctionType = (
  onboardingData: OnboardingData,
  profileInfo: ProfileInfoType,
) => boolean;

export type GetOnboardingPageTitleReactNodeFn = (t: TFunction) => ReactNode;

export type GetOnboardingPageSubtitleReactNodeFn = (t: TFunction) => ReactNode;

export type OnboardingPrebuiltPageComponentProps = OnboardingFooterProps & {
  pageData: OnboardingPage;
};

export type OnboardingPrebuiltPageComponent =
  React.FC<OnboardingPrebuiltPageComponentProps>;

export type OnboardingPrebuiltFooterComponent = React.FC<OnboardingFooterProps>;

export interface OnboardingPageData {
  pageKey: string;
  iconKey?: string;
  titleTranslationKey?: string;
  titleReactNodeGetterKey?: string;
  subTitleTranslationKey?: string;
  subTitleReactNodeGetterKey?: string;
  userDataPutRequestRequired: boolean; // default = false
  onboardingDataPutRequestRequired: boolean; // default = true
  shouldShowPageGetterKey?: string;
  percent: number;
  fieldsList: string[];
  prebuiltPageComponentKey?: string;
  prebuiltFooterComponentKey?: string;
}

export interface OnboardingPage {
  key: string;
  icon: ReactNode | null;
  titleTranslationKey: string | null;
  getTitleReactNode: GetOnboardingPageTitleReactNodeFn | null;
  subTitleTranslationKey: string | null;
  getSubTitleReactNode: GetOnboardingPageSubtitleReactNodeFn | null;
  userDataPutRequestRequired: boolean;
  onboardingDataPutRequestRequired: boolean;
  getShouldShowPage: CheckShouldShowOnboardingPageFunctionType | null;
  percent: number;
  fields: ProfileFieldData[];
  prebuiltPageComponent: OnboardingPrebuiltPageComponent | null;
  prebuiltFooterComponent: OnboardingPrebuiltFooterComponent | null;
}

export interface FundingListForm {
  title: string;
  text: string;
  type: 'checkbox' | 'select';
  list: TranslateValueType[];
  value: string;
}

export interface LanguageType {
  name: string;
  lang: string;
}

export interface SocialLinksObjectType {
  name: string;
  value: keyof SocialLinksType;
  icon: JSX.Element;
  iconColor: JSX.Element;
}

export type TitleListInfo =
  | {
      type: 'p' | 'span';
      value: string;
      class?: string;
    }
  | {
      type: 'br';
      class?: string;
    };
export interface StepListInfo {
  title: TitleListInfo[];
  text?: string;
  icon: JSX.Element;
  list: TranslateValueType[];
  value: string;
  search: boolean;
  searchPlaceholder?: string;
}

export interface MeetingRoomListType {
  name: string;
  icon: JSX.Element;
  value: string;
}

export interface CalendarConnectionListType {
  name: string;
  icon: JSX.Element;
  value: string;
}

enum OnboardingQuestionTypeEnum {
  TEXT_INPUT = 'text_input',
  INTEGER_INPUT = 'integer_input',
  DECIMAL_INPUT = 'decimal_input',
  TEL_INPUT = 'tel_input',
  ZIPCODE_INPUT = 'zipcode_input',
  DROPDOWN = 'dropdown',
  SELECT_BOX = 'select_box',
}

enum ValueTypeEnum {
  STRING = 'string',
  BOOLEAN = 'boolean',
  INTEGER = 'integer',
  DECIMAL = 'decimal',
}

enum ComparisonTypeEnum {
  EQUAL = 'equal',
  NOT_EQUAL = 'not-equal',
  GREATER_THAN = 'greaterThan',
  LESS_THAN = 'lessThan',
  GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
  LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
}

export enum GeographyServeEnum {
  NATIONAL = 'National',
  STATE = 'State',
  ZIPCODESPECIFIC = 'Zip code-specific',
}

interface IfConditionType {
  conditionFieldName: string;
  required: boolean;
  conditionFieldType: ValueTypeEnum;
  condition?: ComparisonTypeEnum;
  conditionValue?: string | number | boolean | null;
}

interface ValidationPropertiesType {
  type: ValueTypeEnum;
  required: boolean;
  nullable?: boolean;
  minimumValue?: number;
  maximumValue?: number;
  isArray?: boolean;
  minItem?: number;
  maxItem?: number;
  pattern?: string;
  if?: IfConditionType[];
}

interface ValidationType {
  name: string;
  properties: ValidationPropertiesType;
}

export interface OnboardingQuestionsOptionsType {
  name: string;
  description?: string;
  value: string;
  icon?: string;
  hint?: string;
}

export interface OnboardingQuestionConfiguration {
  id: number;

  tenantId: number;

  questionType: OnboardingQuestionTypeEnum;

  title: string;

  description: string;

  options: OnboardingQuestionsOptionsType[];

  validation: ValidationType;

  isMultiSelect: boolean;

  componentName: string;
}
