import React, { memo } from 'react';

interface OpenInNewTabIconProps {
  className?: string;
  fillColor?: string;
}
const OpenInNewTabIcon: React.FC<OpenInNewTabIconProps> = ({
  className,
  fillColor,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M19 4.5H5C3.89 4.5 3 5.4 3 6.5V18.5C3 19.6 3.89 20.5 5 20.5H9V18.5H5V8.5H19V18.5H15V20.5H19C20.1 20.5 21 19.6 21 18.5V6.5C21 5.4 20.11 4.5 19 4.5ZM12 10.5L8 14.5H11V20.5H13V14.5H16L12 10.5Z"
      fill={fillColor ?? '#053B66'}
    />
  </svg>
);

export default memo(OpenInNewTabIcon);
