import React, { memo, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import Loader from 'app/components/Loader';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { fetchGetProfileInfo } from 'store/actions/profile';
import { isProfileInfoPresentSelector } from 'store/selectors/profile';
import { onUserDataClean } from 'store/reducers/root';
import { fetchGetOnboardingProgress } from 'store/actions/onboarding';
import { isOnboardingProgressPresentSelector } from 'store/selectors/onboarding';
import { tenantSettingsTenantModulesSelector } from 'store/selectors/tenant-settings';

import useManageSocketConnection from 'utils/contexts/SocketContext/useManageSocketConnection';
import FirebaseAuthDataContextProvider from 'utils/contexts/FirebaseAuthDataContext/FirebaseAuthDataContextProvider';
import { useCurrentAccessToken } from 'utils/firebase/hooks';
import { useGATagAddUserId } from 'utils/hooks/useGATagAddUserId';
import { auth } from 'utils/firebase/FirebaseRemoteConfigInitialization';
import ProfileFieldIdsContextProvider from 'utils/contexts/ProfileFieldIdsContext/ProfileFieldIdsContextProvider';
import { BookingContextProvider } from 'utils/contexts/BookingContext/BookingContextProvider';

const UserDataLoaderComponent: React.FC = memo(() => {
  const dispatch = useAppDispatch();

  const isProfileInfoPresent = useAppSelector(isProfileInfoPresentSelector);
  const isOnboardingProgressPresent = useAppSelector(
    isOnboardingProgressPresentSelector,
  );
  const tenantModules = useAppSelector(tenantSettingsTenantModulesSelector);

  const accessToken = useCurrentAccessToken();

  useManageSocketConnection(accessToken);

  useGATagAddUserId(accessToken, isProfileInfoPresent);

  useEffect(() => {
    (async () => {
      try {
        if (accessToken && !tenantModules?.users) {
          await auth.signOut();
        } else if (accessToken && !isProfileInfoPresent) {
          await dispatch(fetchGetProfileInfo()).unwrap();
          await dispatch(fetchGetOnboardingProgress()).unwrap();
        } else if (!accessToken && isProfileInfoPresent) {
          onUserDataClean(dispatch);
        }
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert(
          'An error occurred while loading user data. Please try reloading page or contact support',
        );
        // eslint-disable-next-line no-console
        console.error(e);
      }
    })();
  }, [accessToken, isProfileInfoPresent, !tenantModules?.users]);

  const isRequiredUserDataLoaded =
    isProfileInfoPresent && isOnboardingProgressPresent;

  if (accessToken && !isRequiredUserDataLoaded) {
    return <Loader />;
  }

  return <Outlet />;
});

const UserDataLoaderWrapper: React.FC = memo(() => (
  <FirebaseAuthDataContextProvider>
    <ProfileFieldIdsContextProvider>
      <BookingContextProvider>
        <UserDataLoaderComponent />
      </BookingContextProvider>
    </ProfileFieldIdsContextProvider>
  </FirebaseAuthDataContextProvider>
));

export default UserDataLoaderWrapper;
