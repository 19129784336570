import { createSlice } from '@reduxjs/toolkit';
import {
  CohortItemType,
  CohortProgramPayloadType,
  PaginatedCohortProgramResponseType,
} from '_types/cohort.interface ';
import {
  fetchGetCohortProgramCards,
  fetchGetMatchedCohortProgramCards,
} from 'store/actions/cohort-program';

interface CohortProgram {
  cohortList: CohortItemType[];
  isLoading: boolean;
  isMatchedCohortLoading: boolean;
  matchedCohortList: PaginatedCohortProgramResponseType;
}

const cohortProgramInitialState: CohortProgram = {
  cohortList: [],
  isLoading: false,
  isMatchedCohortLoading: false,
  matchedCohortList: {
    list: [],
    count: 1,
    limit: 4,
    offset: 0,
  },
};

const cohortProgramSlice = createSlice({
  name: 'cohort-program',
  initialState: cohortProgramInitialState,
  reducers: {
    resetState: () => cohortProgramInitialState,
    resetCohortState: (state) => {
      state.cohortList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetCohortProgramCards.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGetCohortProgramCards.fulfilled, (state, action) => {
      state.cohortList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchGetCohortProgramCards.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchGetMatchedCohortProgramCards.pending, (state) => {
      state.isMatchedCohortLoading = true;
    });
    builder.addCase(
      fetchGetMatchedCohortProgramCards.fulfilled,
      (state, action) => {
        state.matchedCohortList.list = action.payload.list;
        state.isMatchedCohortLoading = false;
      },
    );
    builder.addCase(fetchGetMatchedCohortProgramCards.rejected, (state) => {
      state.isMatchedCohortLoading = false;
    });
  },
});

export default cohortProgramSlice;
