import React, { useMemo, useState } from 'react';
import Button from 'app/components/Button';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import {
  allowedPhotoExtensions,
  photoSizeMbMax,
} from '_constants/otherConstants';
import useTenantColors from 'utils/hooks/useTenantColors';
import AvatarCircle from 'app/components/AvatarCircle/AvatarCircle';
import './style.scss';
import { fileToBase64 } from '../helperFuctions';

interface UploadLogoProps {
  logo: string | undefined | null;
  firstName: string | undefined | null;
  onChange: (newValue: string | null) => void;
}

const UploadLogo: React.FC<UploadLogoProps> = (props) => {
  const { t } = useTenantTranslation();
  const { colorVariables } = useTenantColors();

  const [file, setFile] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<string>('');

  const avatarCircleSize = useMemo(() => ({ small: '48', large: '72' }), []);

  const handleChange = async (event: Event) => {
    const file = (event.target as HTMLInputElement).files![0];

    if (!file) {
      return;
    }

    setValidationError('');

    if (!allowedPhotoExtensions.includes(file.type)) {
      setValidationError(t('validation.bad_file_format'));
      return;
    }

    const fileSizeMb = file.size / 1024 / 1024;

    if (fileSizeMb > photoSizeMbMax) {
      setValidationError(
        t('validation.file_too_large', { maxSizeMB: photoSizeMbMax }),
      );
    }

    const fileContent = await fileToBase64(file);

    setFile(fileContent);
    props.onChange(fileContent);
  };

  const onDelete = () => {
    setValidationError('');
    setFile(null);
    props.onChange(null);
  };

  return (
    <div className="image_upload_field">
      <AvatarCircle
        className="upload_avatar__img"
        firstName={props.firstName || ''}
        src={file || props.logo}
        size={avatarCircleSize}
        color={colorVariables?.background.background_avatar_with_letters}
      />
      {!file && !props.logo && (
        <Button
          type="file"
          btnLook="filled-gray"
          value={t('Upload a logo')}
          size="medium"
          onClick={handleChange}
          id="img"
          accept=".jpg, .jpeg, .png"
        />
      )}

      {validationError ? (
        <p className="image_upload_field__validation_error">
          {validationError}
        </p>
      ) : (
        <p className="image_upload_field__max_size">
          {t('common.max_file_size', { maxSizeMB: photoSizeMbMax })}
        </p>
      )}

      {(file || props.logo) && (
        <div className="image_upload_field__clear" onClick={onDelete}>
          {t('common.remove_show_initials')}
        </div>
      )}
    </div>
  );
};

export default UploadLogo;
