import React, { memo } from 'react';

import LanguageSwitch from 'app/components/LanguageSwitch';

import { useAppSelector } from 'store/configureStore';

import { isProfileInfoPresentSelector } from 'store/selectors/profile';
import { tenantSettingsTenantModulesSelector } from 'store/selectors/tenant-settings';

import GeneralHeaderAuthProfilePart from './GeneralHeaderAuthProfilePart';
import GeneralHeaderUnauthProfilePart from './GeneralHeaderUnauthProfilePart';

const HeaderProfile: React.FC = () => {
  const isProfileInfoPresent = useAppSelector(isProfileInfoPresentSelector);
  const tenantModules = useAppSelector(tenantSettingsTenantModulesSelector);

  return (
    <div className="profile">
      {tenantModules?.languageSwitch && <LanguageSwitch />}

      {isProfileInfoPresent && tenantModules?.users ? (
        <GeneralHeaderAuthProfilePart />
      ) : (
        <GeneralHeaderUnauthProfilePart />
      )}
    </div>
  );
};

export default memo(HeaderProfile);
