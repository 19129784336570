import { ColorVariablesType } from '_types/tenant-settings/tenant-styling.interface';

const getPaymentCalculatorCustomStyles = (
  isPaymentMethodDropdownOpen: boolean,
  colorVariables: ColorVariablesType | undefined,
) => {
  const clearBtnStyle = {
    backgroundColor: `$ ${colorVariables?.buttons.button_tetriary_active}`,
    color: `${colorVariables?.text.text_buttons_secondary}`,
    border: `1px solid ${colorVariables?.borders.border_tetriary_button}`,
  };

  const submitBtnStyle = {
    backgroundColor: `${colorVariables?.buttons.button_primary1_active}`,
    color: `${colorVariables?.text.text_buttons_primary}`,
    border: 'none',
  };

  const borderColorForPaymentMethodDropdown = isPaymentMethodDropdownOpen
    ? colorVariables?.borders?.border_field_hover
    : colorVariables?.borders?.border_search_field;

  const dropdownContainerStyle = {
    border: `1px solid ${borderColorForPaymentMethodDropdown}`,
  };

  return { clearBtnStyle, submitBtnStyle, dropdownContainerStyle };
};

export default getPaymentCalculatorCustomStyles;
