import React, { memo, useMemo, useRef } from 'react';

import { statesOnboardingListFn } from '_constants/onboardingConstants';

import { ProfileFieldErrorType } from '_types/profile.interface';

import ProfileFieldWrapper from 'app/components/ProfileFields/common/ProfileFieldWrapper';
import DropDownMultiSelectList from 'app/components/DropDownMultiSelectList/DropDownMultiSelectList';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useGoToFirstErrorId from 'utils/contexts/ProfileFieldIdsContext/useGoToFirstErrorId';

interface AdvisoryOrgStateSelectComponentProps {
  onChange: (value: string[]) => void;
  errors?: ProfileFieldErrorType;
  value?: string[];
}

const AdvisoryOrgStateSelectComponent: React.FC<
  AdvisoryOrgStateSelectComponentProps
> = ({ onChange, errors, value }) => {
  const fieldId = useGoToFirstErrorId(['advisoryOrgData.state']);
  const { t } = useTenantTranslation();
  const availableStatesList = useMemo(() => statesOnboardingListFn(t), [t]);

  return (
    <ProfileFieldWrapper
      title={t('onboarding.geography_serve.state_title')}
      id={fieldId}
    >
      <DropDownMultiSelectList
        placeholder={`${t('onboarding.geography_serve.state_placeholder')}`}
        value={value || []}
        onChange={onChange}
        availableValuesList={availableStatesList}
        errors={errors}
        isArrow
      />
    </ProfileFieldWrapper>
  );
};

export default memo(AdvisoryOrgStateSelectComponent);
