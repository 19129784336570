/* eslint-disable implicit-arrow-linebreak */
import { RootState } from 'store/configureStore';

export const showResetPasswordModalSelector = (state: RootState) =>
  state.modals.showResetPasswordModal;
export const showLoginModalSelector = (state: RootState) =>
  state.modals.showLoginModal;
export const showResetEmailModalSelector = (state: RootState) =>
  state.modals.showResetEmailModal;
export const showRateSessionModalSelector = (state: RootState) =>
  state.modals.showRateSessionModal;
export const showInfoModalSelector = (state: RootState) =>
  state.modals.showInfoModal;
export const showGeneralModalSelector = (state: RootState) =>
  state.modals.showGeneralModal;
export const showFundingModalSelector = (state: RootState) =>
  state.modals.showNonDigitalFundingInfoModal;
export const showProfileMissingDataModalSelector = (state: RootState) =>
  state.modals.showProfileMissingDataModal;
export const showProfileMissingDataSuccessModalSelector = (state: RootState) =>
  state.modals.showProfileMissingDataSuccessModal;
export const showExternalUrlConsentModalSelector = (state: RootState) =>
  state.modals.showExternalUrlConsentModal;
export const showHasLicenseModalSelector = (state: RootState) =>
  state.modals.showHasLicenseModal;
export const showFundingExpandedViewModalSelector = (state: RootState) =>
  state.modals.showFundingCardExpandedViewModal;
export const showCohortExpandedViewModalSelector = (state: RootState) =>
  state.modals.showCohortCardExpandedViewModal;
export const showMenuModalSelector = (state: RootState) =>
  state.modals.showMenuModal;
export const showCalculatorModalSelector = (state: RootState) =>
  state.modals.showCalculatorModal;
export const showAccountDeletionModalSelector = (state: RootState) =>
  state.modals.showAccountDeletionModal;
export const showCalendlyLinksModalSelector = (state: RootState) =>
  state.modals.showCalendlyLinksModal;
export const hasCalendlyLinksModalBeenShownSelector = (state: RootState) =>
  state.modals.hasCalendlyLinksModalBeenShown;
export const showAdvisorReferralModalSelector = (state: RootState) =>
  state.modals.showAdvisorReferralModal;
export const showAdvisorReferralDeclineModalSelector = (state: RootState) =>
  state.modals.showAdvisorReferralDeclineModal;
export const showProgramsAndResourcesModalSelector = (state: RootState) =>
  state.modals.showProgramsAndResourcesModal;
