import moment from 'moment';

interface ApplicationStatusValue {
  value: string;
  status: boolean;
}

export const getApplicationStatus = (
  startDate: string | undefined,
  endDate: string | undefined,
): ApplicationStatusValue => {
  const currentDate = moment();

  const startMoment = moment(startDate);
  const endMoment = endDate ? moment(endDate) : null;

  if (
    (startDate == null && endDate == null) ||
    (currentDate.isSameOrAfter(startMoment) && endDate == null)
  ) {
    return { value: 'Rolling Applications', status: false };
  }

  if (startDate == null && endDate) {
    return {
      value: `Deadline to Apply: ${endMoment ? endMoment.format('MMM D, YYYY') : 'No Deadline'}`,
      status: false,
    };
  }

  if (currentDate.isBefore(startMoment)) {
    return {
      value: `Apply Starting: ${startMoment.format('MMM D, YYYY')}`,
      status: true,
    };
  }

  if (
    currentDate.isSameOrAfter(startMoment) &&
    (!endMoment || currentDate.isBefore(endMoment))
  ) {
    return {
      value: `Deadline to Apply: ${endMoment ? endMoment.format('MMM D, YYYY') : 'No Deadline'}`,
      status: false,
    };
  }

  return { value: '', status: false };
};

export const formatMoneyWithOutSymbol = (
  value: number | null,
  symbol: boolean = true,
): string => {
  if (value === null) return '';

  let result: string;

  if (value >= 10 ** 3 && value < 10 ** 6) {
    result = `${value / 10 ** 3}K`;
  } else if (value >= 10 ** 6 && value < 10 ** 9) {
    result = `${value / 10 ** 6}M`;
  } else if (value >= 10 ** 9) {
    result = `${value / 10 ** 9}B`;
  } else {
    result = `${value}`;
  }

  if (symbol) {
    result = `$ ${result}`;
  }

  return result;
};
