import React, { ReactNode, memo, useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';

import {
  ProfileFieldErrorType,
  ProfileFieldLocationType,
} from '_types/profile.interface';

import DropDownList from 'app/components/DropDownList/DropDownList';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useGoToFirstErrorId from 'utils/contexts/ProfileFieldIdsContext/useGoToFirstErrorId';
import useIndustryFieldConfiguration from 'utils/hooks/profile-field-configuration/useIndustryFieldConfiguration';
import { getAvailableValuesListFromFieldConfiguration } from 'utils/onboarding';

import SboSubIndustrySelectComponent from './SboSubIndustrySelectComponent';
import ProfileFieldWrapper from '../../common/ProfileFieldWrapper';
import type { IndustryFieldConfiguration } from './types';
import { doesIndustryHaveSubIndustries } from './utils';

type ComponentValue = {
  industry?: string | null;
  subIndustry?: string | null;
};

interface SboIndustrySelectComponentProps {
  onChange: (value: ComponentValue) => void;
  errors?: Partial<Record<keyof ComponentValue, ProfileFieldErrorType>>;
  value?: ComponentValue;
  subTitle?: ReactNode;
  type?: ProfileFieldLocationType;
}

const SboIndustrySelectComponent: React.FC<SboIndustrySelectComponentProps> = ({
  onChange,
  errors,
  value,
  subTitle: subTitleToSetImplicitly,
  type,
}) => {
  const { t } = useTenantTranslation();
  const fieldConfiguration =
    useIndustryFieldConfiguration() as IndustryFieldConfiguration;
  const fieldId = useGoToFirstErrorId(['sboData.industry']);

  const industryAvailableValues = useMemo(
    () => getAvailableValuesListFromFieldConfiguration(fieldConfiguration, t),
    [t, fieldConfiguration],
  );

  const onIndustryChange = useCallback(
    (industry: string) => {
      onChange({
        industry,
        subIndustry: null,
      });
    },
    [onChange],
  );

  const onSubIndustryChange = useCallback(
    (subIndustry: string | null) => {
      onChange({
        industry: value?.industry,
        subIndustry,
      });
    },
    [onChange, value?.industry],
  );

  const subTitle = useMemo(() => {
    if (subTitleToSetImplicitly) {
      return subTitleToSetImplicitly;
    }

    if (type === 'onboarding') {
      return t('onboarding.industry.subtitle');
    }

    return undefined;
  }, [subTitleToSetImplicitly, type, t]);

  // null when shouldnt be shown
  const chosenIndustryConfiguration = useMemo(
    () =>
      fieldConfiguration?.options.find(
        (option) => option.value === value?.industry,
      ) || null,
    [fieldConfiguration?.options, value?.industry],
  );

  const hasSubIndustries = doesIndustryHaveSubIndustries(
    fieldConfiguration,
    value?.industry,
  );

  return (
    <>
      <ProfileFieldWrapper
        title={t('onboarding.industry.title')}
        subTitle={subTitle}
        addAsteriskToTitle
        id={fieldId}
      >
        <DropDownList
          placeholder={t('onboarding.industry.placeholder')}
          value={value?.industry || ''}
          onChange={onIndustryChange}
          availableValuesList={industryAvailableValues}
          errors={errors?.industry}
          isArrow
        />
      </ProfileFieldWrapper>

      {hasSubIndustries && (
        <SboSubIndustrySelectComponent
          value={value?.subIndustry}
          onChange={onSubIndustryChange}
          errors={errors?.subIndustry}
          chosenIndustryConfiguration={chosenIndustryConfiguration!}
        />
      )}
    </>
  );
};

export default memo(SboIndustrySelectComponent, isEqual);
