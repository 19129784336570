import {
  PaginatedPartnerResourcesResponseType,
  FetchPartnerResourcesFilterSettings,
  PartnerResourcesSearchType,
  FetchResourcesType,
  PartnerResourcesItemType,
} from '_types/resources.interface';

import { TenantFilterTypeEnum } from '_types';
import axiosApiInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const resourcesService = {
  getResources: (orgId: number) =>
    axiosApiInstance
      .get<FetchResourcesType>(
        `${baseUrl}/api/v1/advisory-org/resources/${orgId}`,
      )
      .then(({ data }) => data),

  // ************************* Partner Resources ************************/
  getPartnerResourcesList: (
    partnerResourcesSearchPayload: PartnerResourcesSearchType,
  ) =>
    axiosApiInstance
      .post<PaginatedPartnerResourcesResponseType>(
        `${baseUrl}/api/v1/partner-resources/fetch-partner-resources`,
        partnerResourcesSearchPayload,
      )
      .then(({ data }) => data),

  getPartnerResourcesFilterSettings: () =>
    axiosApiInstance
      .get<FetchPartnerResourcesFilterSettings>(
        `${baseUrl}/api/v1/tenant-settings/tenant-filter-settings/${TenantFilterTypeEnum.PARTNER_RESOURCE_FILTER}`,
      )
      .then(({ data }) => data),

  postPartnerResource: (partnerResouce: Partial<PartnerResourcesItemType>) =>
    axiosApiInstance
      .post<PartnerResourcesItemType>(
        `${baseUrl}/api/v1/partner-resources/`,
        partnerResouce,
      )
      .then(({ data }) => data),

  patchPartnerResource: (partnerResouce: Partial<PartnerResourcesItemType>) =>
    axiosApiInstance
      .patch<PartnerResourcesItemType>(
        `${baseUrl}/api/v1/partner-resources/${partnerResouce.id}`,
        partnerResouce,
      )
      .then(({ data }) => data),

  deletePartnerResource: (partnerResouce: Partial<PartnerResourcesItemType>) =>
    axiosApiInstance
      .delete<PartnerResourcesItemType>(
        `${baseUrl}/api/v1/partner-resources/${partnerResouce.id}`,
      )
      .then(({ data }) => data),
};
export default resourcesService;
