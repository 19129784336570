import React from 'react';
import { TranslateValueType } from '_types';
import { PartnerResourceTypeEnum } from '_types/resources.interface';
import { SboType } from '_types/sbo.interface';
import { SelectableBlockConfigurableData } from 'app/components/SelectableBlocksList';
import { TFunction } from 'i18next';
import { MdOutlineGroup, MdOutlineSource } from 'react-icons/md';

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string); // Return the Base64 string
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file); // Convert the file to Base64
  });

export const getAvailableResourceModalTypeList = (
  t: TFunction,
): SelectableBlockConfigurableData[] => [
  {
    value: 'cohort',
    subtitle: (
      <>
        <div className="select__elem__icon">
          <div className="icon-wrapper">
            <MdOutlineGroup size={24} />
          </div>
        </div>
        <div className="select__elem__text">
          {t('common.modal.program_and_resources_modal.cohort_programs')}
        </div>
      </>
    ),
  },

  {
    value: 'resource',
    subtitle: (
      <>
        <div className="select__elem__icon">
          <div className="icon-wrapper">
            <MdOutlineSource size={24} />
          </div>
        </div>
        <div className="select__elem__text">
          {t('common.modal.program_and_resources_modal.other_resources')}
        </div>
      </>
    ),
  },
];

export const availableProgramFormatList = (t: TFunction) => [
  {
    value: 'In Person',
    name: t(
      'common.modal.program_and_resources_modal.add_cohort_program.format.options.in_person',
    ),
  },
  {
    value: 'Virtual',
    name: t(
      'common.modal.program_and_resources_modal.add_cohort_program.format.options.virtual',
    ),
  },
  {
    value: 'Hybrid',
    name: t(
      'common.modal.program_and_resources_modal.add_cohort_program.format.options.hybrid',
    ),
  },
];

export const getAvailableSboTypesList = (
  t: TFunction,
): TranslateValueType[] => [
  {
    value: SboType.PRE_STARTUP,
    name: t('myAccount.pre_start_up'),
  },
  {
    value: SboType.STARTUP,
    name: t('myAccount.start_up'),
  },
  {
    value: SboType.OPERATING,
    name: t('myAccount.operating'),
  },
];

export const getPartnerResourceTypeList = (
  t: TFunction,
): TranslateValueType[] => [
  {
    value: PartnerResourceTypeEnum.Public,
    name: t(
      'common.modal.program_and_resources_modal.add_other_resources.resource_type.options.public',
    ),
  },
  {
    value: PartnerResourceTypeEnum.Private,
    name: t(
      'common.modal.program_and_resources_modal.add_other_resources.resource_type.options.private',
    ),
  },
];
