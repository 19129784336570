import { businessTopicsIcons } from '_constants/multitenancy-capabilities/businessTopics';

import type { TranslateValueType } from '_types';
import type { BusinessTopicDataType } from '_types/tenant-settings';

import type { TFunction } from 'i18next';

export const getBusinessTopicIconByKey = (iconKey: string) => {
  const icon = businessTopicsIcons[iconKey];

  if (!icon) {
    // eslint-disable-next-line no-console
    console.error(`Couldn't find business topic icon for key: ${iconKey}`);
  }

  return icon;
};

export const formatBusinessTopicsData = (
  availableSkillsData: BusinessTopicDataType[] | undefined,
  t: TFunction,
): TranslateValueType[] => {
  if (!availableSkillsData) {
    return [];
  }

  return availableSkillsData.map(
    ({ value, iconKey, titleTranslationKey, hintTranslationKey }) => ({
      value,
      icon: getBusinessTopicIconByKey(iconKey),
      name: t(titleTranslationKey),
      hint: hintTranslationKey ? t(hintTranslationKey) : undefined,
    }),
  );
};
