import { createAsyncThunk } from '@reduxjs/toolkit';

import { GrantsSearchType, LoansSearchType } from '_types/funding.interface';
import { PlatformLanguages } from '_types';

import fundingService from '_services/fundingService';

type FetchGetFundingCardsDataType<
  FundingSearchType = LoansSearchType | GrantsSearchType,
> = {
  searchPayload: FundingSearchType;
  currentLanguage: PlatformLanguages;
  controller?: AbortController;
  isUnauth?: boolean;
};

export const fetchGetLoansCards = createAsyncThunk(
  'funding/fetchGetLoansCards',
  (
    data: FetchGetFundingCardsDataType<LoansSearchType> & {
      fetchType?: 'topMatch';
    },
    { rejectWithValue },
  ) =>
    fundingService
      .getLoansList(
        data.searchPayload,
        data.currentLanguage,
        data.controller,
        data.isUnauth,
      )
      .catch(rejectWithValue),
);

export const fetchGetGrantCards = createAsyncThunk(
  'funding/fetchGetGrantCards',
  (
    data: FetchGetFundingCardsDataType<GrantsSearchType> & {
      fetchType?: 'topMatch';
    },
    { rejectWithValue },
  ) =>
    fundingService
      .getGrantsList(
        data.searchPayload,
        data.currentLanguage,
        data.controller,
        data.isUnauth,
      )
      .catch(rejectWithValue),
);

export const fetchGetProductTypes = createAsyncThunk(
  'funding/fetchGetProductTypes',
  (_, { rejectWithValue }) =>
    fundingService.getProductTypes().catch(rejectWithValue),
);

export const fetchLoanFilterSettings = createAsyncThunk(
  'funding/fetchGetLoanFilterSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fundingService.getLoanFilterSettings();
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGrantFilterSettings = createAsyncThunk(
  'funding/fetchGrantFilterSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fundingService.getGrantFilterSettings();
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
