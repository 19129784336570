import Button from 'app/components/Button';
import AddButtonIcon from 'app/components/Icons/AddButtonIcon';
import Input from 'app/components/InputComponents/Input';
import clsx from 'clsx';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import './style.scss';

interface AdditionalCriteriaInputProps {
  value: Record<string, string> | undefined;
  onChange: (newValues: Record<string, string>) => void;
}

const AdditionalCriteriaInput: React.FC<AdditionalCriteriaInputProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTenantTranslation();
  const [criteria, setCriteria] = useState<Record<string, string>>(value || {});

  useEffect(() => {
    if (JSON.stringify(criteria) !== JSON.stringify(value)) {
      onChange(criteria);
    }
  }, [criteria, onChange, value]);

  const handleChange = useCallback(
    (key: string, field: 'key' | 'value', newValue: string) => {
      const updatedCriteria = Object.entries(criteria).reduce(
        (acc, [currentKey, currentValue]) => {
          if (currentKey === key) {
            if (field === 'key') {
              acc[newValue] = currentValue;
            } else {
              acc[currentKey] = newValue;
            }
          } else {
            acc[currentKey] = currentValue;
          }
          return acc;
        },
        {} as Record<string, string>,
      );

      setCriteria(updatedCriteria);
    },
    [criteria, setCriteria],
  );

  const addNewField = useCallback(() => {
    const existingKeys = Object.keys(criteria);
    let newIndex = 1;

    while (existingKeys.includes(`Criteria ${newIndex}`)) {
      // eslint-disable-next-line no-plusplus
      newIndex++;
    }

    const newKey = `Criteria ${newIndex}`;
    setCriteria((prev) => ({
      ...prev,
      [newKey]: '',
    }));
  }, [criteria, setCriteria]);

  const deleteField = (key: string) => {
    const updatedCriteria = { ...criteria };
    delete updatedCriteria[key];
    setCriteria(updatedCriteria);
  };

  return (
    <div className="additional_criteria_input">
      {Object.entries(criteria).map(([key, value], index) => (
        <div key={index} className="additional_criteria_input__pair">
          <div className="inputs">
            <Input
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.additional_criteria.criteria_placeholder',
              )}
              value={key}
              onChange={(value) => handleChange(key, 'key', value)}
            />
            :
            <Input
              type="text"
              placeholder={t(
                'common.modal.program_and_resources_modal.add_cohort_program.additional_criteria.value_placeholder',
              )}
              value={value}
              onChange={(value) => handleChange(key, 'value', value)}
            />
          </div>
          <AddButtonIcon
            className="additional_criteria_input__delete_icon"
            onClick={() => deleteField(key)}
          />
        </div>
      ))}

      {Object.keys(criteria).length < 3 && (
        <Button
          type="btn"
          btnLook="filled-gray"
          className="additional_criteria_input__add_btn"
          value={t(
            'common.modal.program_and_resources_modal.add_cohort_program.add_criteria_btn',
          )}
          size="small"
          rightIcon={<AddButtonIcon />}
          onClick={addNewField}
        />
      )}
    </div>
  );
};

export default memo(AdditionalCriteriaInput);
