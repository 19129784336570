import React, { memo } from 'react';
import clsx from 'clsx';

import Button from 'app/components/Button';
import ArrowRightCircleIcon from 'app/components/Icons/ArrowRightCircleIcon';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import { calendlySteps } from './config';

interface ModalFooterProps {
  onNextClick: Function;
  onCancelClick: Function;
  active: boolean;
  step: number;
  totalSteps: number;
}

const BookingModalFooter: React.FC<ModalFooterProps> = ({
  active,
  step,
  totalSteps,
  onNextClick,
  onCancelClick,
}) => {
  const { t } = useTenantTranslation();

  return (
    <div
      className={clsx(
        'modal_footer',
        Object.keys(calendlySteps).find((key) => calendlySteps[key] === step),
      )}
    >
      <div className="buttons_row">
        <Button
          type="btn"
          btnLook="filled-gray"
          value={t('common.cancel')}
          onClick={onCancelClick}
          size="large"
          className="cancel_button"
        />
      </div>
      <span className="step_counter">
        {step} {t('common.of')} {totalSteps}
      </span>
      <Button
        disabled={!active}
        className="button_next"
        type="btn"
        btnLook="filled"
        value={totalSteps === step ? t('common.got_it') : t('common.next')}
        onClick={onNextClick}
        size="large"
        rightIcon={totalSteps !== step ? <ArrowRightCircleIcon /> : undefined}
      />
    </div>
  );
};

export default memo(BookingModalFooter);
