import clsx from 'clsx';
import React, { memo, useCallback, useRef, useState, useMemo } from 'react';
import { NumericFormat, NumberFormatValues } from 'react-number-format';

import { ProfileFieldErrorType } from '_types/profile.interface';

import ErrorCloud from 'app/components/ErrorCloud';

import '../style.scss';

// text email password number search tel url

type NumericInputProps = {
  value: number | null | undefined;
  onChange: Function;
  placeholder: string;
  thousandSeparator?: string;
  prefix?: string;
  allowNegative?: boolean;
  autocomplete?: boolean;
  disabled?: boolean;
  maxLength?: number;
  readonly?: boolean;
  inputFieldSize?: number; // in characters
  // isRequired?: boolean;
  // isRequiredErrorText?: string;
  errors?: ProfileFieldErrorType;
  errorWithFocus?: boolean;
  handleFocus?: { inputFocus: boolean | null; setInputFocus: Function };
  allowDecimal?: boolean;
};

const NumericInput: React.FC<NumericInputProps> = ({
  autocomplete,
  disabled,
  maxLength,
  placeholder,
  thousandSeparator,
  prefix,
  allowNegative = true,
  readonly,
  inputFieldSize,
  value,
  onChange,
  errors,
  errorWithFocus,
  handleFocus,
  allowDecimal = true,
}) => {
  const inputRef = useRef<any | null>(null);

  const [focus, setFocus] = useState<boolean>(false);

  const onChangeValue = useCallback(
    (values: NumberFormatValues) => onChange(values.floatValue ?? null),
    [maxLength, onChange],
  );

  const handleStateEvent = useCallback(
    (isFocus: boolean) => () => {
      handleFocus?.setInputFocus(!isFocus);
      setFocus(isFocus);
    },
    [handleFocus],
  );
  const onFocus = useMemo(() => handleStateEvent(true), [handleStateEvent]);
  const onBlur = useMemo(() => handleStateEvent(false), [handleStateEvent]);

  const onWheelPreventChange = useCallback((event) => {
    event.target.blur();
    event.stopPropagation();
  }, []);

  return (
    <div
      className={clsx('entry_field', {
        error: errors?.length && !focus,
        focus,
      })}
    >
      <NumericFormat
        displayType="input"
        inputMode="numeric"
        maxLength={maxLength}
        size={inputFieldSize}
        thousandsGroupStyle="thousand"
        thousandSeparator={thousandSeparator}
        prefix={prefix}
        allowNegative={allowNegative}
        autoComplete={autocomplete ? 'on' : 'off'}
        disabled={disabled}
        placeholder={placeholder}
        readOnly={readonly}
        value={value}
        onValueChange={onChangeValue}
        onFocus={onFocus}
        onBlur={onBlur}
        onWheel={onWheelPreventChange}
        getInputRef={inputRef}
        decimalScale={allowDecimal ? 2 : 0}
      />

      {!!errors?.length && (errorWithFocus || !focus) && (
        <ErrorCloud errorsListOrErrorText={errors} />
      )}
    </div>
  );
};

export default memo(NumericInput);
