import React, { memo, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { ProfileFieldErrorType } from '_types/profile.interface';

import ProfileFieldWrapper from 'app/components/ProfileFields/common/ProfileFieldWrapper';
import DropDownList from 'app/components/DropDownList/DropDownList';

import useGoToFirstErrorId from 'utils/contexts/ProfileFieldIdsContext/useGoToFirstErrorId';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { getAvailableValuesListFromFieldConfiguration } from 'utils/onboarding';

import type { IndustryFieldConfigurationOption } from '../types';

interface SboSubIndustrySelectComponentProps {
  value?: string | null;
  onChange: (value: string | null) => void;
  errors?: ProfileFieldErrorType;
  chosenIndustryConfiguration: IndustryFieldConfigurationOption;
}

const SboSubIndustrySelectComponent: React.FC<
  SboSubIndustrySelectComponentProps
> = ({ value, onChange, errors, chosenIndustryConfiguration }) => {
  const { t } = useTenantTranslation();

  const fieldId = useGoToFirstErrorId(['sboData.subIndustry']);

  const availableValues = useMemo(
    () =>
      getAvailableValuesListFromFieldConfiguration(
        chosenIndustryConfiguration,
        t,
        'subIndustryOptions',
      ),
    [chosenIndustryConfiguration],
  );

  const title = useMemo(
    () => (
      <Trans
        t={t}
        i18nKey="onboarding.sub_industry.title"
        values={{ industry: t(chosenIndustryConfiguration.name) }}
      />
    ),
    [chosenIndustryConfiguration, t],
  );

  return (
    <ProfileFieldWrapper title={title} addAsteriskToTitle id={fieldId}>
      <DropDownList
        placeholder={t('onboarding.sub_industry.placeholder')}
        value={value || ''}
        onChange={onChange}
        availableValuesList={availableValues}
        errors={errors}
        isArrow
      />
    </ProfileFieldWrapper>
  );
};

export default memo(SboSubIndustrySelectComponent);
