/* eslint-disable react/jsx-closing-tag-location */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Formik, FormikConfig } from 'formik';
import { object, string } from 'yup';
import { TFunction } from 'i18next';
import { PiWarning } from 'react-icons/pi';

import advisorService from '_services/advisorService';

import Button from 'app/components/Button';
import FormikInputField from 'app/components/FormikFields/FormikInputField';
import ErrorsText from 'app/components/ErrorsText';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import {
  actionHandleHasCalendlyLinksModalBeenShown,
  actionHandleShowCalendlyLinksModal,
} from 'store/reducers/modal';
import { fetchGetProfileInfo } from 'store/actions/profile';
import {
  profileAdvisorSelector,
  profileInfoEmailSelector,
} from 'store/selectors/profile';

import { addSubAddressToEmail } from 'utils/valueFormatters';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import BaseModal from '../BaseModal';

import './style.scss';

const classNames = {
  modalTitle: 'calendly_links_modal_title',
};

type FormData = {
  email: string;
};

const getValidationSchema = (t: TFunction) =>
  object().shape({
    email: string()
      .email('auth_module.email_not_valid')
      .required('validation.field_required'),
  });

const CalendlyLinksModal: React.FC = memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useTenantTranslation();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const advisorData = useAppSelector(profileAdvisorSelector);
  const userEmail = useAppSelector(profileInfoEmailSelector) ?? '';

  const initialData: FormData = useMemo(() => {
    const defaultEmailToUse = addSubAddressToEmail(userEmail, 'nextstreet');
    const email =
      advisorData?.emailLastUsedToFetchCalendlyLinks || defaultEmailToUse;

    return { email };
  }, [userEmail, advisorData?.emailLastUsedToFetchCalendlyLinks]);

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowCalendlyLinksModal(false));
  }, [dispatch]);

  const onFormSubmit: FormikConfig<FormData>['onSubmit'] = useCallback(
    async ({ email }, { setErrors }) => {
      try {
        setIsLoading(true);
        setErrorMessage(null);

        await advisorService.syncAdvisorCalendlyLinks(email);

        await dispatch(fetchGetProfileInfo()).unwrap();

        closeModal();
      } catch (e: any) {
        if (e.response?.status === 400) {
          setErrors(e.response?.data?.errors);
        } else {
          let errorMessage = t('advisor.calendly_links_modal.generic_error');
          if (e.response?.status === 424) {
            errorMessage = e.response?.data?.message;
          }

          setErrorMessage(errorMessage);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, closeModal, t],
  );

  useEffect(() => {
    dispatch(actionHandleHasCalendlyLinksModalBeenShown(true));
  }, []);

  return (
    <BaseModal
      modalTitle={t('advisor.calendly_links_modal.title')}
      modalDescription={t('advisor.calendly_links_modal.description')}
      closeOnBackgroundClick
      headerIcon={<PiWarning size={50} color="orange" />}
      onModalClose={closeModal}
      classNames={classNames}
      isCloseIconVisible
    >
      <Formik
        initialValues={initialData}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        {({ errors, touched, handleBlur, setFieldTouched }) => (
          <Form className="sync_calendly_links_form">
            <FormikInputField
              type="email"
              name="email"
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              showErrorWhenInFocus
              className="email"
              autocomplete="email"
              placeholder={`${t('common.email')}...`}
              errors={touched.email ? t(errors.email || '') : undefined}
            />

            <ErrorsText errorsListOrErrorText={errorMessage} />

            <Button
              type="btn"
              btnType="submit"
              btnLook="filled"
              size="large"
              isLoading={isLoading}
              disabled={isLoading}
            >
              {t('advisor.calendly_links_modal.submit_btn')}
            </Button>
          </Form>
        )}
      </Formik>
    </BaseModal>
  );
});

export default CalendlyLinksModal;
