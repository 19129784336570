export enum MailchimpEventEnum {
  VIEWED_CAPITAL_PRODUCT = 'viewed_capital_product',
  BOOKED_ADVISOR_SESSION = 'booked_advisor_session',
  VIEWED_PARTNER_RESOURCES = 'viewed_partner_resources',
  STARTED_COURSES = 'started_courses',
}

export interface MailChimpTrackEventData {
  eventName: MailchimpEventEnum;
  options: Object;
}
