import React, { memo, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ProfileFieldErrorType } from '_types/profile.interface';

import { MdOutlineCalendarMonth } from 'react-icons/md';
import ErrorCloud from '../ErrorCloud';

import './style.scss';

interface DatePickerCustomProps {
  placeholder: string;
  value: string;
  onChange: Function;
  format?: string;
  errors?: ProfileFieldErrorType;
  maxDate?: Date;
  showIcon?: boolean;
}
const DatePickerCustom: React.FC<DatePickerCustomProps> = ({
  placeholder,
  value,
  onChange,
  format = '',
  errors,
  maxDate,
  showIcon,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const datePickerRef = useRef<DatePicker | null>(null);

  const dateValue = useMemo(() => {
    if (!value) {
      return null;
    }
    const date = new Date(value);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
  }, [value]);

  const handleInputChange = (e) => {
    let typedValue = e?.target?.value;
    let month: string = '';
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    let year: number = currentYear - 1;
    if (typedValue && format === 'MM/yyyy') {
      typedValue = typedValue.replace(/[^0-9/]/g, '');
      if (
        typedValue?.length === 1 &&
        Number(typedValue) > 1 &&
        Number(typedValue) <= 9
      ) {
        month = `0${typedValue}/`;
      } else if (
        typedValue?.length === 2 &&
        typedValue &&
        !typedValue?.includes('/')
      ) {
        month = `${typedValue}/`;
      } else if (
        (typedValue?.length === 2 && typedValue && typedValue?.includes('/')) ||
        (typedValue?.length === 1 && Number(typedValue) <= 1)
      ) {
        month = `${typedValue}`;
      } else if (typedValue?.length > 2) {
        month = `${typedValue?.split('/')[0]}/`;
      }

      if (typedValue?.length > 3) {
        const updatedYear = typedValue?.split('/')[1].substr(0, 4);
        const typedMonth = typedValue?.split('/')[0];

        if (updatedYear?.length > 3 && updatedYear <= currentYear) {
          year = updatedYear;
          if (
            Number(updatedYear) === currentYear &&
            Number(month?.split('/')[0]) > currentMonth
          ) {
            month = `0${currentMonth}/`;
            if (typedMonth?.length > 2) {
              month = `0${currentMonth}/`;
            }
          } else if (
            Number(updatedYear) < currentYear &&
            Number(month?.split('/')[0]) > currentMonth &&
            typedMonth?.length > 2
          ) {
            month = `0${typedMonth}/`;
          }
        } else if (updatedYear?.length > 3 && updatedYear > currentYear) {
          year = currentYear;
          if (
            Number(month?.split('/')[0]) > currentMonth ||
            typedMonth?.length > 2
          ) {
            month = `0${currentMonth}/`;
          }
        } else if (updatedYear?.length > 4) {
          year = updatedYear;
        }
      }
      let updatedMonth = 0;
      if (month && month?.length > 1) {
        updatedMonth = Number(month?.split('/')[0]);
      } else if (month?.length === 1 && Number(month) === 1) {
        updatedMonth = 1;
      }
      const date = new Date(year, updatedMonth > 12 ? 12 : updatedMonth, -1);
      if (e?.currentTarget?.value) {
        let shownYear = typedValue?.split('/')[1]?.substr(0, 4);
        if (shownYear?.length >= 4 && Number(shownYear) > currentYear) {
          shownYear = String(year);
        }
        e.currentTarget.value = month + (shownYear?.substr(0, 4) ?? '');
      }
      onChange(date);
    }
  };

  return (
    <div className={`date_picker_field ${focus ? 'focus' : ''}`}>
      <DatePicker
        ref={datePickerRef}
        showIcon={showIcon}
        icon={
          <MdOutlineCalendarMonth
            size={24}
            onClick={() => datePickerRef.current?.setOpen(true)}
          />
        }
        calendarIconClassName="date_picker_field__icon"
        selected={dateValue}
        onSelect={(date) => {
          setFocus(false);
          onChange(moment(date).format('YYYY-MM-DD'));
        }}
        onChangeRaw={handleInputChange}
        dateFormat={format}
        showMonthYearPicker={format === 'MM/yyyy'}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        maxDate={maxDate || undefined}
        placeholderText={placeholder}
      />

      {!focus && !!errors?.length && (
        <ErrorCloud errorsListOrErrorText={errors} />
      )}
    </div>
  );
};

export default memo(DatePickerCustom);
