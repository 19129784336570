import React from 'react';

import FinancialAdvisoryIcon from 'app/components/Icons/OnboardingIcons/FinancialAdvisoryIcon';
import AccountingIcon from 'app/components/Icons/OnboardingIcons/AccountingIcon';
import LegalIcon from 'app/components/Icons/OnboardingIcons/LegalIcon';
import FundingIcon from 'app/components/Icons/OnboardingIcons/FundingIcon';
import MarketingIcon from 'app/components/Icons/OnboardingIcons/MarketingIcon';
import HumanResourcesIcon from 'app/components/Icons/OnboardingIcons/HumanResourcesIcon';
import OperationsIcon from 'app/components/Icons/OnboardingIcons/OperationsIcon';
import ProcurementIcon from 'app/components/Icons/OnboardingIcons/ProcurementIcon';
import TechnologyIcon from 'app/components/Icons/OnboardingIcons/TechnologyIcon';
import BusinessPlanningIcon from 'app/components/Icons/OnboardingIcons/BusinessPlanningIcon';
import LicensingIcon from 'app/components/Icons/OnboardingIcons/LicensingIcon';
import EcommerseIcon from 'app/components/Icons/OnboardingIcons/Ecommerce';
import GrowthIcon from 'app/components/Icons/OnboardingIcons/Growth';
import AccountTreeIcon from 'app/components/Icons/OnboardingIcons/AccountTree';
import MarkUnreadEmailIcon from 'app/components/Icons/OnboardingIcons/MarkUnreadEmailIcon';
import SecurityIcon from 'app/components/Icons/OnboardingIcons/SecurityIcon';
import ReduceCapacityIcon from 'app/components/Icons/OnboardingIcons/ReduceCapacityIcon';
import ImportExportIcon from 'app/components/Icons/OnboardingIcons/ImportExportIcon';

export const businessTopicsIcons = {
  funding: <FundingIcon />,
  'financial-advisory': <FinancialAdvisoryIcon />,
  accounting: <AccountingIcon />,
  legal: <LegalIcon />,
  marketing: <MarketingIcon />,
  'human-resources': <HumanResourcesIcon />,
  operations: <OperationsIcon />,
  procurement: <ProcurementIcon />,
  technology: <TechnologyIcon />,
  'business-planning': <BusinessPlanningIcon />,
  licensing: <LicensingIcon />,
  'e-commerce': <EcommerseIcon />,
  growth: <GrowthIcon />,
  'account-tree': <AccountTreeIcon />,
  'mark-unread-email': <MarkUnreadEmailIcon />,
  security: <SecurityIcon />,
  'reduce-capacity': <ReduceCapacityIcon />,
  'import-export': <ImportExportIcon />,
} as const;
