import React, { memo } from 'react';

import Button from 'app/components/Button';

interface CookieConsentButtonProps {
  children: React.ReactNode;
  [key: string]: any;
}

const CookieConsentButton: React.FC<CookieConsentButtonProps> = ({
  children,
  ...props
}) => {
  const isDeclineAllButton = children === 'Decline All';
  const btnStyle = isDeclineAllButton ? 'filled-gray' : 'filled';

  return (
    <div {...props}>
      <Button
        type="btn"
        className={isDeclineAllButton ? 'decline-btn' : 'accept-btn'}
        btnLook={btnStyle}
        size="medium"
      >
        {children}
      </Button>
    </div>
  );
};

export default memo(CookieConsentButton);
