import React from 'react';
import { TFunction } from 'i18next';

import {
  CalendarConnectionListType,
  MeetingRoomListType,
  OnboardingSBOInfo,
} from '_types/onboarding.interface';
import { UserRolesEnum } from '_types/user.interface';
import { TranslateValueType } from '_types';
import { ProfileSBOInfo } from '_types/profile.interface';

import CalendlyIcon from 'app/components/Icons/CalendlyIcon';
import GoogleCalendarIcon from 'app/components/Icons/GoogleCalendarIcon';
import GoogleMeetIcon from 'app/components/Icons/GoogleMeetIcon';
import MicrosoftTeamsIcon from 'app/components/Icons/MicrosoftTeamsIcon';
import OfficeCalendarIcon from 'app/components/Icons/OfficeCalendarIcon';
import ZoomIcon from 'app/components/Icons/ZoomIcon';

export const welcomeListFn = (t: TFunction, role: UserRolesEnum): string[] => {
  if (role === UserRolesEnum.ADVISOR) {
    return [
      t('auth_module.registration_list.contact_information'),
      t('auth_module.registration_list.personal_bio'),
      t('auth_module.registration_list.calender_details'),
    ];
  }

  return [
    t('auth_module.registration_list.org_overview'),
    t('auth_module.registration_list.advisors_names_email'),
    t('auth_module.registration_list.expertise_areas'),
  ];
};

export const specifyAnotherField: string = 'Other, please specify...';

export const sboTopicsListFilterFn = (t: TFunction): TranslateValueType[] => [
  { value: 'Funding', name: t('onboarding.funding') },
  { value: 'Financial advisory', name: t('onboarding.financial_advisory') },
  /* { value: 'Accounting', name: t('onboarding.accounting') },
  { value: 'Legal', name: t('onboarding.legal') },
  { value: 'Marketing and advertising', name: t('onboarding.marketing_advertising') },
  { value: 'Human resources', name: t('onboarding.human_resources') },
  { value: 'Operations', name: t('onboarding.operations') },
  { value: 'Procurement', name: t('onboarding.procurement') },
  { value: 'Technology and E-Commerce', name: t('onboarding.technology_commerce') },
  { value: 'Business Planning and Registration', name: t('onboarding.business_planning_registration') },
  { value: 'Licensing and Permits', name: t('onboarding.licensing_permits') }, */
];

export const getAvailableIndustriesList = (
  t: TFunction,
): TranslateValueType[] => [
  {
    value: 'Agriculture, Forestry, Fishing and Hunting',
    name: t('myAccount.industry.agriculture'),
  },
  {
    value: 'Mining, Quarrying, and Oil and Gas Extraction',
    name: t('myAccount.industry.mining'),
  },
  {
    value: 'Utilities',
    name: t('myAccount.industry.utilities'),
  },
  {
    value: 'Construction',
    name: t('myAccount.industry.construction'),
  },
  {
    value: 'Manufacturing',
    name: t('myAccount.industry.manufacturing'),
  },
  {
    value: 'Wholesale Trade',
    name: t('myAccount.industry.wholesale_trade'),
  },
  {
    value: 'Retail Trade',
    name: t('myAccount.industry.retail_trade'),
  },
  {
    value: 'Transportation and Warehousing',
    name: t('myAccount.industry.transportation'),
  },
  {
    value: 'Information, Media, Internet',
    name: t('myAccount.industry.information'),
  },
  {
    value: 'Finance and Insurance',
    name: t('myAccount.industry.finance'),
  },
  {
    value: 'Real Estate and Rental and Leasing',
    name: t('myAccount.industry.real_estate'),
  },
  {
    value: 'Professional, Scientific, and Technical Services',
    name: t('myAccount.industry.professional'),
  },
  {
    value: 'Management of Companies and Enterprises',
    name: t('myAccount.industry.management'),
  },
  {
    value:
      'Administrative and Support and Waste Management and Remediation Services',
    name: t('myAccount.industry.administrative'),
  },
  {
    value: 'Educational Services',
    name: t('myAccount.industry.educational'),
  },
  {
    value: 'Health Care and Social Assistance',
    name: t('myAccount.industry.health_care'),
  },
  {
    value: 'Arts, Entertainment, and Recreation',
    name: t('myAccount.industry.arts'),
  },
  {
    value: 'Accommodation and Food Services',
    name: t('myAccount.industry.accommodation'),
  },
  {
    value: 'Public Administration',
    name: t('myAccount.industry.public_administration'),
  },
  {
    value: 'Other Services (except Public Administration)',
    name: t('myAccount.industry.other_services'),
  },
];

export const NewYorkStateValue = 'New York';

export const nycNeighborhoods = [
  'New York',
  'Brooklyn',
  'Staten Island',
  'Bronx',
  'The Bronx',
  'Queens',
  'Astoria',
  'Ditmars Steinway',
  'Long Island City',
  'Sunnyside',
  'Woodside',
  'Jackson Heights',
  'Elmhurst',
  'Corona',
  'East Elmhurst',
  'Flushing',
  'Murray Hill',
  'Whitestone',
  'College Point',
  'Malba',
  'Bay Terrace',
  'Beechhurst',
  'Queensboro Hill',
  'Forest Hills',
  'Rego Park',
  'Kew Gardens',
  'Kew Gardens Hills',
  'Fresh Meadows',
  'Hillcrest',
  'Bayside',
  'Auburndale',
  'Little Neck',
  'Douglaston',
  'Glen Oaks',
  'Maspeth',
  'Middle Village',
  'Ridgewood',
  'Glendale',
  'Woodhaven',
  'Richmond Hill',
  'South Richmond Hill',
  'Ozone Park',
  'South Ozone Park',
  'Howard Beach',
  'Jamaica',
  'St. Albans',
  'Springfield Gardens',
  'Cambria Heights',
  'Queens Village',
  'Hollis',
  'Rochdale',
  'Laurelton',
  'Rosedale',
  'Far Rockaway',
  'Arverne',
  'Rockaway Beach',
  'Rockaway Park',
  'Bayswater',
  'Belle Harbor',
  'Neponsit',
  'Broad Channel',
];

export const getShouldShowNYCBorough = (
  sboDataWithBusinessState?:
    | Pick<ProfileSBOInfo, 'businessState' | 'businessCity'>
    | Pick<OnboardingSBOInfo, 'businessState' | 'businessCity'>
    | null,
) =>
  sboDataWithBusinessState?.businessState === NewYorkStateValue &&
  nycNeighborhoods.includes(sboDataWithBusinessState?.businessCity || '');

export const statesOnboardingListFn = (t: TFunction): TranslateValueType[] => [
  { value: 'Alabama', name: t('myAccount.states.alabama') },
  { value: 'Alaska', name: t('myAccount.states.alaska') },
  { value: 'Arizona', name: t('myAccount.states.arizona') },
  { value: 'Arkansas', name: t('myAccount.states.arkansas') },
  { value: 'California', name: t('myAccount.states.california') },
  { value: 'Colorado', name: t('myAccount.states.colorado') },
  { value: 'Connecticut', name: t('myAccount.states.connecticut') },
  { value: 'Delaware', name: t('myAccount.states.delaware') },
  {
    value: 'District of Columbia',
    name: t('myAccount.states.columbia_district'),
  },
  { value: 'Florida', name: t('myAccount.states.florida') },
  { value: 'Georgia', name: t('myAccount.states.georgia') },
  { value: 'Hawaii', name: t('myAccount.states.hawaii') },
  { value: 'Idaho', name: t('myAccount.states.idaho') },
  { value: 'Illinois', name: t('myAccount.states.illinois') },
  { value: 'Indiana', name: t('myAccount.states.indiana') },
  { value: 'Iowa', name: t('myAccount.states.iowa') },
  { value: 'Kansas', name: t('myAccount.states.kansas') },
  { value: 'Kentucky', name: t('myAccount.states.kentucky') },
  { value: 'Louisiana', name: t('myAccount.states.louisiana') },
  { value: 'Maine', name: t('myAccount.states.maine') },
  { value: 'Maryland', name: t('myAccount.states.maryland') },
  { value: 'Massachusetts', name: t('myAccount.states.massachusetts') },
  { value: 'Michigan', name: t('myAccount.states.michigan') },
  { value: 'Minnesota', name: t('myAccount.states.minnesota') },
  { value: 'Mississippi', name: t('myAccount.states.mississippi') },
  { value: 'Missouri', name: t('myAccount.states.missouri') },
  { value: 'Montana', name: t('myAccount.states.montana') },
  { value: 'Nebraska', name: t('myAccount.states.nebraska') },
  { value: 'Nevada', name: t('myAccount.states.nevada') },
  { value: 'New Hampshire', name: t('myAccount.states.new_hampshire') },
  { value: 'New Jersey', name: t('myAccount.states.new_jersey') },
  { value: 'New Mexico', name: t('myAccount.states.new_mexico') },
  { value: NewYorkStateValue, name: t('myAccount.states.new_york') },
  { value: 'North Carolina', name: t('myAccount.states.north_carolina') },
  { value: 'North Dakota', name: t('myAccount.states.north_dakota') },
  { value: 'Ohio', name: t('myAccount.states.ohio') },
  { value: 'Oklahoma', name: t('myAccount.states.oklahoma') },
  { value: 'Oregon', name: t('myAccount.states.oregon') },
  { value: 'Pennsylvania', name: t('myAccount.states.pennsylvania') },
  { value: 'Puerto Rico', name: t('myAccount.states.puerto_rico') },
  { value: 'Rhode Island', name: t('myAccount.states.rhode_island') },
  { value: 'South Carolina', name: t('myAccount.states.south_carolina') },
  { value: 'South Dakota', name: t('myAccount.states.south_dakota') },
  { value: 'Tennessee', name: t('myAccount.states.tennessee') },
  { value: 'Texas', name: t('myAccount.states.texas') },
  { value: 'Utah', name: t('myAccount.states.utah') },
  { value: 'Vermont', name: t('myAccount.states.vermont') },
  { value: 'Virginia', name: t('myAccount.states.virginia') },
  { value: 'Washington', name: t('myAccount.states.washington') },
  { value: 'West Virginia', name: t('myAccount.states.west_virginia') },
  { value: 'Wisconsin', name: t('myAccount.states.wisconsin') },
  { value: 'Wyoming', name: t('myAccount.states.wyoming') },
];

export const boroughOnboardingListFn = (t: TFunction): TranslateValueType[] => [
  { value: 'Bronx', name: t('myAccount.borough.Bronx') },
  { value: 'Brooklyn', name: t('myAccount.borough.Brooklyn') },
  { value: 'Manhattan', name: t('myAccount.borough.Manhattan') },
  { value: 'Queens', name: t('myAccount.borough.Queens') },
  { value: 'Staten Island', name: t('myAccount.borough.Staten_Island') },
];

export const sboCommunitiesListFn = (t: TFunction): TranslateValueType[] => [
  {
    name: t('myAccount.sboCommunities.african_american'),
    value: 'Black or African American',
  },
  { name: t('myAccount.sboCommunities.latinx'), value: 'Hispanic or Latino' },
  { name: t('myAccount.sboCommunities.asian'), value: 'Asian' },
  {
    name: t('myAccount.sboCommunities.native_hawaiian'),
    value: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    name: t('myAccount.sboCommunities.american_indian'),
    value: 'American Indian or Alaskan Native',
  },
  { name: t('myAccount.sboCommunities.white'), value: 'White' },
  { name: t('myAccount.sboCommunities.lgbtqia'), value: 'LGBTQIA+' },
  { name: t('myAccount.sboCommunities.veteran'), value: 'Veteran' },
  { name: t('myAccount.sboCommunities.rural'), value: 'Rural' },
  { name: t('common.disabled'), value: 'Disabled' },
  { name: t('myAccount.sboCommunities.women_owned'), value: 'Women-Owned' },
];

export const getAvailableLanguagesList = (t: TFunction) => [
  { value: 'English', name: t('common.language.english') },
  { value: 'Spanish', name: t('common.language.spanish') },
  { value: 'Chinese', name: t('common.language.chinese') },
  { value: 'French', name: t('common.language.french') },
  { value: 'Arabic', name: t('common.language.arabic') },
  { value: 'Hindi', name: t('common.language.hindi') },
  { value: 'Urdu', name: t('common.language.urdu') },
  { value: 'Japanese', name: t('common.language.japanese') },
  { value: 'Korean', name: t('common.language.korean') },
  { value: 'German', name: t('common.language.german') },
  { value: 'Polish', name: t('common.language.polish') },
  { value: 'Russian', name: t('common.language.russian') },
  { value: 'Other, please specify...', name: t('common.other_specify') },
];

export const meetingRoomList: MeetingRoomListType[] = [
  {
    name: 'Zoom',
    icon: <ZoomIcon />,
    value: 'zoom',
  },
  {
    name: 'Google Meet',
    icon: <GoogleMeetIcon />,
    value: 'googleMeet',
  },
  {
    name: 'Microsoft Teams',
    icon: <MicrosoftTeamsIcon />,
    value: 'microsoftTeams',
  },
];

export const calendarConnectionList: CalendarConnectionListType[] = [
  {
    name: 'Google Calendar',
    icon: <GoogleCalendarIcon />,
    value: 'google',
  },
  {
    name: 'Office 365 Calendar',
    icon: <OfficeCalendarIcon />,
    value: 'office',
  },
  {
    name: 'Calendly',
    icon: <CalendlyIcon />,
    value: 'calendly',
  },
];

export const finalOnboardingStepNumber: number = 999;
