import React, { memo, useMemo } from 'react';

import { ProfileFieldErrorType } from '_types/profile.interface';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

interface ErrorCloudErrorTextLineProps {
  error: ProfileFieldErrorType | { message: string };
}

const ErrorCloudErrorTextLine: React.FC<ErrorCloudErrorTextLineProps> = ({
  error,
}) => {
  const { t } = useTenantTranslation();

  const errorToDisplay = useMemo(() => {
    if (typeof error === 'string') {
      return t(error);
    }

    if ('message' in error) {
      return t(error.message, { params: error });
    }

    return error;
  }, [error, t]);

  return <p className="error_cloud__bullet_list__text">{errorToDisplay}</p>;
};

export default memo(ErrorCloudErrorTextLine);
