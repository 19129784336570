import {
  CohortItemType,
  CohortProgramPayloadType,
  PaginatedCohortProgramResponseType,
} from '_types/cohort.interface ';
import axiosApiInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const CohortService = {
  getCohortList: () =>
    axiosApiInstance
      .get<CohortItemType[]>(`${baseUrl}/api/v1/cohort-program`)
      .then(({ data }) => data),
  getMatchedCohortList: (cohortProgramPayload: CohortProgramPayloadType) =>
    axiosApiInstance
      .post<PaginatedCohortProgramResponseType>(
        `${baseUrl}/api/v1/cohort-program/matched`,
        cohortProgramPayload.payload,
      )
      .then(({ data }) => data),

  postCohortProgram: (cohortProgram: Partial<CohortItemType>) =>
    axiosApiInstance
      .post<CohortItemType>(`${baseUrl}/api/v1/cohort-program/`, cohortProgram)
      .then(({ data }) => data),

  patchCohortProgram: (cohortProgram: Partial<CohortItemType>) =>
    axiosApiInstance
      .patch<CohortItemType>(
        `${baseUrl}/api/v1/cohort-program/${cohortProgram.id}`,
        cohortProgram,
      )
      .then(({ data }) => data),

  deleteCohortProgram: (cohortProgram: Partial<CohortItemType>) =>
    axiosApiInstance
      .delete<CohortItemType>(
        `${baseUrl}/api/v1/cohort-program/${cohortProgram.id}`,
      )
      .then(({ data }) => data),
};
export default CohortService;
