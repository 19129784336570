import React, { useCallback, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiArrowDownSFill } from 'react-icons/ri';

import { phoneConstant } from '_constants/otherConstants';

import { UserRolesEnum } from '_types/user.interface';

import AvatarCircle from 'app/components/AvatarCircle/AvatarCircle';
import BusinessIcon from 'app/components/Icons/BusinessIcon';
import ExitIcon from 'app/components/Icons/ExitIcon';
// import LanguageSwitch from 'app/components/LanguageSwitch';
import NotificationsComponent from 'app/components/NotificationsComponent';
import ModulesHamburgerMenuForMobile from 'app/components/ModulesHamburgerMenuForMobile';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import {
  isProfileChangedSelector,
  profileInfoAssuredSelector,
  profilePhotoSelector,
} from 'store/selectors/profile';
import { actionSetShowUnsavedChangesPrompt } from 'store/reducers/profile';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { auth } from 'utils/firebase/FirebaseRemoteConfigInitialization';
import useTenantColors from 'utils/hooks/useTenantColors';

const avatarSize = { small: '40', large: '40' };

const GeneralHeaderAuthProfilePart: React.FC = () => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profileInfo = useAppSelector(profileInfoAssuredSelector);
  const profilePhoto = useAppSelector(profilePhotoSelector);
  const hasProfileUnsavedChanges = useAppSelector(isProfileChangedSelector);
  const { colorVariables } = useTenantColors();

  const [isProfileBurgerMenuOpen, setIsProfileBurgerMenuOpen] =
    useState<boolean>(false);

  const logout = useCallback(async () => {
    sessionStorage.clear();
    await auth.signOut();

    navigate('/');
  }, [dispatch, navigate]);

  const handleLogoutClick = useCallback(() => {
    if (hasProfileUnsavedChanges) {
      dispatch(
        actionSetShowUnsavedChangesPrompt({
          onDecline: () => {},
          onOk: logout,
        }),
      );
    } else {
      logout();
    }
  }, [dispatch, logout, hasProfileUnsavedChanges]);

  const onOutsideClick = useCallback(() => {
    if (isProfileBurgerMenuOpen) {
      setIsProfileBurgerMenuOpen(false);
    }
  }, [isProfileBurgerMenuOpen, setIsProfileBurgerMenuOpen]);

  const onProfileBurgerOpenToggle = useCallback(
    () => setIsProfileBurgerMenuOpen((isOpen: boolean) => !isOpen),
    [setIsProfileBurgerMenuOpen],
  );

  const { ref } = useOnClickOutside(true, onOutsideClick);

  const isAdvisorOrAdvisoryOrgAdmin =
    profileInfo.role === UserRolesEnum.ADVISOR ||
    profileInfo.role === UserRolesEnum.ADVISORY_ORG_ADMIN;

  return (
    <>
      <NotificationsComponent />

      <div className="profile_avatar" ref={ref}>
        <div
          className="profile_avatar__wrapper"
          onClick={onProfileBurgerOpenToggle}
        >
          <AvatarCircle
            className="profile_avatar__img"
            firstName={profileInfo.firstName}
            lastName={profileInfo.lastName}
            src={profilePhoto}
            size={avatarSize}
            color={
              colorVariables?.background.background_avatar_with_letters ||
              '#459396'
            }
          />
          <RiArrowDownSFill
            size={20}
            color={colorVariables?.icons.icon_dropdown}
            className={isProfileBurgerMenuOpen ? 'active' : ''}
          />
        </div>

        {isProfileBurgerMenuOpen && (
          <div className="profile_avatar__burger">
            <div
              className="burger_item"
              onClick={() => navigate('/my-account')}
            >
              <BusinessIcon className="burger_item__icon" />
              {isAdvisorOrAdvisoryOrgAdmin
                ? t('common.my_profile')
                : t('common.my_account')}
            </div>

            <div className="burger_item" onClick={handleLogoutClick}>
              <ExitIcon className="burger_item__icon" />
              {t('common.logout')}
            </div>
          </div>
        )}
      </div>

      <ModulesHamburgerMenuForMobile />
    </>
  );
};

export default memo(GeneralHeaderAuthProfilePart);
