import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import I18NextHttpBackend from 'i18next-http-backend';

const s3cloudFrontAddress = process.env.REACT_APP_S3_CLOUDFRONT_ADDRESS;

// Create the 'translations' object to provide full intellisense support for the static json files.
// convertLanguageJsonToObject(en);
export const i18n: any = i18next
  .use(I18NextHttpBackend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      debug: false, // process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
      returnObjects: true,
      defaultNS: 'nxst',
      fallbackLng: 'en',
      ns: ['nxst'],
      detection: {
        lookupQuerystring: 'lang',
      },
      supportedLngs: ['en', 'es', 'ar', 'zh'],
      backend: {
        loadPath: `${s3cloudFrontAddress}/locales/{{lng}}/{{ns}}.json`,
        crossDomain: true,
        allowMultiLoading: true,
      },
    },
    (err, t) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.error('Error during I18N initialization', err);
      }
    },
  );
