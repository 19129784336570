import React, { ReactElement } from 'react';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

import Button from 'app/components/Button';
import { NavigateFunction } from 'react-router-dom';
import { AdvisorReferralNotificationObject } from './type';

import './style.scss';

export const getTitleBlock = (
  advisorReferralNotificationObject: AdvisorReferralNotificationObject,
  t: TFunction,
): ReactElement | null => (
  <Trans components={[<span key="0" />]}>
    {t('<0>{{referreName}}</0> referred you to <1>{{referredName}}<1>:', {
      referreName: `${advisorReferralNotificationObject.data.referee_advisor.firstName} ${advisorReferralNotificationObject.data.referee_advisor.lastName}`,
      referredName: `${advisorReferralNotificationObject.data.referred_advisor.firstName} ${advisorReferralNotificationObject.data.referred_advisor.lastName}`,
    })}
  </Trans>
);

export const getContentBlock = (
  advisorReferralNotificationObject: AdvisorReferralNotificationObject,
  t: TFunction,
  navigate: NavigateFunction,
): ReactElement | null => (
  <div className="advisor-referral-notification-container">
    <p>{`"${advisorReferralNotificationObject.data.note}"`}</p>
    <Button
      type="link"
      btnLook="filled"
      size="small"
      onClick={() =>
        navigate('/advisors/list', { state: { sessionTabIndex: 2 } })
      }
      value={t('View My Referred Advisors')}
      className="referral_button"
    />
  </div>
);
