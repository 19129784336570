import { useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantStylingSelector } from 'store/selectors/tenant-settings';

const useTenantColors = () => {
  const tenantStyling = useAppSelector(tenantSettingsTenantStylingSelector);

  return {
    mainColor: tenantStyling?.mainColor,
    secondaryColor: tenantStyling?.secondaryColor,
    shades08: tenantStyling?.shades08,
    shades09: tenantStyling?.shades09,
    shades10: tenantStyling?.shades10,
    shades12: tenantStyling?.shades12,
    secondaryTeal: tenantStyling?.secondaryTeal,
    secondary04: tenantStyling?.secondary04,
    secondary05: tenantStyling?.secondary05,
    secondary06: tenantStyling?.secondary06,
    secondary08: tenantStyling?.secondary08,
    mainYellow: tenantStyling?.mainYellow,
    colorVariables: tenantStyling?.colorVariables,
  };
};

export default useTenantColors;
