import React, { memo } from 'react';
import { Trans } from 'react-i18next';

import { HeaderTypes } from '_constants/headerConstants';
import { FooterTypes } from '_constants/footerConstants';

import HelpButton from 'app/components/HelpButton';
import CommonPageWrapper from 'app/pages/utils/Wrappers/CommonPageWrapper';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantStylingSelector } from 'store/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import GlobalErrorPageImage from './GlobalErrorPageImage';

import '../../../../styles/App.scss';
import './style.scss';

const GlobalErrorPage: React.FC = () => {
  const { t } = useTenantTranslation();

  const tenantStyling = useAppSelector(tenantSettingsTenantStylingSelector);

  return (
    <div className="app">
      <CommonPageWrapper
        className="global-error-page-wrapper"
        title="NXST - Error Occurred"
        withOutlet={false}
        headerType={HeaderTypes.notFound}
        footerType={FooterTypes.notFound}
      >
        <div className="global-error-page">
          <GlobalErrorPageImage
            className="global-error-page__image"
            color1={tenantStyling?.mainColor}
            color2={tenantStyling?.shades12}
            color3={tenantStyling?.shades08}
          />

          <div className="global-error-page__content">
            <div className="global-error-page__content__title">
              {t('error_page.title')}
            </div>
            <div className="global-error-page__content__text">
              <Trans components={[<br key="0" />]}>
                {t('error_page.text')}
              </Trans>
            </div>
          </div>
        </div>
      </CommonPageWrapper>

      <HelpButton />
    </div>
  );
};

export default memo(GlobalErrorPage);
