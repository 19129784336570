import React, { memo, useCallback, useMemo } from 'react';

import { specifyAnotherField } from '_constants/onboardingConstants';

import { TranslateValueType } from '_types';
import { ProfileFieldErrorType } from '_types/profile.interface';

import SelectButton from 'app/components/SelectButton/SelectButton';
import ErrorCloud from 'app/components/ErrorCloud';

import {
  checkOtherSelectButton,
  getSpecifyOtherValueFromList,
  removeSpecifyOtherValueFromList,
} from 'utils/onboarding';

import './style.scss';
import clsx from 'clsx';

type TypeProps =
  | {
      type: 'checkbox';
      value: string[];
      onChange: (value: string[]) => void;
    }
  | {
      type: 'select';
      value: string;
      onChange: (value: string) => void;
    };

type ListWithSelectablesProps = {
  availableValuesList: TranslateValueType[];
  look?: 'one-column' | 'two-columns';
  disableHeightLimitation?: boolean;
  errors?: ProfileFieldErrorType;
  fillRowsFirst?: boolean; // 2nd item is placed in 2nd column, 3rd in 1st column, etc.
} & TypeProps;

const ListWithSelectables: React.FC<ListWithSelectablesProps> = ({
  type,
  value,
  availableValuesList,
  onChange,
  errors,
  look = 'two-columns',
  disableHeightLimitation = false,
  fillRowsFirst = false,
}) => {
  const specifyValue = useMemo(() => {
    if (type === 'checkbox') {
      return getSpecifyOtherValueFromList(value, availableValuesList);
    }

    return value;
  }, [value, availableValuesList, type]);

  const getSpecifyAnotherField = (funding: TranslateValueType) => {
    if (funding.value === specifyAnotherField) {
      return value &&
        availableValuesList.findIndex((elem) => elem.value === value) === -1
        ? specifyValue
        : '';
    }
    return funding.name;
  };

  const formListHeight = useMemo(() => {
    const maxColumnRowLength = Math.ceil(availableValuesList.length / 2);
    return maxColumnRowLength * 60 + maxColumnRowLength * 8;
  }, [availableValuesList]);

  const checkboxTypeAddToList = (name: string) => {
    if (type !== 'checkbox') {
      return;
    }

    let newList = [...value];
    if (newList.includes(name)) {
      if (name === specifyValue) {
        newList = removeSpecifyOtherValueFromList(newList, availableValuesList);
      } else {
        newList = newList.filter((elem) => elem !== name);
      }
    } else {
      newList = [...newList, name];
    }

    onChange(newList);
  };

  const checkboxTypeEditSpecifyValue = useCallback(
    (newValue: string) => {
      if (type !== 'checkbox') {
        return;
      }

      if (newValue) {
        const newList = removeSpecifyOtherValueFromList(
          value,
          availableValuesList,
        );
        onChange([...newList, newValue]);
      }

      if (!newValue) {
        const newList = removeSpecifyOtherValueFromList(
          value,
          availableValuesList,
        );
        onChange(newList);
      }
    },
    [type, value, availableValuesList, onChange],
  );

  const checkIsSelected = (elem: TranslateValueType) => {
    const valueToCompare =
      elem.value === specifyAnotherField ? specifyValue : elem.value;

    if (type === 'checkbox') {
      return value.includes(valueToCompare);
    }

    return checkOtherSelectButton(
      availableValuesList.map((elem) => elem.value),
      elem.value,
      value,
    );
  };

  return (
    <div className="list_with_selectables">
      {!!errors?.length && <ErrorCloud errorsListOrErrorText={errors} />}

      <div
        className={clsx('list_with_selectables__list', look, { fillRowsFirst })}
        style={{
          height:
            disableHeightLimitation || look === 'one-column'
              ? 'auto'
              : formListHeight,
        }}
      >
        {availableValuesList.map((elem: TranslateValueType, index: number) =>
          type === 'checkbox' ? (
            <SelectButton
              key={index}
              value={getSpecifyAnotherField(elem)}
              onClick={() => {
                if (elem.value === specifyAnotherField) {
                  if (specifyValue) {
                    checkboxTypeAddToList(specifyValue);
                  }
                } else {
                  checkboxTypeAddToList(elem.value);
                }
              }}
              onChange={checkboxTypeEditSpecifyValue}
              selected={checkIsSelected(elem)}
              type={type}
              inputSelect={elem.value === specifyAnotherField}
              inputSelectText={elem.name}
              hint={elem.hint}
            />
          ) : (
            <SelectButton
              key={index}
              value={getSpecifyAnotherField(elem)}
              onClick={(event) => {
                onChange(
                  elem.value === specifyAnotherField ? event : elem.value,
                );
              }}
              onChange={onChange}
              selected={checkIsSelected(elem)}
              type="select"
              inputSelect={elem.value === specifyAnotherField}
              inputSelectText={specifyAnotherField}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default memo(ListWithSelectables);
