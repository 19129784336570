import React, { memo } from 'react';
import useTenantColors from 'utils/hooks/useTenantColors';

interface Props {
  className?: string;
}

const AccountTreeIcon: React.FC<Props> = ({ className }) => {
  const { colorVariables } = useTenantColors();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11V3H15V6H9V3H2V11H9V8H11V18H15V21H22V13H15V16H13V8H15V11H22ZM7 9H4V5H7V9ZM17 15H20V19H17V15ZM17 5H20V9H17V5Z"
        fill={colorVariables?.icons.icon_primary2_button || '#053B66'}
      />
    </svg>
  );
};

export default memo(AccountTreeIcon);
