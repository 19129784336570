import React, { memo, useState } from 'react';
import clsx from 'clsx';
import { CiWarning } from 'react-icons/ci';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { Trans } from 'react-i18next';
import CheckedIcon from 'app/components/Icons/CheckedIcon';
import BaseModal from '../../BaseModal';
import './style.scss';

interface DeleteResourceModalProps {
  closeModal: Function;
  deleteResource: Function;
}

const DeleteResourceModal: React.FC<DeleteResourceModalProps> = ({
  closeModal,
  deleteResource,
}) => {
  const { t } = useTenantTranslation();
  const [selected, setSelected] = useState<boolean>(false);

  return (
    <BaseModal
      headerIcon={<CiWarning className="icon" size={40} color="#BA2C2B" />}
      modalTitle={t(
        'common.modal.program_and_resources_modal.delete_resource.header_text',
      )}
      closeOnBackgroundClick
      withDeclineButton
      declineButtonText={t('common.cancel')}
      withAcceptButton
      onDeclineButtonClick={closeModal}
      onModalClose={closeModal}
      onAcceptButtonClick={deleteResource}
      acceptButtonText={t(
        'common.modal.program_and_resources_modal.delete_resource.confirmation_button_text',
      )}
      classNames={{
        modal: 'resource_deletion__modal',
        modalTitle: 'modal_block__title',
        modalHeader: 'modal_block__header',
        acceptButton: 'modal_block__acceptButton',
        declineButton: 'modal_block__declineButton',
        closeIcon: 'modal_block__closeIcon',
        modalFooter: 'modal_block__footer',
      }}
      disableAcceptButton={!selected}
      isCloseIconVisible
      declineButtonSize="large"
      acceptButtonSize="large"
    >
      <div className="modal_block__body">
        <div className="content">
          <p>
            <Trans components={[<span key="0" />]}>
              {t(
                'common.modal.program_and_resources_modal.delete_resource.body_description1',
              )}
            </Trans>
          </p>
          <p>
            {t(
              'common.modal.program_and_resources_modal.delete_resource.body_description2',
            )}
          </p>
        </div>
        <div className="checkbox" onClick={() => setSelected(!selected)}>
          <div className={clsx('selectable_box', { active: selected })}>
            {selected && <CheckedIcon />}
          </div>
          <p>
            {t(
              'common.modal.program_and_resources_modal.delete_resource.confirmation_message',
            )}
          </p>
        </div>
      </div>
    </BaseModal>
  );
};

export default memo(DeleteResourceModal);
