import { createAsyncThunk } from '@reduxjs/toolkit';
import CohortService from '_services/cohortProgram';
import {
  CohortItemType,
  CohortProgramPayloadType,
} from '_types/cohort.interface ';

export const fetchGetCohortProgramCards = createAsyncThunk(
  'cohort-program/fetch-cohort-program',
  (_, { rejectWithValue }) =>
    CohortService.getCohortList().catch(rejectWithValue),
);

export const fetchGetMatchedCohortProgramCards = createAsyncThunk(
  'cohort-program/fetch-matched-cohort-programs',
  (data: CohortProgramPayloadType, { rejectWithValue }) =>
    CohortService.getMatchedCohortList(data).catch(rejectWithValue),
);

export const createCohortProgram = createAsyncThunk(
  'cohort-program/createCohortProgram',
  async (cohortProgram: Partial<CohortItemType>, { rejectWithValue }) => {
    try {
      const response = await CohortService.postCohortProgram(cohortProgram);

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateCohortProgram = createAsyncThunk(
  'cohort-program/updateCohortProgram',
  async (cohortProgram: Partial<CohortItemType>, { rejectWithValue }) => {
    try {
      const response = await CohortService.patchCohortProgram(cohortProgram);

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteCohortProgram = createAsyncThunk(
  'cohort-program/deleteCohortProgram',
  async (cohortProgram: Partial<CohortItemType>, { rejectWithValue }) => {
    try {
      const response = await CohortService.deleteCohortProgram(cohortProgram);

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
