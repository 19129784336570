import React, { memo, useCallback, useState } from 'react';

import SelectableBlock from 'app/components/SelectableBlock';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

interface StepTimeSelectorProps {
  onSelect: (value: string) => void;
  isCFO: boolean;
}

const StepTimeSelectorComponent: React.FC<StepTimeSelectorProps> = ({
  onSelect,
  isCFO,
}) => {
  const { t } = useTenantTranslation();

  const [selected, setSelected] = useState<string>();

  const selectTime = useCallback(
    (value: string) => {
      setSelected(value);
      onSelect(value);
    },
    [onSelect],
  );

  return (
    <>
      <h2 className="modal_body_title">
        {t('advisors_module.booking_card.how_much_time_Need')}
      </h2>

      {isCFO ? (
        <SelectableBlock
          selected={selected === 'link60'}
          onChange={selectTime}
          title={t('advisors_module.booking_card.CFO_advisor_title')}
          subtitle={t('advisors_module.booking_card.CFO_advisor_subtitle', {
            value: 60,
          })}
          value="link60"
          type="select"
          className="select"
        />
      ) : (
        <>
          <SelectableBlock
            selected={selected === 'link15'}
            onChange={selectTime}
            title={t('advisors_module.booking_card.quick_check_in')}
            subtitle={t('advisors_module.booking_card.value_minutes', {
              value: 15,
            })}
            value="link15"
            type="select"
            className="select"
          />
          <SelectableBlock
            selected={selected === 'link30'}
            onChange={selectTime}
            title={t('advisors_module.booking_card.standard_session')}
            subtitle={t('advisors_module.booking_card.value_minutes', {
              value: 30,
            })}
            value="link30"
            type="select"
            className="select"
          />
        </>
      )}
    </>
  );
};

export default memo(StepTimeSelectorComponent);
