import React, { memo, useMemo } from 'react';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import { AdvisorSmallData } from '_types/advisor.interface';
import { useNavigate } from 'react-router-dom';
import NotificationBlock from '../NotificationBlock';

import { getContentBlock, getTitleBlock } from './utils';
import { AdvisorReferralNotificationObject } from './type';

interface AdvisorRefferralNotificationProps {
  notification: AdvisorReferralNotificationObject;
}

const AdvisorRefferralNotification: React.FC<
  AdvisorRefferralNotificationProps
> = ({ notification }) => {
  const { t } = useTenantTranslation();
  const navigate = useNavigate();

  const titleBlock = useMemo(
    () => getTitleBlock(notification, t),
    [notification, t],
  );

  const contentBlock = useMemo(
    () => getContentBlock(notification, t, navigate),
    [notification, t],
  );

  return (
    <NotificationBlock
      notificationId={notification.id}
      isRead={notification.isRead}
      createdAtUnix={notification.createdAtUnix}
      logoUser={
        notification.data.referee_advisor as unknown as AdvisorSmallData
      }
      titleBlock={titleBlock}
      contentBlock={contentBlock}
    />
  );
};

export default memo(AdvisorRefferralNotification);
