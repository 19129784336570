import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  DropEvent,
  DropzoneOptions,
  FileRejection,
  useDropzone,
} from 'react-dropzone';
import { HiDocumentText } from 'react-icons/hi';

import { zipCodeValidationRegExp } from '_constants/otherConstants';

import Button from 'app/components/Button';
import BaseModal, { BaseModalProps } from 'app/components/Modals/BaseModal';
import CheckedIcon from 'app/components/Icons/CheckedIcon';
import ErrorIcon from 'app/components/Icons/ErrorIcon';
import ProgressBarInfinite from 'app/components/ProgressBarInfinite';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import { ZipCodesFileImportValidationError } from './types';

import './style.scss';

const modalClassNames: BaseModalProps['classNames'] = {
  modal: 'zip_codes_file_import_modal',
};

const zipCodesFileMbMax = 2;
const dropZoneAcceptFileType: Record<string, string[]> = {
  'text/plain': ['.txt'],
  'text/csv': ['.csv'],
};

interface ZipCodesFileImportModalProps {
  setIsSuccessfullyUploaded: (value: boolean) => void;
  onModalClose: () => void;
  onChange: (value: string[]) => void;
}

const ZipCodesFileImportModal: React.FC<ZipCodesFileImportModalProps> = memo(
  ({ setIsSuccessfullyUploaded, onModalClose, onChange }) => {
    const { t } = useTenantTranslation();

    const [validationError, setValidationError] =
      useState<ZipCodesFileImportValidationError>(null);
    const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSuccess = useCallback(() => {
      setIsSuccessfullyUploaded(true);
      setIsSuccessful(true);
    }, [setIsSuccessfullyUploaded, onModalClose]);

    const onFileChosen = useCallback(
      async (files: File[]) => {
        const file = files[0];

        if (!file) {
          return;
        }

        if (!Object.keys(dropZoneAcceptFileType).includes(file.type)) {
          setValidationError({
            title: t(
              'onboarding.advisory_org_zip_codes.errors.wrong_file_type.title',
            ),
            text: t(
              'onboarding.advisory_org_zip_codes.errors.wrong_file_type.text',
            ),
          });
          return;
        }

        const fileSizeMb = file.size / 1024 / 1024;
        if (fileSizeMb > zipCodesFileMbMax) {
          setValidationError({
            title: t(
              'onboarding.advisory_org_zip_codes.errors.wrong_size.title',
            ),
            text: t('onboarding.advisory_org_zip_codes.errors.wrong_size.text'),
          });
          return;
        }

        try {
          const fileText = await file.text();

          const zipCodesToUpload = fileText
            // delete all occurences of ',' in text
            .replaceAll(/[,;.]/g, '')
            .split(/\r?\n/)
            .map((zipCode) => zipCode.trim())
            .filter(Boolean);

          // eslint-disable-next-line no-restricted-syntax
          for (const zipCode of zipCodesToUpload) {
            if (!zipCodeValidationRegExp.test(zipCode)) {
              setValidationError({
                title: t(
                  'onboarding.advisory_org_zip_codes.errors.invalid_zip_code.title',
                ),
                text: t(
                  'onboarding.advisory_org_zip_codes.errors.invalid_zip_code.text',
                ),
              });
              return;
            }
          }

          if (!zipCodesToUpload.length) {
            setValidationError({
              title: t(
                'onboarding.advisory_org_zip_codes.errors.empty_file.title',
              ),
              text: t(
                'onboarding.advisory_org_zip_codes.errors.empty_file.text',
              ),
            });
            return;
          }

          onChange(zipCodesToUpload);

          onSuccess();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);

          setValidationError({
            title: t(
              'onboarding.advisory_org_zip_codes.errors.unknown_error.title',
            ),
            text: t(
              'onboarding.advisory_org_zip_codes.errors.unknown_error.text',
            ),
          });
        }
      },
      [t, setValidationError, onSuccess],
    );

    const onDrop = useCallback(
      (
        acceptedFiles: File[],
        fileRejections: FileRejection[],
        _: DropEvent,
      ) => {
        setValidationError(null);

        let files: File[] = [];

        setIsLoading(true);

        if (acceptedFiles.length) {
          files = acceptedFiles;
        } else if (fileRejections.length) {
          // it won't process a file, but will call setValidationError
          files = fileRejections.map((rejected) => rejected.file);
        }

        onFileChosen(files).finally(() => setIsLoading(false));
      },
      [onFileChosen, setValidationError, setIsLoading],
    );

    const dropzoneHookOptions: DropzoneOptions = useMemo(
      () => ({
        onDrop,
        accept: dropZoneAcceptFileType,
        multiple: false,
        useFsAccessApi: false,
      }),
      [onDrop],
    );

    const { getRootProps, getInputProps } = useDropzone(dropzoneHookOptions);

    if (isLoading) {
      return (
        <BaseModal classNames={modalClassNames}>
          <div className="zip_codes_file_import_modal__description">
            <HiDocumentText
              className="zip_codes_file_import_modal__description-icon"
              size={32}
            />
            <p>{t('onboarding.advisory_org_zip_codes.being_uploaded')}</p>
            <ProgressBarInfinite className="zip_codes_file_import_modal__description-loader" />
          </div>
        </BaseModal>
      );
    }

    return (
      <BaseModal
        closeOnBackgroundClick
        onModalClose={onModalClose}
        withCloseButton
        closeButtonLook={isSuccessful ? 'filled' : 'filled-gray'}
        closeButtonText={
          isSuccessful ? t('common.continue') : t('common.cancel')
        }
        classNames={modalClassNames}
      >
        {isSuccessful ? (
          <div className="zip_codes_file_import_modal__success_description">
            <p>
              {t('onboarding.advisory_org_zip_codes.successfully_uploaded')}
            </p>
            <CheckedIcon color="var(--secondary-teal, #099)" />
          </div>
        ) : validationError ? (
          <div
            {...getRootProps()}
            className="zip_codes_file_import_modal__error_description"
          >
            <div className="zip_codes_file_import_modal__error_description__title">
              <ErrorIcon className="zip_codes_file_import_modal__error_description__title__icon" />
              <h2 className="zip_codes_file_import_modal__error_description__title__text">
                {validationError.title}
              </h2>
            </div>

            <p className="zip_codes_file_import_modal__error_description__text">
              {validationError.text}
            </p>

            <Button
              drop={getInputProps()}
              type="file-dropzone"
              className="zip_codes_file_import_modal__error_description-button"
              btnLook="filled"
              size="medium"
              value="Browse files..."
            />
          </div>
        ) : (
          <div
            {...getRootProps()}
            className="zip_codes_file_import_modal__description"
          >
            <HiDocumentText
              className="zip_codes_file_import_modal__description-icon"
              size={32}
            />
            <p>
              {t('onboarding.advisory_org_zip_codes.drag_and_drop', {
                maxSizeMb: zipCodesFileMbMax,
              })}
            </p>
            <p>{t('onboarding.advisory_org_zip_codes.or')}</p>
            <Button
              drop={getInputProps()}
              type="file-dropzone"
              className="zip_codes_file_import_modal__description-button"
              btnLook="filled"
              size="medium"
              value={t('onboarding.advisory_org_zip_codes.browse_files')}
            />
            <p>{t('onboarding.advisory_org_zip_codes.upload_note')}</p>
          </div>
        )}
      </BaseModal>
    );
  },
);

export default ZipCodesFileImportModal;
