import React, { memo, ReactNode } from 'react';
import clsx from 'clsx';

import './style.scss';

export interface FieldWrapperProps {
  title?: string;
  subTitle?: string;
  children?: ReactNode;
  fieldClassName?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  addAsteriskToTitle?: boolean;
}

const FieldWrapper: React.FC<FieldWrapperProps> = ({
  title,
  subTitle,
  children,
  fieldClassName,
  titleClassName,
  subTitleClassName,
  addAsteriskToTitle,
}) => (
  <div className={clsx('field_wrapper', fieldClassName)}>
    <div className="field_wrapper_key">
      {!!title && (
        <p className={clsx('field_wrapper_key__title', titleClassName)}>
          {title}
          {addAsteriskToTitle && <>*</>}
        </p>
      )}

      {!!subTitle && (
        <p className={clsx('field_wrapper_key__subtitle', subTitleClassName)}>
          {subTitle}
          {!title && addAsteriskToTitle && <>*</>}
        </p>
      )}
    </div>
    {children}
  </div>
);

export default memo(FieldWrapper);
