import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  PartnerResourcesItemType,
  PartnerResourcesSearchType,
} from '_types/resources.interface';

import resourcesService from '_services/resourcesService';

type FetchGetPartnerResourcesCardsDataType<PartnerResourcesSearchType> = {
  searchPayload: PartnerResourcesSearchType;
};

export const fetchResources = createAsyncThunk(
  'resources/fetch-resources',
  (orgId: number, { rejectWithValue }) =>
    resourcesService.getResources(orgId).catch(rejectWithValue),
);

// ***************************  Partner Resources *************************/
export const fetchPartnerResourcesCards = createAsyncThunk(
  'resources/fetch-partner-resources',
  (
    data: FetchGetPartnerResourcesCardsDataType<PartnerResourcesSearchType>,
    { rejectWithValue },
  ) =>
    resourcesService
      .getPartnerResourcesList(data.searchPayload)
      .catch(rejectWithValue),
);

export const fetchPartnerResourcesFilterSettings = createAsyncThunk(
  'resources/fetchPartnerResourcesFilterSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response =
        await resourcesService.getPartnerResourcesFilterSettings();

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createPartnerResource = createAsyncThunk(
  'resources/createPartnerResource',
  async (
    partnerResource: Partial<PartnerResourcesItemType>,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await resourcesService.postPartnerResource(partnerResource);

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updatePartnerResource = createAsyncThunk(
  'resources/updatePartnerResource',
  async (
    partnerResource: Partial<PartnerResourcesItemType>,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await resourcesService.patchPartnerResource(partnerResource);

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deletePartnerResource = createAsyncThunk(
  'resources/deletePartnerResource',
  async (
    partnerResource: Partial<PartnerResourcesItemType>,
    { rejectWithValue },
  ) => {
    try {
      const response =
        await resourcesService.deletePartnerResource(partnerResource);

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

// **************************  Cohort Program **************************/
