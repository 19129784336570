import React, { useState, useMemo, memo, useCallback, useEffect } from 'react';
import clsx from 'clsx';

import { specifyAnotherField } from '_constants/onboardingConstants';

import { ProfileFieldErrorType } from '_types/profile.interface';
import { TranslateValueType } from '_types';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import EmptyArrowIcon from '../Icons/EmptyArrowIcon';
import ErrorCloud from '../ErrorCloud';
import SelectButton from '../SelectButton/SelectButton';

import './style.scss';

interface DropDownMultiSelectListProps {
  placeholder: string;
  value: string[];
  onChange: (newValue: string[]) => void;
  errors?: ProfileFieldErrorType;
  availableValuesList: TranslateValueType[];
  isArrow?: boolean;
  listUpward?: boolean;
}

const DropDownMultiSelectList: React.FC<DropDownMultiSelectListProps> = ({
  placeholder,
  value,
  onChange,
  errors,
  availableValuesList,
  isArrow,
  listUpward,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleLangModal = useCallback(() => setOpen(false), []);

  const { ref } = useOnClickOutside(open, handleLangModal);

  const handleDropDownClick = () => {
    setOpen((value) => !value);
  };

  const { t } = useTenantTranslation();
  const [specifyValue, setSpecifyValue] = useState<string>('');

  const availableValuesListFormatted = useMemo(
    () =>
      availableValuesList.map((listItem) => {
        if (
          listItem.value === specifyAnotherField &&
          listItem.value !== specifyValue
        ) {
          listItem.name = specifyValue;
        }
        return listItem;
      }),
    [availableValuesList, specifyValue, t],
  );

  const editSpecifyValueInOnboarding = (name: string) => {
    const initSpecifyValueIndex = value.findIndex(
      (elem) =>
        availableValuesListFormatted.findIndex(
          (listItem) => listItem.value === elem || elem === specifyAnotherField,
        ) === -1,
    );

    let newValue: string[] = value;

    if (initSpecifyValueIndex !== -1) {
      if (name) {
        newValue = value.map((elem, index) =>
          index === initSpecifyValueIndex ? name : elem,
        );
      } else {
        newValue = value.filter((_, index) => index !== initSpecifyValueIndex);
      }
    } else if (name !== '') {
      newValue = [...value, name];
    }

    const valueToSet = newValue.filter(
      (value, index, self) => self.indexOf(value) === index,
    );

    onChange(valueToSet);
  };

  const editSpecifyValue = (name: string) => {
    setSpecifyValue(name);
    editSpecifyValueInOnboarding(name);
  };

  const selectedValues = useMemo(
    () =>
      value.map(
        (val) => availableValuesList.find((elem) => elem.value === val)?.name,
      ),
    [value, availableValuesList],
  );

  const addToList = (name: string) => {
    let newValue: string[];
    if (value.includes(name)) {
      newValue = value.filter((elem) => elem !== name);
    } else {
      newValue = [...value, name];
    }

    onChange(newValue);
  };

  useEffect(() => {
    const initSpecifyValue = value.find(
      (elem) =>
        availableValuesListFormatted.length &&
        availableValuesListFormatted.findIndex(
          (listItem) => listItem.value === elem || elem === specifyAnotherField,
        ) === -1,
    );

    if (initSpecifyValue) {
      editSpecifyValue(initSpecifyValue);
    }
  }, [availableValuesListFormatted]);

  const selectButtonOnClick = (elem) => {
    if (elem.value === specifyAnotherField) {
      if (specifyValue) {
        addToList(specifyValue);
      }
    } else {
      addToList(elem.value);
    }
  };

  return (
    <div className="drop_down_multi_select_container" ref={ref}>
      <div
        className={clsx('drop_down_multi_select_container__text_field', {
          error: errors?.length,
          active: open,
        })}
        onClick={handleDropDownClick}
      >
        <p className={`${value ? 'value' : ''}`}>
          {selectedValues.join(', ') || placeholder}
        </p>

        {isArrow && (
          <EmptyArrowIcon className={`arrow ${open ? 'active' : ''}`} />
        )}
      </div>

      {!!errors?.length && <ErrorCloud errorsListOrErrorText={errors} />}

      {open && (
        <div
          className={clsx('drop_down_multi_select_container__list', {
            upward: listUpward,
          })}
        >
          {availableValuesListFormatted.map(
            (elem: TranslateValueType, index: number) => (
              <SelectButton
                key={`${elem.value}${index}`}
                onClick={() => selectButtonOnClick(elem)}
                leftDownIcon={elem.icon}
                hint={elem.hint}
                hintPosition="left"
                selected={value.includes(
                  elem.value === specifyAnotherField ? elem.name : elem.value,
                )}
                type="checkbox"
                value={elem.name}
                onChange={editSpecifyValue}
                inputSelect={elem.value === specifyAnotherField}
                inputSelectText={specifyAnotherField}
              />
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default memo(DropDownMultiSelectList);
